import { allTrue, hidden } from 'signal/utility-functions'
import { dbVersions } from 'analyse2/createDb'

async function getProjectJSON (projectId) {
  const dbClient = this.dbClient
  const _projects = this.get().projects
  const _fileOverviews = this.get().fileOverviews
  const _tasks = this.get().tasks
  const _samples = this.get().samples
  const _sampleCollections = this.get().sampleCollections
  const _variantSets = this.get().variantSets
  if (!allTrue(
    dbClient,
    _projects,
    _fileOverviews,
    _tasks,
    _samples,
    _sampleCollections,
    _variantSets
  )) return
  const projects = [this.get().getProject(projectId)]
  const samples = this.get().getSamplesForProject(projectId)
  const fileOverviews = this.get().getFileOverviewsForProject(projectId)
  const tasks = this.get().getTasksForProject(projectId)
  const variantSets = this.get().getVariantSetsForProject(projectId)
  const sampleCollections = this.get().getSampleCollectionsForProject(projectId)
  const files = await Promise.all(fileOverviews.map(({ id }) => dbClient.files.get(id)))
  return JSON.stringify({
    format: 'signal/analyse2',
    version: dbVersions[dbVersions.length - 1].version,
    data: {
      samples,
      fileOverviews,
      tasks,
      variantSets,
      sampleCollections,
      projects,
      files: files.filter(Boolean)
    }
  })
}

export default getProjectJSON