import { allTrue } from 'signal/utility-functions'

async function getProjectCSVs (projectId, signatures, sampleOverviews, substitutionSpec, doubleSubstitutionSpec, indelSpec) {
  const projects = this.get().projects
  const variantSets = this.get().variantSets
  if (!allTrue(projects, signatures, variantSets, sampleOverviews, projectId)) return
  const samplesForProject = this.get().getSamplesForProject(projectId)
  if (!samplesForProject) return
  const finalCSV = {}
  await Promise.all(samplesForProject.map(async ({ id, alias }) => {
    const sampleOverview = sampleOverviews[id]
    if (!sampleOverview) return
    const secondaryVariantSetId = sampleOverview.secondaryVariantSetId
    const primaryVariantSetId = sampleOverview.primaryVariantSetId
    const secondaryDoubleSubstitutionVariantSetId = sampleOverview.secondaryDoubleSubstitutionVariantSetId
    const primaryDoubleSubstitutionVariantSetId = sampleOverview.primaryDoubleSubstitutionVariantSetId
    const primaryIndelVariantSetId = sampleOverview.primaryIndelVariantSetId
    const secondaryIndelVariantSetId = sampleOverview.secondaryIndelVariantSetId
    if (!secondaryVariantSetId) return
    finalCSV[alias] = await this.getSampleCSVs({
      sampleId: id,
      primaryVariantSetId,
      secondaryVariantSetId,
      secondaryDoubleSubstitutionVariantSetId,
      primaryDoubleSubstitutionVariantSetId,
      secondaryIndelVariantSetId,
      primaryIndelVariantSetId,
      signatures,
      substitutionSpec,
      doubleSubstitutionSpec,
      indelSpec
    })
  }))
  return finalCSV
}

export default getProjectCSVs