import React from 'react'
import styled from 'styled-components'
import colors from 'signal/colors'

export default function ({ to, ...props }) {
  return (
    <Link
      href={to}
      target='_blank'
      rel='noopener noreferrer'
      {...props}
    />
  )
}

const Link = styled.a`
  text-decoration: underline;
  color: ${colors.primary};
  transition: color 0.1s;
  &:hover {
    color: black;
  }
`