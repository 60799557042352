import { shallowEqual } from 'signal/utility-functions'
import { useRef, useEffect, useCallback, useMemo } from 'react'
import { useMemoisedValue, useAsyncState } from 'signal/utility-hooks'

function useFiles (_fileIds = []) {

  const fileIds = useMemoisedValue(_fileIds, shallowEqual)

  const selectFileAvailabilities = useCallback(data => {
    return fileIds.reduce((acc, fileId) => (acc[fileId] = data.fileIsFetched(fileId), acc), {})
  }, [fileIds])

  const fileAvailabilities = this.use(selectFileAvailabilities, shallowEqual)
  const [state, setState] = useAsyncState({})

  const unmounted = useRef(false)
  useEffect(() => () => unmounted.current = true, [])

  // Remove stale files from state
  useEffect(() => {
    setState(currentState => {
      const stateFileIds = Object.keys(currentState)
      const staleFileIds = stateFileIds.filter(fileId => !fileIds.includes(fileId))
      if (staleFileIds.length === 0) return currentState
      else {
        const newState = { ...currentState }
        for (let fileId of staleFileIds) delete newState[fileId]
        return newState
      }
    })
  }, [fileIds, setState])

  useEffect(() => {
    (async () => {
      const fileIdsToFetch = await setState((currentState, resolve) => {
        const fileIdsToFetch = fileIds.filter(fileId => fileAvailabilities[fileId] && !currentState[fileId])
        resolve(fileIdsToFetch)
        if (fileIdsToFetch.length === 0) return currentState
        else return {
          ...currentState,
          ...fileIdsToFetch.reduce((acc, fileId) => (acc[fileId] = { loading: true, data: null, loaded: false }, acc), {})
        }
      })
      if (fileIdsToFetch.length > 0) {

        for (let fileId of fileIdsToFetch) {
          (async () => {
            const file = await this.dbClient.files.get(fileId)
            // if (fileId === 'fbc335d2-0451-11ed-a26b-6d41e693a427') console.log('file', file)
            // Changed the below line because it was returning on a file that was an empty string "".
            // File should have had 'id' as a heading though, so worth checking why the conversions are inconsistent
            // or whatever else it was causing the file to not have 'id' as header
            //if (unmounted.current || !(file && file.data)) return
            if (unmounted.current) return
            setState(currentState => {
              if (!currentState[fileId]) return currentState
              else return {
                ...currentState,
                [fileId]: { loading: false, data: file.data, loaded: true }
              }
            })
          })()
        }
      }
    })()
  }, [fileIds, fileAvailabilities, setState])

  return useMemo(() => {
    return fileIds.reduce((acc, id) => {
      const { loading = false, data = null, loaded = false } = state[id] || {}
      const available = !!fileAvailabilities[id]
      acc[id] = { loading, data, loaded, available, id }
      return acc
    }, {})
  }, [fileIds, fileAvailabilities, state])
}

export default useFiles