import { Document } from 'flexsearch'
import { assoc, forEach } from 'ramda'
import { useState, useEffect } from 'react'
import { experimentalState } from 'signal/state'

import { useQuery } from 'signal/hooks'

const getIndexDir = (dataVersion, experimental) => {
  if (experimental) {
    return `${dataVersion}_experimental`
  } else {
    return dataVersion
  }
}

const useIndexes = indexList => {
  const [indexes, setIndexes] = useState(null)
  const { data: { dataVersion } = {} } = useQuery('/dataVersion')
  const experimental = experimentalState.use()
  const indexListString = indexList.join('_')
  useEffect(() => {
    if (dataVersion) {
      let stale = false
      const apolloState = JSON.parse(document.getElementById('apolloState').textContent)
      const indexesUrl = apolloState.indexes;
      (async () => {
        const indexes = await Promise.all(indexList.map(async indexName => {
          const response = await window.fetch(`${indexesUrl}/${getIndexDir(dataVersion, experimental)}/${indexName}.json.br`)
          const indexData = await response.json()
          const index = new Document(assoc('worker', true, indexData.options))
          forEach(({ key, data }) => index.import(key, data), indexData.data)
          return { type: indexData.type, index }
        }))
        !stale && setIndexes(indexes)
      })()
      return () => (setIndexes(null), stale = true)
    }
  }, [dataVersion, indexListString, setIndexes])
  return indexes
}

export default useIndexes
