import { allTrue } from 'signal/utility-functions'
import taskIsComplete from './taskIsComplete'
import fileExistsOnServer from './fileExistsOnServer'
import fileTouchHasTimedOut from './fileTouchHasTimedOut'

const prerequisiteFileRequiresTouch = (fileOverview, parentTask) => allTrue(
  taskIsComplete(parentTask),
  fileExistsOnServer(fileOverview),
  fileTouchHasTimedOut(fileOverview)
)

export default prerequisiteFileRequiresTouch