function getSampleCount (projectId = null, sampleCollectionId) {
  if (!this.samples) return
  if (projectId) {
    if (!this.projects) return
    if (!this.projects[projectId]) return
    return Object.values(this.samples).filter(sample => {
      const passCollectionFilter = !sampleCollectionId || sample.sampleCollection === sampleCollectionId
      return sample.projectId === projectId && !sample.delete && passCollectionFilter
    }).length
  } else return Object.keys(this.samples).length
}

export default getSampleCount