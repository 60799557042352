import equal from 'deep-equal'
import React, { useState } from 'react'

import Context from './Context'

const Provider = props => {
  const [breadcrumbs, _setBreadcrumbs] = useState(null)
  const setBreadcrumbs = nextBreadcrumbs => {
    if (!equal(breadcrumbs, nextBreadcrumbs)) {
      _setBreadcrumbs(nextBreadcrumbs)
    }
  }
  return (
    <Context.Provider value={[breadcrumbs, setBreadcrumbs]}>
      {props.children}
    </Context.Provider>
  )
}

export default Provider
