import SearchComponent from './SearchComponent'
import colors from 'signal/colors'
import useIndexes from './useIndexes'
import React, { useState } from 'react'
import styled from 'styled-components'

const indexNames = [
  'referenceCancerSignature',
  'tissueType',
  'mutagenGroup',
  'compound',
  'radiation',
  'gene',
  'cancerSample',
]

const Search = ({ ...props }) => {
  const [mainOpen, setMainOpen] = useState(false)
  const indexes = useIndexes(indexNames)
  return (
    <Container onClick={() => setMainOpen(true)}>
      {mainOpen && <SearchComponent
        initialValue={''}
        close={() => setMainOpen(false)}
        exploreIndexes={indexes}
        exploreIndexNames={indexNames}
      />}
      <span css='font-size: 0.85em; position: relative; top: 0px;'>Search</span>&nbsp;&nbsp;<i css='position: relative; top: 1px;' className='material-icons'>search</i>
    </Container>
  )
}

const Container = styled.div`
  cursor: pointer;
  color: ${colors.primary};
  display: flex;
  align-items: center;
  font-size: 1.2em;
  margin: 0px 10px;
`

export default Search