import { allTrue } from 'signal/utility-functions'
import { taskRequiresSubmission, prerequisiteFileRequiresTouch } from '../utils'

function getFileIdsRequiringTouch () {
  if (!allTrue(this.tasks, this.fileOverviews)) return []
  const tasks = this.tasks
  const fileOverviews = this.fileOverviews
  const fileIdsToTouch = []
  for (let taskId in tasks) {
    const task = tasks[taskId]
    if (taskRequiresSubmission(task)[0]) {
      const { prerequisiteFileIds } = task
      for (let fileId of prerequisiteFileIds) {
        const fileOverview = fileOverviews[fileId]
        // Need to do something when fileOverview doesn't exist, or we will throw an error on the next line
        if (!fileOverview) console.warn(`Prerequisite file ${fileId} of task ${taskId} does not exist`)
        else {
          const { parentTaskId } = fileOverview
          const task = tasks[parentTaskId]
          const needed = prerequisiteFileRequiresTouch(fileOverview, task)
          if (needed && !fileIdsToTouch.includes(fileId)) fileIdsToTouch.push(fileId)
        }
      }
    }
  }
  return fileIdsToTouch
}

export default getFileIdsRequiringTouch