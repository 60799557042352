import React from 'react'
import { useLocation } from 'react-router-dom'
import QueryString from 'query-string'
import { assoc } from 'ramda'

import {
  ExternalLink,
  InternalLink,
  TextLink
} from 'signal-components'
import theme from './theme.css'
import wellcomeLogo from './wellcome.png'
import comsigLogo from './comsig_con.png'
import sangerLogo from './sanger.svg'
import cambridgeLogo from './cambridge.svg'
import mrcLogo from './mrc.svg'
import crukLogo from './cruk.svg'

const Footer = props => {
  const location = useLocation()
  return (
    <footer className={theme.container}>
      <div className={theme.logos}>
        <div className={theme.logoContainer}>
          <a
            href='https://wellcome.ac.uk/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img className={theme.logo} src={wellcomeLogo} />
          </a>
        </div>
        <div className={theme.logoContainer}>
          <a
            href='https://www.cancerresearchuk.org/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img className={theme.logo} src={crukLogo} />
          </a>
        </div>
        <div className={theme.logoContainer}>
          <a
            href='http://www.steinerstiftung.unibe.ch'
            target='_blank'
            rel='noopener noreferrer'
            className={theme.steiner}
          >
            <span className={theme.top}>Dr. Josef Steiner</span>
            <span className={theme.bottom}>Foundation</span>
          </a>
        </div>
        <div className={theme.logoContainer}>
          <img className={theme.logo} src={comsigLogo} />
        </div>
        <div className={theme.logoContainer}>
          <a
            href='http://www.cam.ac.uk/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img className={theme.logo} src={cambridgeLogo} />
          </a>
        </div>
        {/*
        <div className={theme.logoContainer}>
          <a
            href='https://www.mrc-cu.cam.ac.uk/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img className={theme.logo} src={mrcLogo} />
          </a>
        </div>
        */}
        <div className={theme.logoContainer}>
          <a
            href='http://www.sanger.ac.uk/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img className={theme.logo} src={sangerLogo} />
          </a>
        </div>
      </div>
      <p>
        Early Cancer Institute, University of Cambridge,
        2023 &mdash; <TextLink to='/termsAndConditions'>Terms and Conditions</TextLink>
      </p>
      <p>
        <ExternalLink to='https://mutationalsignatures.com'>Meet the team</ExternalLink>
      </p>
      <p style={{ fontSize: '0.9em' }}>Any questions? Email <a
        className={theme.email}
        href='mailto:signal@mutationalsignatures.com'
      >signal@mutationalsignatures.com</a></p>
    </footer>
  )
}

export default Footer
