import React from 'react'
import styled from 'styled-components'
import colors from 'signal/colors'

const ResultItem = ({ id, type, detached, name, selected, ...props }) => {
  return (
    <Container detached={detached} selected={selected} {...props}>
      <div css='flex-grow: 1; white-space: nowrap; text-overflow: ellipsis; overflow: hidden; min-width: 0px;'>{name}</div>
      {selected && <div>↵</div>}
    </Container>
  )
}

const Container = styled.div`
  padding: 0px 10px;
  font-size: 1.1em;
  border-radius: 20px 0px 0px 20px;
  height: 40px;
  display: flex; align-items: center;
  cursor: pointer;
  border: 1px solid transparent;
  ${p => p.selected ? `border-color: ${colors.primary};` : ''}
  ${p => p.selected ? `background-color: ${colors.primary}; color: white;` : ''}
`

export default ResultItem