import React from 'react'
import styled from 'styled-components'
import ButtonUnstyled from './button-unstyled'
import colors from 'signal/colors'

const Button = ({ unstyled, ...props }) => {
  if (unstyled) return <ButtonUnstyled {...props} />
  else return <ButtonStyled {...props} />
}

const ButtonStyled = styled.button`
  color: white;
  font: inherit;
  padding: 0.5rem 1rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 1.1rem;
  min-width: 150px;
  outline: inherit;
  font-weight: bold;
  transition: background-color 0.15s, color 0.15s;
  border: none;
  border-radius: 3px;
  ${p => {
    if (!p.disabled) {
      return (`
        background-color: ${colors.primary};
        cursor: pointer;
        &:hover {
          background-color: ${colors.primaryHover};
        }
      `)
    } else return `background-color: ${colors.greyHoverFill};`
  }}
  ${p => p.addedStyle}
`

export default Button
