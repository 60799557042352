async function deleteProject (projectId) {
  if (!this.dbClient) return
  await this.dbClient.transaction(
    'rw',
    ['projects', 'sampleCollections', 'tasks', 'samples', 'variantSets', 'fileOverviews', '_files'],
    async () => {
      const project = await this.dbClient.projects.get(projectId)
      if (!project) return
      await Promise.all([
        this.dbClient.projects.delete(projectId),
        this.dbClient.tasks.where({ projectId }).delete(),
        this.dbClient.samples.where({ projectId }).delete(),
        this.dbClient.variantSets.where({ projectId }).delete(),
        this.dbClient.sampleCollections.where({ projectId }).delete(),
        (async () => {
          const fileOverviews = await this.dbClient.fileOverviews.where({ projectId }).toArray()
          await Promise.all(
            fileOverviews.map(({ id: fileId }) => Promise.all([
              this.dbClient.files.delete(fileId),
              this.dbClient.fileOverviews.delete(fileId)
            ]))
          )
        })()
      ])
    }
  )
}

export default deleteProject