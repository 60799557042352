import React, { useState, useLayoutEffect, useEffect, useMemo, useCallback } from 'react'
import { Portal } from 'signal/utility-components'
import styled from 'styled-components'
import { animation } from 'analyse2/css'
import { pipe, reverse, pluck, unnest, take, zip, range, map, assoc, groupWith, tap } from 'ramda'
import { Text } from 'signal/input'
import colors from 'signal/colors'
import getPath from 'signal/routes/getPath'
import { useHistory } from 'react-router-dom'
import useWindowSize from '@rooks/use-window-size'
import PreviewPane from './preview-pane'
import ResultItem from './result-item'
import { searchHistoryState } from 'signal/state'
import HistoryItem from './HistoryItem'
import HistorySection from './HistorySection'

const searchIndex = async (type, index, value, limit) => {
  const results = await index.searchAsync(value, { limit, enrich: true })
  return pipe(
    pluck('result'),
    unnest,
    map(({ doc: { id, name } }) => ({ id, name, type }))
  )(results)
}

const sectionTitles = {
  referenceCancerSignature: 'Reference Signatures',
  mutagenGroup: 'Mutagen Groups',
  compound: 'Compounds',
  radiation: 'Radiation',
  gene: 'Genes',
  cancerSample: 'Cancer Samples',
  tissueType: 'Tissue Types'
}

const SearchComponent = ({ initialValue, close, exploreIndexes, exploreIndexNames, ...props }) => {
  const [value, setValue] = useState(initialValue)
  const [_results, setResults] = useState({ results: [], loading: false })
  const results = _results.results
  const [selectedResult, setSelectedResult] = useState(0)
  const history = useHistory()
  const { innerWidth } = useWindowSize()
  const mobile = innerWidth < 700

  const searchHistory = searchHistoryState.use()

  const [hiddenTypes, setHiddenTypes] = useState([])
  const toggleHideType = useCallback(type => setHiddenTypes(hiddenTypes =>
    hiddenTypes.includes(type)
      ? hiddenTypes.filter(x => x !== type)
      : [...hiddenTypes, type]
  ), [setHiddenTypes])

  useEffect(() => {
    setSelectedResult(0)
  }, [value, setSelectedResult])

  useLayoutEffect(() => {
    if (exploreIndexes && value) {
      let stale = false;
      setResults(x => ({ ...x, loading: true }));
      (async () => {
        const results = await Promise.all(exploreIndexes.map(async index => {
          const indexResults = await searchIndex(index.type, index.index, value, 10)
          return indexResults
        }))
        const filteredResults = pipe(unnest, take(10))(results)
        !stale && setResults({ results: filteredResults, loading: false })
      })()
      return () => {
        // setResults({ results: [], loading: false })
        stale = true
      }
    } else if (exploreIndexes) setResults({ results: [], loading: false })
  }, [exploreIndexes, exploreIndexNames, value, setResults])

  const resultsBySection = useMemo(() => 
    pipe(
      zip(range(0, results.length)),
      map(([i, result]) => {
        if (i === selectedResult) return pipe(assoc('i', i), assoc('selected', true))(result)
        else return assoc('i', i, result)
      }),
      groupWith((a, b) => a.type === b.type),
      map(sectionResults => ({
        title: sectionTitles[sectionResults[0].type],
        type: sectionResults[0].type,
        results: sectionResults
      }))
    )(results)
  , [results, selectedResult])

  const activeSectionAndResult = useMemo(() => {
    for (let section of resultsBySection) {
      for (let result of section.results) {
        if (result.selected) return [section, result]
      }
    }
  }, [resultsBySection])

  const [previewPanelUrl, setPreviewPanelUrl] = useState()

  const highlightedResultUrl = value && resultsBySection.length > 0 && activeSectionAndResult && getPath({ type: activeSectionAndResult[1].type, id: activeSectionAndResult[1].id })

  const detached = highlightedResultUrl !== previewPanelUrl

  return (
    <Portal>
      <GreyUnderlay onClick={e => { close(); e.stopPropagation() }}>

        <div css='display: flex; justify-content: center;'>
          <Container onClick={e => e.stopPropagation()} css='background-color: white; box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);'>
        <div css='display: flex; justify-content: flex-end;'>
          <CloseButton onClick={close} />
        </div>
            <Content>
              <TextInput
                focusedLineColor={colors.primary}
                unfocusedLineColor='darkgrey'
                valid
                onBlur={e => {
                  const target = e.target
                  setTimeout(() => target.focus(), 100)
                }} // This was breaking when scrolled down (short window height), clicking on a result wouldn't work
                // this was because the onBlur triggering refocus was causing a re-render of some kind resulting
                // in the browser throwing away the onClick event on the result.
                // I want onBlur so that the typing doesn't stop working because the user clicked elsewhere,
                // so we have this hacky fix where it will wait just long enough for the refocus to happen
                // that it gives time for the onClick to process first
                autoFocus
                value={value}
                onChange={e => setValue(e.target.value)}
                onKeyDown={e => {
                  if (results.length > 0 && e.key === 'Enter') {
                    const url = getPath(results[selectedResult])
                    const { name, id, type } = results[selectedResult]
                    searchHistoryState.add({ name, id, type })
                    history.push(url)
                    close()
                  } else if (e.key === 'ArrowDown') {
                    e.preventDefault()
                    if (selectedResult === results.length - 1) setSelectedResult(0)
                    else setSelectedResult(selectedResult + 1)
                  } else if (e.key === 'ArrowUp') {
                    e.preventDefault()
                    if (selectedResult === 0) setSelectedResult(results.length - 1)
                    else setSelectedResult(selectedResult - 1)
                  } else if (e.key === 'Escape') {
                    close()
                  }
                }}
              />
              {!value && (
                <div css={`display: flex; flex-direction: column; color: ${colors.primary}; font-size: 1.6em;`}>
                  {searchHistory.length === 0 && (
                    <div css='margin-top: 50px; '>
                      <div>Start typing</div>
                      <div css='font-size: 0.7em; margin-top: 10px; color: rgb(120, 120, 120);'>Search results will appear here</div>
                    </div>
                  )}
                  {searchHistory.length > 0 && <HistorySection go={x => { history.push(x); close(); }} />}
                </div>
              )}
              {value && resultsBySection.length === 0 && !_results.loading && (
                <div css={`margin-top: 50px; display: flex; justify-content: center; color: ${colors.primary}; font-size: 1.5em;`}>
                  No results found, please try a different search term.
                </div>
              )}
              {value && resultsBySection.length > 0 && (
                <div css='display: flex;margin-top: 20px;'>
                  <div css='flex-basis: 0px; flex-grow: 1; min-width: 0px; padding-right: 5px;'>
                    {resultsBySection.map(({ title, results }) => (
                      <div key='title'>
                        <SubHeader /* onClick={() => toggleHideType(title)} */ expanded={!hiddenTypes.includes(title)}>
                          <div>{title}</div>
                          {/* <div>▼</div> */}
                        </SubHeader>
                        <SubSection expanded={!hiddenTypes.includes(title)}>
                          {results.map(({ id, type, name, i, selected }) => (
                            <ResultItem onClick={() => {
                              console.log('CLICKED')
                              const url = getPath({ type, id })
                              searchHistoryState.add({ type, id, name })
                              history.push(url)
                              close()
                            }} detached={detached} onMouseMove={() => setSelectedResult(i)} key={id} id={id} type={type} name={name} selected={selected} />
                          ))}
                        </SubSection>
                      </div>
                    ))}
                  </div>
                  {!mobile && (
                    <div css={`height: 550px; display: flex; flex-direction: column; flex-basis: 0px; flex-grow: 1.5; min-width: 0px;`}>
                      <PreviewPane
                        closeSearch={close}
                        searchHistoryState={searchHistoryState}
                        categoryType={activeSectionAndResult[0].type}
                        name={activeSectionAndResult[1].name}
                        id={activeSectionAndResult[1].id}
                        type={activeSectionAndResult[1].type}
                        key={getPath({ type: activeSectionAndResult[1].type, id: activeSectionAndResult[1].id })}
                        previewPanelUrl={previewPanelUrl}
                        setPreviewPanelUrl={setPreviewPanelUrl}
                      />
                    </div>
                  )}
                </div>
              )}
            </Content>
          </Container>
        </div>
      </GreyUnderlay>
    </Portal>
  )
}
const SubSection = styled.div`
  overflow: hidden;
  ${p => p.expanded ? '' : 'height: 0px;'}
  transition: height 0.2s;
`
const SubHeader = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.primary};
  padding: 10px 0px;
  /* cursor: pointer; */
  opacity: 0.9;
  &:hover {
    opacity: 1;
  }
  & > div:first-child {
    flex-grow: 1;
  }
  /*
  & > div:last-child {
    flex-shrink: 0;
    font-size: 0.7em;
    transition: transform 0.1s;
    ${p => p.expanded ? '' : 'transform: rotate(180deg);'}
  }
  */
`

const ProcessingText = styled.div`
  ${p => p.processing ? '' : 'visibility: hidden;'}
`

const Description = styled.div`
  font-size: 1em;
  margin-bottom: 10px;
  color: rgb(60, 60, 60);
`
const InvalidText = styled.div`
  color: ${colors.warning};
  ${p => p.invalid ? '' : 'visibility: hidden;'}
  margin-top: 5px;
`

const TextInput = styled(Text)`
  font-size: 2em;
  min-width: 50%;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: 1000px;
  /* ${animation('slide')} */
`

const CloseButton = styled.div.attrs({ children: <>&#10005;</> })`
  font-size: 1.2em;
  margin-bottom: 10px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 50%;
  color: ${colors.primary};
  width: 27px;
  height: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.9;
  &:hover {
    opacity: 1;
    color: white;
    background-color: ${colors.primary};
  }
  transition: color 0.15s, background-color 0.15s;
`

const GreyUnderlay = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  overflow: auto;
  background-color: rgba(255, 255, 255, 0.85);
  /*
  @supports (backdrop-filter: blur(6px)) {
    backdrop-filter: blur(6px);
    background-color: rgba(255, 255, 255, 0.9);
  }
  */
  z-index: 10000000000000;
  /* ${animation('fade')} */
`

const Container = styled.div`
  flex-grow: 1;
  max-width: 1000px;
  padding: 20px 20px 40px;
  border: 1px solid lightgrey;
  margin: 20px 0px;
`


export default SearchComponent