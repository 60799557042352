import VState from 'signal/v-state'
import React from 'react'
import { Portal } from 'signal/utility-components'
import { animation } from 'analyse2/css'

const dbUpgradeNoticeState = new VState(false)

const DBUpgradeNotice = () => {
  const show = dbUpgradeNoticeState.use()
  if (!show) return null
  else return (
    <Portal>
      <div css={`${animation('fade', 'slide')}; z-index: 100000000000000000; position: fixed; right: 20px; bottom: 20px; opacity: 0.9; font-size: 1.1em; background-color: black; padding: 7px; border-radius: 3px; color: white;`} children='Upgrading database. Please wait...' />
    </Portal>
  )
}

export { DBUpgradeNotice, dbUpgradeNoticeState }