import React, { useEffect, useLayoutEffect, useState, forwardRef, useMemo } from 'react'
import { useHistory, useLocation, MemoryRouter, Route } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import styled from 'styled-components'
import { NavBar } from 'signal-components'
import { valuesAnchor as autoCompleteAnchor } from 'signal-components/AutoComplete'
import Breadcrumbs, { Provider as BreadcrumbsProvider } from 'signal-components/Breadcrumbs'
import { valuesAnchor as dropdownAnchor } from 'signal-components/Dropdown'
import { Provider as MutationTypeProvider } from 'signal-components/MutationType'
import { CancerCohortSelectionProvider } from 'signal-components/CancerCohortSelection'
import { Provider as PanelProvider } from 'signal-components/Panel'
import { Provider as ViewportProvider } from 'signal-components/Viewport'
import Routes from 'signal-routes'
import { mainHistory as mainHistoryState, sidebarHistory as sidebarHistoryState, experimentalChoiceState } from 'signal-state'
import { CentrePaneOverlayAnchor } from 'analyse2/CentrePaneOverlay'
import { sidebarOpenState, SidebarNavbar, sidebarHasBeenOpenedState } from 'signal/sidebar'
import { useElement, useLayoutEffectNoWarning } from 'signal/utility-hooks'
import queryString from 'query-string'
import GlobalProjectProgress from 'analyse2/GlobalProjectProgress'
import { createGlobalStyle } from 'styled-components'

const DisableUserSelect = createGlobalStyle`
  * {
    user-select: none;
    pointer-events: none;
  }
`

const initialState = {
  resizing: false,
  sidebarWidth: null,
  clientX: null,
  sidebarFrozenWidth: null,
  mainFrozenWidth: null
}

const useResizeSidebar = (mainElement, dividerElement, sidebarElement) => {
  const [state, setState] = useState(initialState)

  useLayoutEffectNoWarning(() => {
    if (mainElement && dividerElement && sidebarElement) {

      const onDividerMouseDown = e => {
        const sidebarWidth = sidebarElement.getBoundingClientRect().width
        const mainWidth = mainElement.getBoundingClientRect().width
        const clientX = e.clientX

        setState({
          resizing: true,
          sidebarWidth: sidebarWidth,
          sidebarFrozenWidth: sidebarWidth,
          mainFrozenWidth: mainWidth,
          clientX: clientX
        })

        window.addEventListener('mousemove', onMouseMove)
        window.addEventListener('mouseup', onMouseUp)
      }

      const onMouseUp = () => {
        setState(state => ({
          ...state,
          resizing: false,
          sidebarFrozenWidth: null,
          mainFrozenWidth: null,
          clientX: null
        }))
        window.removeEventListener('mousemove', onMouseMove)
        window.removeEventListener('mouseup', onMouseUp)
      }

      const onMouseMove = e => {
        e.preventDefault()
        const clientX = e.clientX
        setState(state => {
          const xDifference = clientX - state.clientX
          const newSidebarWidth = state.sidebarWidth - xDifference
          return {
            ...state,
            clientX: clientX,
            sidebarWidth: newSidebarWidth
          }
        })
      }

      dividerElement.addEventListener('mousedown', onDividerMouseDown)

      return () => {
        setState(initialState)
        dividerElement.removeEventListener('mousedown', onDividerMouseDown)
      }
    }
  }, [mainElement, dividerElement, sidebarElement])

  return {
    sidebarStyle: {
      maxWidth: '50%',
      minWidth: '100px',
      ...(state.sidebarWidth === null
        ? {}
        : {
          width: `${state.sidebarWidth}px`,
          flexBasis: `${state.sidebarWidth}px`,
          flexGrow: 0,
          flexShrink: 0
        }
      )
    },
    sidebarContentStyle: {
      width: state.resizing ? `${state.sidebarFrozenWidth}px` : 'auto'
    },
    mainContentStyle: {
      width: state.resizing ? `${state.mainFrozenWidth}px` : 'auto',
      display: 'flex',
      flexDirection: 'column',
      flexGrow: '1',
      position: 'relative'
    },
    resizing: state.resizing
  }
}

const ExperimentalHandler = () => {
  const history = useHistory()
  const location = useLocation()
  const { pathname, search } = location
  const params = useMemo(() => queryString.parse(search), [search])
  const experimentalChoice = experimentalChoiceState.use()

  useLayoutEffect(() => {
    if (experimentalChoice && !params.experimental) {
      const newParams = { ...params, experimental: true }
      const newSearch = queryString.stringify(newParams)
      history.replace(`${pathname}?${newSearch}`)
    }
  }, [history, pathname, params, experimentalChoice])

  return null
}
const setMainHistory = mainHistoryState.set.bind(mainHistoryState)
const App = props => {
  const history = useHistory()
  useEffect(() => {
    setMainHistory(history)
  }, [history])

  const sidebarOpen = sidebarOpenState.use()

  const [mainElement, setMainElement] = useElement()
  const [dividerElement, setDividerElement] = useElement()
  const [sidebarElement, setSidebarElement] = useElement()

  const {
    sidebarStyle,
    sidebarContentStyle,
    mainContentStyle,
    resizing
   } = useResizeSidebar(mainElement, dividerElement, sidebarElement)

  const experimentalChoice = experimentalChoiceState.use()

  return (
    <BreadcrumbsProvider>
      <MutationTypeProvider>
        <CancerCohortSelectionProvider>
          <PanelProvider panel='main'>
            <Container>
              {resizing && <DisableUserSelect />}
              <GlobalProjectProgress />
              <NavBarContainer experimentalButton={experimentalChoice}>
                <NavBar />
              </NavBarContainer>
              <Content>
                <Main ref={setMainElement}>
                  <ExperimentalHandler />
                  <ViewportProvider viewportElement={mainElement}>
                    <Breadcrumbs />
                    <div style={mainContentStyle}>
                      <Routes displayFooter />
                      <CentrePaneOverlayAnchor />
                    </div>
                  </ViewportProvider>
                </Main>
                {sidebarOpen && <Divider ref={setDividerElement} />}
                <Sidebar open={sidebarOpen} style={sidebarStyle}>
                  <BreadcrumbsProvider>
                    <MutationTypeProvider>
                      <PanelProvider panel='sidebar'>
                        <MemoryRouter
                          initialEntries={['/explore']}
                          initialIndex={0}
                        >
                          <QueryParamProvider ReactRouterRoute={Route}>
                            <SidebarContent style={sidebarContentStyle} resizing={resizing} setViewportElement={setSidebarElement} viewportElement={sidebarElement} />
                          </QueryParamProvider>
                        </MemoryRouter>
                      </PanelProvider>
                    </MutationTypeProvider>
                  </BreadcrumbsProvider>
                </Sidebar>
              </Content>
            </Container>
            <div ref={autoCompleteAnchor.set} />
            <div ref={dropdownAnchor.set} />
          </PanelProvider>
        </CancerCohortSelectionProvider>
      </MutationTypeProvider>
    </BreadcrumbsProvider>
  )
}

export default App

const Divider = styled(forwardRef((props, ref) => {
  return <div ref={ref} {...props}>
    <div>•</div>
    <div css='margin-top: -9px; margin-bottom: -9px;'>•</div>
    <div>•</div>
  </div>
}))`
  flex-basis: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: rgb(190, 190, 190);
  font-weight: bold;
  justify-content: center;
  border-left: 1px solid rgb(220, 220, 220);
  border-right: 1px solid rgb(220, 220, 220);
  background-image: linear-gradient(to right, rgb(230, 230, 230), rgb(248, 248, 248), rgb(230, 230, 230));
  flex-shrink: 0;
  cursor: col-resize;

`
const setSidebarHistory = sidebarHistoryState.set.bind(sidebarHistoryState)

const SidebarContent = ({ style, resizing, viewportElement, setViewportElement, ...props }) => {

  const history = useHistory()
  useEffect(() => {
    setSidebarHistory(history)
  }, [history])

  return (
    <>
      <SidebarNavbar />
      <SidebarPage style={style} ref={setViewportElement}>
        <ViewportProvider viewportElement={viewportElement}>
          <Routes />
        </ViewportProvider>
      </SidebarPage>
    </>
  )
}

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`

const NavBarContainer = styled.div`
  flex: 0 0 auto;
`

const Content = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  overflow: hidden;

  @media print {
    flex: none;
  }
`

const Main = styled.div`
  flex: 1 1 70%;
  overflow-y: auto;
  position: relative;
  z-index: 100;
  display: flex;
  flex-direction: column;

  @media print {
    overflow-y: auto;
  }
`
const Sidebar = styled(({ open, ...props }) => {
  useEffect(() => {
    open && sidebarHasBeenOpenedState.set(true)
  }, [open])
  return <div {...props} />
})`
  flex: 1 1 30%;
  display: ${p => p.open ? 'flex' : 'none'};
  flex-direction: column;
`

const SidebarPage = styled.div`
  position: relative;
  flex: 1 1 auto;
  overflow-y: auto;
`
