import { allTrue } from 'signal/utility-functions'
import taskExistsOnServer from './taskExistsOnServer'
import taskIsLocal from './taskIsLocal'
import erroredTooRecently from './erroredTooRecently'

const taskRequiresSubmission = task => {
  const erroredTooRecently1 = erroredTooRecently(task)
  const timeToWait = (!taskExistsOnServer(task) && !taskIsLocal(task) && erroredTooRecently1) ? erroredTooRecently1 : null
  return [
    allTrue(!taskExistsOnServer(task), !taskIsLocal(task), !erroredTooRecently1),
    timeToWait,
    task
  ]
}

export default taskRequiresSubmission