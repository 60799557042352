async function setProjectAlias (projectId, alias) {
  if (!this.dbClient) return false
  return this.dbClient.transaction('rw', ['projects'], async () => {
    const projects = await this.dbClient.projects.toArray()
    const project = projects.find(({ id }) => id === projectId)
    if (!project) return false
    const conflictingProject = projects.find(
      otherProject => otherProject.alias === alias && otherProject.id !== project.id
    )
    if (conflictingProject) return false
    else {
      await this.dbClient.projects.update(projectId, { alias })
      return true
    }
  })
}

export default setProjectAlias