import VState from 'signal/v-state'
import { persistentWorker } from 'analyse2/worker'

const acceptStorageState = new VState(false)

if (typeof window !== 'undefined' && typeof localStorage !== 'undefined') {
  window.addEventListener('storage', e => e.key === 'acceptStorage' && acceptStorageState.set(e.newValue === 'true'))
  const acceptStorage = localStorage.getItem('acceptStorage')
  acceptStorageState.set(acceptStorage === 'true')
}

acceptStorageState.writeTransform = function (newValue = true, prevValue) {
  if (typeof localStorage !== 'undefined') {
    localStorage.setItem('acceptStorage', newValue ? 'true' : 'false')
    return newValue
  } else return prevValue
}

if (persistentWorker) {
  acceptStorageState.subscribe(acceptStorage => persistentWorker.postMessage({ acceptStorage }))
}

export default acceptStorageState