import { allTrue, reduceByKey, isFunction, shallowEqual } from 'signal/utility-functions'

function setProjectFilters (projectId, filtersOrFunction) {
  if (!this.dbClient) return
  return this.dbClient.transaction('rw', ['projects'], async () => {
    let filters
    if (isFunction(filtersOrFunction)) {
      const project = await this.dbClient.projects.get(projectId) || {}
      const filtersFromDb = project.filters || {}
      filters = filtersOrFunction(filtersFromDb)
    } else filters = filtersOrFunction
    await this.dbClient.projects.update(projectId, { filters })
  })
}

export default setProjectFilters