import React from 'react'
import styled from 'styled-components'

import { Dropdown } from 'signal/components'
import {
  identityState,
  modeState
} from 'signal/state'

const Mode = props => {
  const identity = identityState.use()
  const mode = modeState.use()

  if (identity) {
    return (
      <Container>
        <Identity identity={identity} />
        <Selector
          identity={identity}
          mode={mode}
        />
        <LogOut onClick={() => { identityState.set(null) }}>
          Sign out
        </LogOut>
      </Container>
    )
  } else {
    return null
  }
}

export default Mode

const Identity = props => {
  if (props.identity.type === 'google') {
    return (
      <IdentityContainer>
        <SignedInAs>Signed in as</SignedInAs>
        <div>{props.identity.identity.name}</div>
      </IdentityContainer>
    )
  } else if (props.identity.type === 'reviewer') {
    return (
      <IdentityContainer>
        <SignedInAs>Signed in as</SignedInAs>
        <div>Reviewer</div>
      </IdentityContainer>
    )
  } else {
    return null
  }
}

const Selector = props => {
  if (
    props.identity.type === 'google' &&
    !props.identity.identity.permissions.experimental
  ) {
    return 'You do not have permission to switch modes'
  } else {
    return (
      <Dropdown
        label='Mode'
        values={[
          { id: 'production', label: 'Production' },
          { id: 'prerelease', label: 'Prerelease' }
        ]}
        value={props.mode}
        onChange={m => modeState.set(m)}
      />
    )
  }
}

const Container = styled.div`
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  color: rgb(70, 70, 70);
  background-color: rgb(240, 240, 240);
`

const IdentityContainer = styled.div`
  height: 100%;
  margin-right: 5px;
  display: flex;
  flex-direction: column;
`

const SignedInAs = styled.div`
  font-size: 10px;
  margin-top: 1px;
  margin-bottom: 8px;
`

const LogOut = styled.div`
  cursor: pointer;
  margin: 0 10px;
`
