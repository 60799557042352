function getSubmittedTaskIdsWithStalePrerequisiteServerIds () {
  if (!this.fileOverviews || !this.tasks) return []
  const taskIdsToFailByFailedDependency = []

  for (let taskId in this.tasks) {
    const { serverId, prerequisiteFileIds, prerequisiteFileServerIds, complete } = this.tasks[taskId]
    if (!serverId || complete) continue
    for (let fileId of prerequisiteFileIds) {
      const lastSubmittedServerId = prerequisiteFileServerIds && prerequisiteFileServerIds[fileId]
      if (!prerequisiteFileServerIds || !lastSubmittedServerId) {
        console.warn(`Missing prerequisiteFileServerIds for task ${taskId}`)
        taskIdsToFailByFailedDependency.push(taskId)
        break
      }
      const fileOverview = this.fileOverviews[fileId]
      if (fileOverview) {
        const { serverId } = fileOverview
        if (!serverId || serverId !== lastSubmittedServerId) {
          console.log('failing task ' + taskId + ' due to failed dependency')
          taskIdsToFailByFailedDependency.push(taskId)
          break
        }
      } else console.error(`No fileOverview found for prerequisite file ${fileId} of task ${taskId}`)
    }
  }

  return taskIdsToFailByFailedDependency
}

export default getSubmittedTaskIdsWithStalePrerequisiteServerIds