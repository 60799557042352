import VState from 'signal/v-state'
import { persistentWorker } from 'analyse2/worker'
import identityState from './identity'
import modeState from './mode'

const experimentalState = VState
  .join(
    identityState,
    modeState
  )
  .derive(([
    identity,
    mode
  ]) => {
    if (identity && mode === 'prerelease') {
      if (identity.type === 'google' && identity.identity.permissions.experimental) {
        return true
      } else if (identity.type === 'reviewer') {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  })

experimentalState.subscribe(experimental => {
  persistentWorker.postMessage({ experimental })
})

export default experimentalState
