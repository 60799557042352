import runOnTransientWorker from './runOnTransientWorker'

const persistentWorker = typeof Worker === 'undefined' || typeof window === 'undefined'
  ? null
  : new Worker(`${__ROOT__}/worker.persistent.js`)

if (persistentWorker) {
  persistentWorker.addEventListener('message', e => {
    if (e.data.requestTransientPassThrough) {
      console.log('Going to request job on transient worker')
      ;(async () => {
        const [task, payload] = e.data.requestTransientPassThrough
        console.log('calling runOnTransientWorker with', task, payload)
        const response = await runOnTransientWorker(task, payload)
        console.log('Posting message from main thread to persistent worker', { reportBackTransientPassThrough: response })
        persistentWorker.postMessage({ reportBackTransientPassThrough: response })
      })()
    }
  })
}

export default persistentWorker
