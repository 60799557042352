import React, { useContext, createContext, useState, useCallback } from 'react'

const cancerCohortContext = createContext()
const setCancerCohortContext = createContext()

const CancerCohortSelectionProvider = ({ children, ...props }) => {
  const [cohortId, _setCohortId] = useState(3)
  const setCohortId = useCallback(value => _setCohortId(+value), [_setCohortId])
  return (
    <setCancerCohortContext.Provider value={setCohortId} {...props}>
      <cancerCohortContext.Provider value={cohortId} children={children} />
    </setCancerCohortContext.Provider>
  )
}

const useCancerCohortSelection = () => useContext(cancerCohortContext)
const useSetCancerCohortSelection = () => useContext(setCancerCohortContext)

export {
  CancerCohortSelectionProvider,
  useCancerCohortSelection,
  useSetCancerCohortSelection
}