import { shallowEqual } from 'signal/utility-functions'
import { useRef } from 'react'

const useMemoisedValue = (value, equalityFn = shallowEqual) => {
  const memoisedValueRef = useRef()
  const equal = equalityFn(value, memoisedValueRef.current)
  if (!equal) memoisedValueRef.current = value
  return memoisedValueRef.current
}

export default useMemoisedValue