import { allTrue } from 'signal/utility-functions'
import { getSecondaryVariantSetId as getSecondaryVariantSetId1 } from '../utils'

function getSecondaryVariantSetId (sampleId, primaryVariantSetId) {
  if (!allTrue(this.samples, this.projects, this.variantSets)) return
  const sample = this.samples[sampleId]
  if (!sample) {
    console.warn('Sample does not exist', sampleId)
    return
  }
  const projectId = sample.projectId
  const project = this.projects[projectId]
  if (!project) {
    console.warn('Project does not exist', projectId)
    return
  }
  const primaryVariantSet = this.variantSets[primaryVariantSetId]
  if (!primaryVariantSet) return
  const variantSetsForSample = this.variantSets.filter(variantSet => variantSet.sampleId === sampleId)
  const secondaryVariantSetId = getSecondaryVariantSetId1(sample, project, variantSetsForSample, primaryVariantSet)
  return secondaryVariantSetId
}

export default getSecondaryVariantSetId