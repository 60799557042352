import Dexie from 'dexie'
import { omit } from 'ramda'
import { dbUpgradeNoticeState } from 'analyse2/db-upgrade-notice'
import initialSharedState from './initialSharedState'
import errorDialogueState from 'analyse2/error-dialogue-state'
import isomorphicUpgradeToFixEmptyTimingRegionFiles from './upgradeToFixEmptyTimingRegionFiles'
import patchDb from './patchDb'
import dbVersions from './dbVersions'

Dexie.debug = true
export const createDb = async () => {
  const db = new Dexie('Analyse2')
  db.version(1).stores({
    projects: 'id, created',
    tasks: 'id, projectId, submitted, serverId',
    fileOverviews: 'id, type, projectId, serverId',
    files: 'id, projectId',
    variantSets: 'id, projectId, sampleId',
    samples: 'id, projectId, selected',
    browserSessions: 'id, lastCheckedIn',
    sharedState: 'id',
    locks: 'key, browserSessionId',
    sampleCollections: 'id, projectId'
  })
  db.version(2).stores({
    fileOverviews: 'id, type, projectId, serverId, parentTaskId'
  })
  db.version(3).stores({
    _files: 'id, projectId',
    _browserSessions: 'id, lastCheckedIn'
  }).upgrade(transaction => {
    dbUpgradeNoticeState.set(true)
    return transaction
      .browserSessions
      .toArray()
      .then(objs => transaction._browserSessions.bulkAdd(objs))
      .then(() => transaction.files.toArray())
      .then(objs => transaction._files.bulkAdd(objs))
  })
  db.version(4).upgrade(transaction => {
    dbUpgradeNoticeState.set(true)
    return (async () => {
      const samplesToUpdate = await transaction
        .samples
        .toArray()
        .then(samples => samples.filter(({ organId }) => organId && organId !== +organId))
      await Promise.all(samplesToUpdate.map(sample => transaction.samples.update(sample.id, { organId: +sample.organId })))
    })()
  })
  db.version(5).upgrade(transaction => {
    dbUpgradeNoticeState.set(true)
    return (async () => {
      const samplesToUpdate = await transaction
        .samples
        .toArray()
        .then(samples => samples.filter(({ doubleSubstitutionUnmatchedVariantsListFileId }) => doubleSubstitutionUnmatchedVariantsListFileId))
      const variantSetsToDelete = await transaction
        .variantSets
        .toArray()
        .then(variantSets => variantSets.filter(({ mutationType }) => mutationType === 'DOUBLE_SUBSTITUTION'))
      const alteredSamples = samplesToUpdate.map(sample => omit([
        'doubleSubstitutionLaddingStrandVariantsListFileId',
        'doubleSubstitutionLeadingStrandVariantsListFileId',
        'doubleSubstitutionReplicationTimingRegionVariantsLists',
        'doubleSubstitutionUnmatchedVariantsListFileId',
        'doubleSubstitutionVariantsChannelsFileId',
        'doubleSubstitutionVariantsFileId',
        'nonPassDoubleSubstitutionVariantsListFileId'
      ], sample))
      const variantSetIdsToDelete = variantSetsToDelete.map(({ id }) => id)
      await Promise.all(alteredSamples.map(sample => transaction.samples.put(sample)))
      await Promise.all(variantSetIdsToDelete.map(id => transaction.variantSets.delete(id)))
    })()
  })
  db.version(6).upgrade(upgradeToFixBootstrapOf1)
  db.version(7).upgrade(upgradeToFixBootstrapOf1)
  db.version(8).upgrade(upgradeToFixEmptyTimingRegionFiles)

  dbVersions.forEach(({ version, fn }) => {
    db.version(version).upgrade(transaction => {
      dbUpgradeNoticeState.set(true)
      return patchDb({ transaction, fn })
    })
  })

  db.createTask = ({
    id,
    projectId,
    type,
    params,
    outputs,
    local,
    prerequisiteFileIds = []
  }) => db.tasks.put({
    id: id,
    projectId: projectId,
    type: type,
    complete: false,
    submitted: null,
    error: null,
    params: params,
    response: null,
    outputs: outputs,
    prerequisiteFileIds: prerequisiteFileIds,
    serverId: null,
    touched: null,
    local: local
  })
  db.createFileOverview = ({
    id,
    type,
    sampleId,
    projectId,
    parentTaskId,
    format = 'CSV'
  }) => db.fileOverviews.put({
    id: id,
    type: type,
    sampleId: sampleId,
    projectId: projectId,
    format: format,
    parentTaskId: parentTaskId,
    fetched: false,
    serverId: null,
    touched: null
  })
  db.files = db._files
  await db.transaction(
    'rw',
    ['sharedState'],
    async () => {
      const sharedState = await db.sharedState.get(1)
      if (!sharedState) await db.sharedState.put(initialSharedState)
    }
  )
  dbUpgradeNoticeState.set(false)
  return db
}

// STILL NEED TO DO THIS IN IMPORT PROJECT FORM
const upgradeToFixEmptyTimingRegionFiles = transaction => {
  dbUpgradeNoticeState.set(true)
  return (async () => {
    const samples = await transaction.samples.toArray()
    for (let sample of samples) {
      if (sample.replicationTimingRegionVariantsLists && sample.replicationTimingRegionVariantsLists.length === 0) {
        await transaction.samples.where('id').equals(sample.id).modify(sample => {
          delete(sample.replicationTimingRegionVariantsLists)
        })
      }
      if (sample.doubleSubstitutionReplicationTimingRegionVariantsLists && sample.doubleSubstitutionReplicationTimingRegionVariantsLists.length === 0) {
        await transaction.samples.where('id').equals(sample.id).modify(sample => {
          delete(sample.doubleSubstitutionReplicationTimingRegionVariantsLists)
        })
      }
    }
    const tasks = await transaction.tasks.toArray()
    for (let task of tasks) {
      if (task.outputs) {
        const replFilesIndex = task.outputs.findIndex(output => output.key === 'replicationTimingRegionVariantsFiles' && output.array.length === 0)
        if (replFilesIndex > -1) {
          await transaction.tasks.where('id').equals(task.id).modify(task => {

            task.outputs = [...task.outputs.slice(0, replFilesIndex), ...task.outputs.slice(replFilesIndex + 1)]
            task.complete = false
            task.serverId = null
            task.submitted = null
          })
        }
      }
    }
  })()
}

const upgradeToFixBootstrapOf1 = transaction => {
  dbUpgradeNoticeState.set(true)
  return (async () => {
    const sampleCollections = await transaction.sampleCollections.toArray()
    const samples = await transaction.samples.toArray()
    const tasks = await transaction.tasks.toArray()
    const newSampleCollections = sampleCollections.reduce((acc, sampleCollection) => {
      if (sampleCollection.analyses && sampleCollection.analyses.sigFit && sampleCollection.analyses.sigFit.numOfBootstraps === 1) {
        const newSampleCollection = {
          ...sampleCollection,
          analyses: {
            ...sampleCollection.analyses,
            sigFit: {
              ...sampleCollection.analyses.sigFit,
              numOfBootstraps: 2
            }
          }
        }
        acc.push(newSampleCollection)
      }
      return acc
    }, [])
    const newSamples = samples.reduce((acc, sample) => {
      let sampleNeedsUpdate = false

      if (sample.selectedAnalyses && sample.selectedAnalyses.sigFit) {
        for (let sigFit of sample.selectedAnalyses.sigFit) {
          if (sigFit.numOfBootstraps === 1) sampleNeedsUpdate = true
        }
      }

      if (sampleNeedsUpdate) {
        const newSample = {
          ...sample,
          selectedAnalyses: {
            ...sample.selectedAnalyses,
            sigFit: []
          }
        }
        for (let sigFit of sample.selectedAnalyses.sigFit) {
          newSample.selectedAnalyses.sigFit.push({
            ...sigFit,
            numOfBootstraps: sigFit.numOfBootstraps === 1 ? 2 : sigFit.numOfBootstraps
          })
        }
        acc.push(newSample)
      }

      return acc
    }, [])

    const newTasks = tasks.reduce((acc, task) => {
      if (task.type === 'REINTRODUCTION' && task.params && task.params.numOfBootstraps && task.params.numOfBootstraps.value === 1) {
        acc.push({
          ...task,
          params: {
            ...task.params,
            numOfBootstraps: {
              ...task.params.numOfBootstraps,
              value: 2
            }
          },
          errorCount: null
        })
      }
      return acc
    }, [])

    if (newSampleCollections.length > 0) await Promise.all(newSampleCollections.map(x => transaction.sampleCollections.put(x)))
    if (newSamples.length > 0) await Promise.all(newSamples.map(x => transaction.samples.put(x)))
    if (newTasks.length > 0) await Promise.all(newTasks.map(x => transaction.tasks.put(x)))

    if (newSampleCollections.length > 0 || newSamples.length > 0 || newTasks.length > 0) {
      errorDialogueState.set(x => [
        ...x,
        {
          text: `FIX APPLIED: We discovered an issue with one or more of your analyses. Your signature fitting requests specifying 1 bootstrap have been altered to now request 2 bootstraps. Requesting 1 bootstrap is not supported but was erroneously allowed. We apologise for the inconvenience. If you are still experiencing issues or believe you're seeing this message in error, please email us via signal@mutationalsignatures.com`
        }
      ])
    }
  })()
}

export default createDb
