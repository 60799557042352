const convertToReference = (csv, signatures) => {
  const lines = csv.split(/\n/).filter(Boolean)
  lines.shift()
  const newContributions = {}
  lines.forEach(line => {
    const [ogId, count] = line.split(',')
    const signature = signatures[ogId]
    if (ogId !== 'unassigned' && signature.referenceSignatureContributions) {
      for (const contributingSignatureId in signature.referenceSignatureContributions) {
        const contribution = signature.referenceSignatureContributions[contributingSignatureId]
        if (!Object.hasOwnProperty.call(newContributions, `referenceCancerSignature::${contributingSignatureId}`)) newContributions[`referenceCancerSignature::${contributingSignatureId}`] = 0
        newContributions[`referenceCancerSignature::${contributingSignatureId}`] += contribution * count
      }
    } else newContributions[ogId] = count
  })
  let newCsv = 'id,0'
  for (let id in newContributions) {
    newCsv += `\n${id},${newContributions[id]}`
  }
  return newCsv
}

export default convertToReference