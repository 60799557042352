function getSamplesForProject (projectId, sampleCollectionId) {
  if (!this.samples) return
  const samples = Object.values(this.samples)
  const samplesForProject = []
  for (let sample of samples) {
    if (sample.projectId === projectId && !sample.delete) {
      if (sampleCollectionId && sample.sampleCollection !== sampleCollectionId) continue
      else samplesForProject.push(sample)
    }
  }
  return samplesForProject
}

export default getSamplesForProject