import { allTrue } from 'signal/utility-functions'
import convertFailed from './convertFailed'
import convertResultsProcessed from './convertResultsProcessed'
import fileIsDownloaded from './fileIsDownloaded'

const sampleCollectionReadyToProcess = (sampleCollection, outputFileOverview) => allTrue(
  fileIsDownloaded(outputFileOverview),
  !convertResultsProcessed(sampleCollection),
  !convertFailed(sampleCollection)
)

export default sampleCollectionReadyToProcess