import React from 'react'
import styled from 'styled-components'

import { Link } from 'signal-components'

const TextLink = ({ children, ...props }) => (
  <Link {...props}>
    <Contents>
      {children}
    </Contents>
  </Link>
)

export default TextLink

const Contents = styled.span`
  color: #04869b;
  text-decoration: underline;
  transition: color 0.2s ease;

  &:hover {
    color: black;
  }
`
