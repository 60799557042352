import convertToReferenceFn from 'analyse2/convert-to-reference'

const makeReadableContributionsCsv = (csv, { signatures, convertToReference, ...options }) => {
  try {
    // convertToReference will only work with singleSolution
    const data = convertToReference ? convertToReferenceFn(csv, signatures) : csv

    const { singleSolution } = options
    const _lines = data.split(/\n/)
    const lines = _lines.filter(Boolean)
    const processHeader = (cell, columnIndex) =>
      columnIndex === 0 ? 'Signature' : singleSolution ? 'Contribution' : `Solution_${columnIndex}`

    const processData = (cell, columnIndex) => {
      return columnIndex === 0 ? `"${cell === 'unassigned' ? 'Unassigned' : signatures[cell].name}"` : cell
    }

    const newLines = lines.map((line, lineIndex) => {
      const lineArray = line.split(',')
      const newLineArray = lineArray.map(lineIndex === 0 ? processHeader : processData)
      return newLineArray.join(',')
    })

    return newLines.join('\n') + '\n'
  } catch (e) {
    console.error(e)
    return
  }
}

const makeReadableMultiCatalogCSV = (catalogData, { substitutionSpec }) => {
  try {
    const newHeaderArr = catalogData.map(({ label }) => `"${label}"`)
    newHeaderArr.unshift('Substitution')
    const newHeader = newHeaderArr.join(',')
    const dataObjects = catalogData.map(({ data }) => {
      const lines = data.split(/\n/).filter(Boolean).slice(1)
      const cat = {}
      for (let line of lines) {
        const [id, count] = line.split(',')
        cat[id] = count
      }
      return cat
    })
    const otherLines = substitutionSpec.order.map(id => {
      const { change, original, upstream, downstream } = substitutionSpec.lookup[id]
      const substitutionText = `${upstream}[${original}>${change}]${downstream}`
      const newLineData = dataObjects.map(({ [id]: count }) => count)
      return [substitutionText, ...newLineData].join(',')
    })
    return [newHeader, ...otherLines].join('\n') + '\n'
  } catch (e) {
    return
  }
}

const makeReadableCatalogCsv = (unreadableCSV, { alias, substitutionSpec }) => {
  try {
    const lines = unreadableCSV.split(/\n/).filter(Boolean).slice(1)
    const newLines = lines.map(line => {
      const lineArray = line.split(',')
      const substitutionId = lineArray[0]
      const { change, original, upstream, downstream } = substitutionSpec.lookup[substitutionId]
      const substitutionText = `${upstream}[${original}>${change}]${downstream}`
      return `${substitutionText},${lineArray[1]}`
    })
    newLines.unshift(`Substitution,"${alias}"`)
    return newLines.join('\n') + '\n'
  } catch (e) {
    return
  }
}

const makeReadableDbsCatalogCsv = (unreadableCSV, { alias, mutationSpec }) => {
  try {
    const lines = unreadableCSV.split(/\n/).filter(Boolean).slice(1)
    const newLines = lines.map(line => {
      const lineArray = line.split(',')
      const mutationId = lineArray[0]
      const { change, original } = mutationSpec.lookup[mutationId]
      const mutationText = `${original}>${change}`
      return `${mutationText},${lineArray[1]}`
    })
    newLines.unshift(`doubleSubstitution,"${alias}"`)
    return newLines.join('\n') + '\n'
  } catch (e) {
    return
  }
}
const makeReadableIndelCatalogCsv = (unreadableCSV, { alias, mutationSpec }) => {
  try {
    const lines = unreadableCSV.split(/\n/).filter(Boolean).slice(1)
    const newLines = lines.map(line => {
      const lineArray = line.split(',')
      const mutationId = lineArray[0]
      const { label } = mutationSpec.lookup[mutationId]
      return `"${label}",${lineArray[1]}`
    })
    newLines.unshift(`indel,"${alias}"`)
    return newLines.join('\n') + '\n'
  } catch (e) {
    return
  }
}

function makeReadableTimingRegionCatalog (unreadableCSV) {
  return unreadableCSV
}
const makeReadableCorrelationsCsv = (unreadableCSV, { signatures }) => {
  try {
    const lines = unreadableCSV.split(/\n/).filter(Boolean)
    const header = lines.shift()
    const headerArr = header.split(',')
    headerArr.shift()
    const newHeaderArr = headerArr.map(signatureId => `"${signatures[signatureId].name}"`)
    newHeaderArr.unshift('Signature')
    const newHeader = newHeaderArr.join(',')
    const newLines = lines.map(line => {
      const lineArr = line.split(',')
      const id = lineArr.shift()
      const name = `"${signatures[id].name}"`
      const newLineArr = lineArr.map(number => number || '0')
      return `${name},${newLineArr.join(',')}`
    })
    newLines.unshift(newHeader)
    return newLines.join('\n') + '\n'
  } catch (e) {
    console.error(e)
    return
  }
}

const makeReadableKataegisRegionsCsv = (data, { variantsCsv }) => {
  try {
    const regionsLinesArr = data.split('\n').filter(Boolean)
    regionsLinesArr.shift()
    const idToChrPos = {}
    const regionLinesArrArr = regionsLinesArr.map(line => {
      const lineArr = line.split(',')
      idToChrPos[lineArr[1]] = {}
      idToChrPos[lineArr[2]] = {}
      return lineArr
    })
    const variantsLinesArr = variantsCsv.split('\n')
    variantsLinesArr.shift()
    variantsLinesArr.forEach(line => {
      const [id, chr, pos] = line.split(',')
      if (Object.hasOwnProperty.call(idToChrPos, id)) {
        idToChrPos[id].chr = chr
        idToChrPos[id].pos = pos
      }
    })
    const newLines = regionLinesArrArr.map(([, startId, endId]) => {
      const chr = idToChrPos[startId].chr
      const startPos = idToChrPos[startId].pos
      const endPos = idToChrPos[endId].pos
      return `${chr},${startPos},${endPos}`
    })
    newLines.unshift(`chr,start_pos,end_pos`)
    return newLines.join('\n') + '\n'
  } catch (e) {
    return
  }
}

const makeReadableCsv = (unreadableCSV, { type, ...options }) => {
  if (!unreadableCSV) return
  switch (type) {
    case 'CONTRIBUTIONS':
      return makeReadableContributionsCsv(unreadableCSV, options)
    case 'CATALOG':
      return Array.isArray(unreadableCSV) ? makeReadableMultiCatalogCSV(unreadableCSV, options) : makeReadableCatalogCsv(unreadableCSV, options)
    case 'DBS_CATALOG':
      return makeReadableDbsCatalogCsv(unreadableCSV, options)
    case 'INDEL_CATALOG':
      return makeReadableIndelCatalogCsv(unreadableCSV, options)
    case 'CORRELATIONS':
      return makeReadableCorrelationsCsv(unreadableCSV, options)
    case 'KATAEGIS_REGIONS':
      return makeReadableKataegisRegionsCsv(unreadableCSV, options)
    case 'TIMING_REGION_CATALOG':
      return makeReadableTimingRegionCatalog(unreadableCSV, options)
  }
}

export {
  makeReadableCsv,
  makeReadableContributionsCsv
}
