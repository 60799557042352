import VState from 'signal/v-state'
import sleep from 'sleep-promise'
import { path, flatten } from 'ramda'
import { allTrue, reduceByKey, isFunction, shallowEqual } from 'signal/utility-functions'
import generateId from 'uuid/v1'
import requestLock from 'analyse2/request-lock'
import { fileTouchTimeout, lockPrefixes } from 'analyse2/config'
import { useState, useRef, useEffect, useCallback } from 'react'
import { useShallowSetState, useMemoisedValue, usePrevious } from 'signal/utility-hooks'

function getLocksForFileTouch (fileIds) {
  if (!this.dbClient) return []
  return this.dbClient.transaction('rw', ['locks', 'fileOverviews'], async () => {
    const locks = await Promise.all(fileIds.map(async fileId => {
      const hasLock = await requestLock(`${lockPrefixes.fileTouch}${fileId}`,
        async dbClient => {
          const fileOverview = await dbClient.fileOverviews.get(fileId)
          if (!fileOverview) return false
          const { touched, serverId } = fileOverview
          if (!serverId) return false
          if (Date.now() - touched > fileTouchTimeout) return true
          return false
        }, ['fileOverviews']
      )
      return hasLock && fileId
    }))
    return locks.filter(Boolean)
  })
}

export default getLocksForFileTouch