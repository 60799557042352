import VState from 'signal/v-state'
import { acceptStorageState } from 'analyse2/worker/accept-storage'
import createDb from 'analyse2/createDb'

const dbClientState = new VState()

acceptStorageState.subscribe(async (acceptStorage, unsubscribe) => {
  if (acceptStorage && !dbClientState.get()) {
    const db = await createDb()
    dbClientState.set(db)
    unsubscribe()
  }
})

export default dbClientState