import { css, keyframes } from 'styled-components'

const fade = keyframes`
  0% { opacity: 0 }
  20% { opacity: 0 }
  100% { opacity: 1 }
`

const slide = keyframes`
  0% { transform: translateY(20px) }
  20% { transform: translateY(20px) }
  100% { transform: translateY(0px) }
`

const animations = { fade, slide }

const animation = (...types) =>
  css`animation: ${types.map(type => css`${animations[type]} 0.3s,`)}${keyframes``};`

const slowAnimation = (...types) =>
  css`animation: ${types.map(type => css`${animations[type]} 0.5s,`)}${keyframes``};`

export { slowAnimation }
export default animation
