import { find, isNil } from 'ramda'
import React, { useRef } from 'react'
import FontIcon from 'react-toolbox/lib/font_icon'
import styled from 'styled-components'

import useDropdownValues from './useDropdownValues'
import Values from './Values'
export { default as Context } from './Context'
export { default as useDropdownValues } from './useDropdownValues'

export { default as valuesAnchor } from './valuesAnchor'

const Dropdown = props => {
  const ref = useRef()
  const [active, open, close] = useDropdownValues({
    ref,
    value: props.value,
    values: props.values,
    onChange: props.onChange
  })
  const selected = find(({ id }) => id === props.value, props.values)
  const valueLabel = selected ? selected.label : null

  return (
    <Container ref={ref} disabled={props.disabled}>
      <Values />
      <PointerEvents disabled={props.disabled}>
        <LabelContainer>
          <Label value={props.value}>{props.label}</Label>
        </LabelContainer>
        <TextContainer>
          <Text onClick={() => active ? close() : open()}>
            {valueLabel}
          </Text>
          <Clear
            onChange={props.onChange}
            show={props.clearable && props.value !== null}
          />
          <Arrow
            active={active}
            onClick={() => active ? close() : open()}
          >
            <FontIcon value='expand_more' />
          </Arrow>
        </TextContainer>
      </PointerEvents>
    </Container>
  )
}

export default Dropdown

const Clear = props => {
  if (props.show) {
    return (
      <ClearWrapper
        onClick={() => {
          props.onChange(null)
        }}
      >
        <FontIcon value='clear' />
      </ClearWrapper>
    )
  } else {
    return null
  }
}

const Container = styled.div`
  color: ${props => props.disabled ? 'rgb(150,150,150)' : 'rgb(70,70,70)'};
  cursor: ${props => props.disabled ? 'not-allowed' : 'auto'};
`

const PointerEvents = styled.div`
  pointer-events: ${props => props.disabled ? 'none' : 'auto'};
`

const LabelContainer = styled.div`
  padding-left: 10px;
`

const Label = styled.div`
  font-size: 10px;
  transform: ${props => !isNil(props.value) ? 'none' : 'scale(1.6, 1.6) translateY(12px)'};
  transform-origin: top left;
  transition: transform 0.1s ease;
  pointer-events: none;
`

const TextContainer = styled.div`
  display: flex;
  border-bottom: 1px solid #ccc;
  align-items: center;
`

const Text = styled.div`
  flex: 1 1 auto;
  height: 35px;
  padding: 8px 10px;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
`

const ClearWrapper = styled.div`
  flex: 0 0 auto;
  cursor: pointer;
`

const Arrow = styled.div`
  flex: 0 0 auto;
  cursor: pointer;
  transform: ${props => props.active ? 'rotate(180deg)' : 'rotate(0deg)'};
  transition: transform 0.1s ease;
`
