import React from 'react'

import theme from './theme.css'

class ErrorBoundary extends React.Component {
  constructor (props) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidCatch (error, info) {
    this.setState({ hasError: true })
  }

  render () {
    if (this.state.hasError) {
      return (
        <div className={theme.error}>
          <div>
            <h1>Oops!</h1>
            <p style={{ color: 'grey' }}>
              Something went wrong.
            </p>
            <p style={{ fontSize: '1.15em' }}>
              Try refreshing your browser or email us at <a href='mailto:signal@mutationalsignatures.com'>signal@mutationalsignatures.com</a> and we&apos;ll try to get it fixed as soon as
              possible.
            </p>
          </div>
        </div>
      )
    }
    return this.props.children
  }
}

export default ErrorBoundary
