function getFilesToDownload () {
  const fileIdsToDownload = this.getFileIdsToDownload()
  const filesToDownload = {}
  for (let fileId of fileIdsToDownload) {
    const { serverId } = this.fileOverviews[fileId]
    filesToDownload[fileId] = serverId
  }
  return filesToDownload
}

export default getFilesToDownload