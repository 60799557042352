import React, { useRef } from 'react'
import useComponentSize from '@rehooks/component-size'
import styled from 'styled-components'
import { acceptStorageState } from 'signal/storage'
import { Button } from 'signal-components'

const StorageWarning = props => {
  const acceptStorage = acceptStorageState.use()
  const ref = useRef(null)
  const size = useComponentSize(ref)

  return (
    <Container height={acceptStorage ? 0 : `${size.height}px`}>
      <Content ref={ref}>
        <Text>This website requires local client storage.</Text>
        <Button onClick={() => acceptStorageState.set(true)}>
          Accept
        </Button>
      </Content>
    </Container>
  )
}

export default StorageWarning

const Container = styled.div`
  background-color: #eb4a65;
  overflow: hidden;
  transition: height 0.2s ease;
  height: ${props => props.height}
`

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;

`

const Text = styled.div`
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`
