import { sampleCollectionReadyToProcess } from '../utils'

function getSampleCollectionsForProcessing () {
  const sampleCollectionsToProcess = []
  if (!this.sampleCollections || !this.fileOverviews) return sampleCollectionsToProcess
  for (let sampleCollectionId in this.sampleCollections) {
    const sampleCollection = this.sampleCollections[sampleCollectionId]
    const { convertOutputFileId, convertProcessedLocally } = sampleCollection
    if (convertProcessedLocally) continue
    const convertOutputFileOverview = this.fileOverviews[convertOutputFileId]
    if (sampleCollectionReadyToProcess(sampleCollection, convertOutputFileOverview)) sampleCollectionsToProcess.push(sampleCollection)
  }
  return sampleCollectionsToProcess
}

export default getSampleCollectionsForProcessing