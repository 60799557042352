
import { isNil } from 'ramda'
import QueryString from 'query-string'

import pages from './pages'

const getPath = ({ type, id }, query) => {
  if (pages[type]) {
    return `${pages[type].getPath(id)}${getQuery(query)}`
  } else {
    return '#'
  }
}

const getQuery = query => {
  if (isNil(query)) {
    return ''
  } else {
    return `?${QueryString.stringify(query)}`
  }
}

export default getPath
