import getNewTransientWorker from './getNewTransientWorker'

const runOnTransientWorker = (task, payload) => {
  return new Promise((resolve, reject) => {
    const worker = getNewTransientWorker()
    if (!worker) {
      self.addEventListener('message', e => {
        console.log('Received message in persistent worker from main thread', e.data)
        if (e.data.reportBackTransientPassThrough) {
          console.log('Resolving transient worker request in persistent worker')
          resolve(e.data.reportBackTransientPassThrough)
        }
      })
      /*
      self.addEventListener('error', e => {
        reject(e.message)
      })
      */
      console.log('Posting request from persistent worker to main thread', { requestTransientPassThrough: [task, payload]})
      self.postMessage({ requestTransientPassThrough: [task, payload]})
    } else {
      worker.onmessage = e => {
        console.log('received message from transient worker, terminating and resolving', e.data)
        worker.terminate()
        resolve(e.data)
      }
      worker.onerror = e => {
        worker.terminate()
        reject(e.message)
      }
      console.log('going to post message to transient worker', [task, payload])
      worker.postMessage([task, payload])
    }
  })
}

export default runOnTransientWorker
