import { useState, useCallback } from 'react'

const useAsyncState = (...args) => {
  const [state, vanillaSetState] = useState(...args)

  const asyncSetState = useCallback(setterFn => {
    return new Promise(resolve => {
      const newSetterFn = currentState => {
        const newState = setterFn(currentState, resolve)
        resolve(newState)
        return newState
      }
      vanillaSetState(newSetterFn)
    })
  }, [vanillaSetState])

  return [state, asyncSetState]
}

export default useAsyncState