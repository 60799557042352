import { assoc, assocPath } from 'ramda'
import { useQuery as _useQuery } from 'react-query'

import fetchJson from 'signal/hooks/fetchJson'
import {
  identityState,
  modeState
} from 'signal/state'

const apolloState = JSON.parse(document.getElementById('apolloState').textContent)
const root = apolloState.dynamicApi

const getMode = () => {
  const identity = identityState.use()
  const mode = modeState.use()

  if (identity && mode && mode !== 'production') {
    return mode
  } else {
    return 'production'
  }
}

const getAuthorization = () => {
  const identity = identityState.use()

  if (identity && identity.type === 'google') {
    return `Google ${identity.token}`
  } else if (identity && identity.type === 'reviewer') {
    return `Reviewer ${identity.token}`
  } else {
    return null
  }
}

const useQuery = (path, _params, _fetchOptions = {}, reactQueryOptions = {}) => {
  const mode = getMode()
  const authorization = getAuthorization()

  const params = assoc('mode', mode, _params)
  const fetchOptions = assocPath(
    ['headers', 'Authorization'], authorization, _fetchOptions
  )

  return _useQuery(
    [path, params],
    async () => fetchJson(
      root, path, params, fetchOptions
    ),
    {
      staleTime: Infinity,
      keepPreviousData: true,
      ...reactQueryOptions
    }
  )
}

export default useQuery
