// Imports
// -----------------------------------------
import 'dexie-observable'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Route } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ErrorBoundary, Browser } from 'signal-components'
import favicon from './images/favicon.png'
import startGoogleAnalytics from 'signal-util/startGoogleAnalytics'
import { persistentWorker } from 'analyse2/worker'
import App from './App'
import './main.css'
import { configState, pipelineApiState } from 'signal/state'
import { acceptStorageState } from 'signal/storage'
import { urlUploadWebSocketState } from 'analyse2/upload-form_stable'

if (!window._babelPolyfill) require('babel-polyfill')

const basenameRegex = /^\/version\/\w+/
const basenameMatch = window.location.pathname.match(basenameRegex)
const basename = basenameMatch ? basenameMatch[0] : '/'

//
//
//

//
//
//

// Put root URLs in store
// -----------------------------------------
const apolloState = JSON.parse(document.getElementById('apolloState').textContent)
urlUploadWebSocketState.set(apolloState.uploadUrl)
configState.set(apolloState)
//
//
//

//
//
//

// Set favicon
// -----------------------------------------
const faviconElement = document.createElement('link')
faviconElement.setAttribute('rel', 'shortcut icon')
faviconElement.setAttribute('type', 'image/x-icon')
faviconElement.setAttribute('href', favicon)
document.head.appendChild(faviconElement)

//
//
//

acceptStorageState.subscribe((acceptStorage, unsubscribe) => {
  if (acceptStorage) {
    unsubscribe()
    startGoogleAnalytics()
  }
})

// Create new db
// -----------------------------------------
if (typeof document !== 'undefined') {

  const apolloState = JSON.parse(document.getElementById('apolloState').textContent)
  const pipelineApi = apolloState.pipelineApi
  const webSocket = apolloState.websocket
  const dynamicApi = apolloState.dynamicApi
  pipelineApiState.set(pipelineApi)
  persistentWorker.postMessage({
    pipelineApi,
    webSocket,
    dynamicApi
  })
}

//
//
//

const queryClient = new QueryClient()

// Create App
const ProvidedClientRoot = () => {
  return (
    <ErrorBoundary>
      <BrowserRouter basename={basename}>
        <QueryParamProvider ReactRouterRoute={Route}>
          <QueryClientProvider client={queryClient}>
              <Browser.Provider>
                <App />
              </Browser.Provider>
          </QueryClientProvider>
        </QueryParamProvider>
      </BrowserRouter>
    </ErrorBoundary>
  )
}

// Mount App to DOM
loadPolyfills().then(() => {
  ReactDOM.render(
    <ProvidedClientRoot />,
    document.getElementById('app')
  )
})

function loadPolyfills () {
  const polyfills = []
  if (!supportsIntersectionObserver()) {
    console.log('Loading intersection observer polyfill')
    polyfills.push(import('intersection-observer'));
  }
  return Promise.all(polyfills)
}

function supportsIntersectionObserver () {
  return (
    'IntersectionObserver' in global &&
    'IntersectionObserverEntry' in global &&
    'intersectionRatio' in IntersectionObserverEntry.prototype
  )
}
