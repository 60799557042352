async function failTaskDueToMissingOutputs (taskId) {
  await this.dbClient.transaction('rw', ['tasks', 'fileOverviews'], async () => {
    const [task, fileOverviews] = await Promise.all([
      this.dbClient.tasks.get(taskId),
      this.dbClient.fileOverviews.where('parentTaskId').equals(taskId).toArray()
    ])
    if (task) {
      const missingFileOverviews = fileOverviews.filter(({ serverId, fetched }) => !serverId && !fetched)
      if (task.complete && task.serverId && missingFileOverviews.length > 0) await this.failTask(taskId)
    }
  })
}

export default failTaskDueToMissingOutputs