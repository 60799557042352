import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'

import { mainHistory as mainHistoryState } from 'signal-state'
import { Tooltip } from 'signal-components'
import sidebarOpenState from './sidebar-open-state'

const SidebarNavbar = props => {
  const history = useHistory()
  const mainHistory = mainHistoryState.use()
  const location = useLocation()

  const [backElement, backRef] = useState(null)
  const [forwardElement, forwardRef] = useState(null)
  const [openInMainElement, openInMainRef] = useState(null)
  const [openMainInSidebarElement, openMainInSidebarRef] = useState(null)
  const [closeSidebarElement, closeSidebarRef] = useState(null)

  return (
    <Container>
      <Tooltip target={backElement} content='Go back' />
      <Button
        ref={backRef}
        active={history.index > 0}
        onClick={() => history.goBack()}
      >
        <I className='material-icons'>arrow_back</I>
      </Button>
      <Tooltip target={forwardElement} content='Go forward' />
      <Button
        ref={forwardRef}
        active={history.index < history.length - 1}
        onClick={() => history.goForward()}
      >
        <I className='material-icons'>arrow_forward</I>
      </Button>
      <Tooltip target={openInMainElement} content='Open in main panel' />
      <Button
        ref={openInMainRef}
        active
        onClick={() => {
          sidebarOpenState.set(false)
          mainHistory.push(location.pathname)
        }}
      >
        <I className='material-icons'>open_in_full</I>
      </Button>
      <Tooltip target={openMainInSidebarElement} content='Open main panel in sidebar' />
      <Button
        ref={openMainInSidebarRef}
        active
        onClick={() => history.push(mainHistory.location.pathname)}
      >
        <I className='material-icons'>input</I>
      </Button>
      <div css='flex-grow: 1'></div>
      <Tooltip target={closeSidebarElement} content='Close sidebar' />
      <Button
        ref={closeSidebarRef}
        active
        onClick={() => sidebarOpenState.set(false)}
      >
        <I className='material-icons'>close</I>
      </Button>
    </Container>
  )
}

export default SidebarNavbar

const Container = styled.div`
  background-color: rgb(229,229,229);
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
`

const Button = styled.div`
  color: ${props => props.active ? 'rgb(50,50,50)' : 'rgb(150,150,150)'};
  cursor: ${props => props.active ? 'pointer' : 'auto'};
  padding: 3px;
`

const I = styled.i`
  font-size: 34px;
`
