function getVariantSetsForSignatureFitProcessing () {
  if (!this.variantSets || !this.fileOverviews) return []
  else return Object.values(this.variantSets).filter(variantSet => {
    if (!variantSet.signatureFitRequests) return false
    return variantSet.signatureFitRequests.find(request => {
      const { processed, summaryFileId } = request
      const summaryFileOverview = this.fileOverviews[summaryFileId]
      if (!summaryFileOverview) return false
      const { fetched } = summaryFileOverview
      return fetched && !processed
    })
  })
}

export default getVariantSetsForSignatureFitProcessing