import { allTrue } from 'signal/utility-functions'
import { fileIsDownloaded, fileExistsOnServer, taskIsComplete } from '../utils'

function getFileIdsToDownload () {
  const fileIdsToDownload = []
  if (!allTrue(this.tasks, this.fileOverviews)) return fileIdsToDownload

  const fileReadyForDownload = (fileOverview, parentTask) => allTrue(
    !fileIsDownloaded(fileOverview),
    taskIsComplete(parentTask),
    fileExistsOnServer(fileOverview)
  )

  for (let fileId in this.fileOverviews) {
    const fileOverview = this.fileOverviews[fileId]
    const { parentTaskId } = fileOverview
    const task = this.tasks[parentTaskId]
    const needed = fileReadyForDownload(fileOverview, task)
    needed && fileIdsToDownload.push(fileId)
  }

  return fileIdsToDownload
}

export default getFileIdsToDownload