import upgradeToFixEmptyTimingRegionFiles from './upgradeToFixEmptyTimingRegionFiles'
import upgradeToFixNonSubMutagenSigs from './upgradeToFixNonSubMutagenSigs'

const dbVersions = [
  {
    version: 9,
    fn: upgradeToFixEmptyTimingRegionFiles
  },
  {
    version: 10,
    fn: upgradeToFixNonSubMutagenSigs
  }
]

export default dbVersions