function getSampleFilename (sampleId) {
  if (!this.samples || !this.sampleCollections) return
  if (!sampleId) return
  const { sampleCollection } = this.samples[sampleId]
  const sampleCollectionObj = this.sampleCollections[sampleCollection]
  if (!sampleCollectionObj) return
  const { inputFilename } = sampleCollectionObj
  return inputFilename
}

export default getSampleFilename