import VState from 'signal/v-state'
import sleep from 'sleep-promise'
import { path, flatten } from 'ramda'
import { allTrue, reduceByKey, isFunction, shallowEqual } from 'signal/utility-functions'
import generateId from 'uuid/v1'
import requestLock from 'analyse2/request-lock'
import { fileTouchTimeout, lockPrefixes } from 'analyse2/config'
import { useState, useRef, useEffect, useCallback } from 'react'
import { useShallowSetState, useMemoisedValue, usePrevious } from 'signal/utility-hooks'

/*
{
          userId: 'overQuotaUser',
          jobId,
          message: {
            status: 'TOO_MANY_SUBMISSIONS',
            seconds: 300,
            maxSubmitted: 5,
            currentSubmitted: 5
          }
        }
*/

async function processTaskStatusUpdate (message, messageTime, { attemptNumber = 1 } = {}) {
  if (attemptNumber > 5) {
    console.error('Failed to update status after 5 attempts', message)
    return
  }
  const timeSinceSubmissionToTakeNotFoundSeriously = 1000 * 60 * 60 * 24
  const taskIsLocal = task => task.local
  const taskIsComplete = task => task.complete
  const taskRequiresStatusUpdate = task => allTrue(!taskIsComplete(task), !taskIsLocal(task))
  const serverId = path(['id'], message)
  const statusCode = path(['status'], message)
  if (!allTrue(this.dbClient, serverId, statusCode)) return

  let needsSleep = false

  await this.dbClient.transaction('rw', ['tasks'], async () => {
    const tasks = await this.dbClient.tasks.where('serverId').equals(serverId).toArray()
    if (tasks.length === 0) {
      needsSleep = true
      return
    }
    if (tasks.length !== 1) {
      console.error(`more than 1 task with serverId ${serverId}`, tasks)
      return
    }
    const task = tasks[0]
    const stillNeedsTouch = !task.touched || messageTime > task.touched
    if (!allTrue(taskRequiresStatusUpdate(task) && stillNeedsTouch)) return

      // Process status
    if (statusCode === 'RUNNING') await this.dbClient.tasks.update(task.id, { touched: messageTime })
    else if (statusCode === 'PENDING') await this.dbClient.tasks.update(task.id, { touched: messageTime })
    else if (statusCode === 'COMPLETE') await this.dbClient.tasks.update(task.id, { complete: true })
    else if (statusCode === 'NOT_FOUND') {
      if (messageTime - task.submitted > timeSinceSubmissionToTakeNotFoundSeriously) {
        await this.dbClient.tasks.update(task.id, { complete: true })
      }
    }
    /*
      Note we are failing the task but not setting output file serverIds to null, so when
      the job is resubmitted, they will still have serverIds which will be overwritten. This shouldn't
      be a problem but keep an eye on it.

      Might need to account for DEPENDENCY_ERROR status. For now it's just ignored, but comes out in the wash
      when the prerequisite task is resubmitted, its output file serverIds changed, and then this job
      gets flagged as failed and resubmits because of the serverIds being out of sync
    */
    else if (statusCode === 'ERROR') await this.dbClient.tasks.where('id').equals(task.id).modify(task => {
      if (!task.errorCount) task.errorCount = 0
      task.errorCount++
      task.timeOfLastError = messageTime
      task.lastError = { cause: 'unknown' }
      task.serverId = null
    })
    else console.error(`Got status ${statusCode} for task ${task.id}`)
  })

  if (needsSleep) {
    await sleep(500)
    await this.processTaskStatusUpdate (message, messageTime, { attemptNumber: attemptNumber + 1 })
  }
}

export default processTaskStatusUpdate