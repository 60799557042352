import { path, flatten } from 'ramda'
import { allTrue, reduceByKey, isFunction, shallowEqual } from 'signal/utility-functions'

function failTasksWithStalePrerequisites (taskIds) {
  /*
    If a job has been submitted to the server, but its prerequisite file serverIds have changed since
    submission (due to the upstream job failing and being resubmitted, for example),
    we no longer care about the output of that job and want to resubmit it with the new prerequisite
    file serverIds. If the job is complete, however, we don't necessarily want to resubmit, because
    the job and its upsteam jobs may have been successful, but the file serverIds changed because time
    has passed and they were reuloaded (we don't want every downstream job of a reuploaded file to be
    resubmitted automatically). We may potentially have an issue where a job comes back as complete
    but with dodgy results due to the upstream job having an issue that doesn't error the dependent job,
    but that would be something to fix on the server. If a job is "complete" we are assuming it and all upstream
    work was done successfully so we needn't worry about resubmitting. In the case that time has passed
    and we have assumed that a job has completed for the sake of trying to see if the files are there anyway,
    then this function wont fail it due to the 'complete' flag, but the missing output files will end up
    failing it anyway.
  */
  if (!this.dbClient) return
  return this.dbClient.transaction('rw', ['fileOverviews', 'tasks'], async () => {
    const tasks = await this.dbClient.tasks.where('id').anyOf(taskIds).toArray()
    const tasksYetToBeFailed = tasks.filter(({ serverId }) => serverId)
    if (tasksYetToBeFailed.length === 0) return
    const tasksWithoutPrerequisiteFileServerIds = tasksYetToBeFailed
      .filter(({ prerequisiteFileServerIds }) => !prerequisiteFileServerIds)
    const tasksWithPrerequisiteFileServerIds = tasksYetToBeFailed
      .filter(({ prerequisiteFileServerIds }) => prerequisiteFileServerIds)
    const taskIdsToFail = tasksWithoutPrerequisiteFileServerIds.map(({ id }) => id)
    if (tasksWithPrerequisiteFileServerIds.length > 0) {
      const prerequisiteFileIds = flatten(tasksWithPrerequisiteFileServerIds.map(task => task.prerequisiteFileIds))
      const fileOverviews = await this.dbClient.fileOverviews.where('id').anyOf(prerequisiteFileIds).toArray()
      const fileOverviewsObj = reduceByKey(fileOverviews)
      const tasksStillToFail = tasksWithPrerequisiteFileServerIds.filter(task => {
        const { id, prerequisiteFileIds, prerequisiteFileServerIds } = task
        const filesNotMatchingLastSubmitted = prerequisiteFileIds.filter(fileId => {
          const fileOverview = fileOverviewsObj[fileId]
          if (!fileOverview) {
            console.error(`No fileOverview found for prerequisite file ${fileId} of task ${id}`)
            return false
          }
          const { serverId } = fileOverview
          if (!serverId) return true
          const lastSubmittedServerId = prerequisiteFileServerIds[fileId]
          if (serverId !== lastSubmittedServerId) return true
        })
        if (filesNotMatchingLastSubmitted.length > 0) return true
      })
      taskIdsToFail.push(...tasksStillToFail.map(({ id }) => id))
    }
    await Promise.all(taskIdsToFail.map(id => this.failTask(id)))
  })
}

export default failTasksWithStalePrerequisites