const badSignatures = [
  "mutagenSignature::12",
  "mutagenSignature::18",
  "mutagenSignature::19",
  "mutagenSignature::10",
  "mutagenSignature::11",
  "mutagenSignature::14",
  "mutagenSignature::15",
  "mutagenSignature::1",
  "mutagenSignature::6",
  "mutagenSignature::13",
  "mutagenSignature::8",
  "mutagenSignature::7",
  "mutagenSignature::2",
  "mutagenSignature::16",
  "mutagenSignature::17",
  "mutagenSignature::4",
  "mutagenSignature::3",
  "mutagenSignature::5",
  "mutagenSignature::9"
]

const upgradeToFixNonSubMutagenSigs = async ({
  selectAll,
  selectById,
  modifyById
}) => {
  const tasks = await selectAll('tasks')
  for (const task of tasks) {
    if (task.type === 'REINTRODUCTION') {
      if (task.params.signatureKeys) {
        const signatureKeys = task.params.signatureKeys.value
        if (signatureKeys) {
          const keysToRemove = signatureKeys.filter(x => badSignatures.includes(x))
          if (keysToRemove.length > 0 && signatureKeys.length - keysToRemove.length > 1) {
            await modifyById('tasks', task.id, task => {
              task.params.signatureKeys.value = task.params.signatureKeys.value.filter(x => !badSignatures.includes(x))
              task.complete = false
              task.serverId = null
              task.submitted = null
              task.errorCount = 0
            })
          }
        }
      }
    }
  }

  const samples = await selectAll('samples')
  for (const sample of samples) {
    if (sample.selectedAnalyses && sample.selectedAnalyses.sigFit) {
      let needsToChange = false
      for (const request of sample.selectedAnalyses.sigFit) {
        if (request.signatureKeys) {
          const keysToRemove = request.signatureKeys.filter(x => badSignatures.includes(x))
          if (keysToRemove.length > 0 && request.signatureKeys.length - keysToRemove.length > 1) {
            needsToChange = true
            break
          }
        }
      }
      if (needsToChange) {
        await modifyById('samples', sample.id, sample => {
          let index = -1
          for (const request of sample.selectedAnalyses.sigFit) {
            index++
            if (request.signatureKeys) {
              const keysToRemove = request.signatureKeys.filter(x => badSignatures.includes(x))
              if (keysToRemove.length > 0 && request.signatureKeys.length - keysToRemove.length > 1) {
                sample.selectedAnalyses.sigFit[index].signatureKeys = request.signatureKeys.filter(x => !badSignatures.includes(x))
              }
            }
          }
        })
      }
    }
  }

  const sampleCollections = await selectAll('sampleCollections')
  for (const sampleCollection of sampleCollections) {
    if (sampleCollection.analyses && sampleCollection.analyses.sigFit) {
      if (sampleCollection.analyses.sigFit.signatureKeys) {
        const { signatureKeys } = sampleCollection.analyses.sigFit
        const keysToRemove = signatureKeys.filter(x => badSignatures.includes(x))
        if (keysToRemove.length > 0 && signatureKeys.length - keysToRemove.length > 1) {
          await modifyById('sampleCollections', sampleCollection.id, sampleCollection => {
            sampleCollection.analyses.sigFit.signatureKeys = sampleCollection.analyses.sigFit.signatureKeys.filter(x => !badSignatures.includes(x))
          })
        }
      }
    }
  }
}

export default upgradeToFixNonSubMutagenSigs