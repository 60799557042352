import { path } from 'ramda'
import findMatchingVariantSet from './findMatchingVariantSet'

const getPrimaryVariantSetId = (sample, project, variantSets) => {
  const { variantsFileId, unmatchedVariantsListFileId, nonPassVariantsListFileId } = sample
  const removeNonPassVariants = path(['globalFilters', 'removeNonPassVariants'], project)
  const removeUnmatchedVariants = path(['globalFilters', 'removeUnmatchedVariants'], project)
  let requiredPrimaryIncludeFileIds = []
  let requiredPrimaryExcludeFileIds = []
  if (variantsFileId && removeNonPassVariants) requiredPrimaryExcludeFileIds.push(nonPassVariantsListFileId)
  if (variantsFileId && removeUnmatchedVariants) requiredPrimaryExcludeFileIds.push(unmatchedVariantsListFileId)
  const primaryVariantSet = findMatchingVariantSet(requiredPrimaryIncludeFileIds, requiredPrimaryExcludeFileIds, variantSets)
  if (!primaryVariantSet) return
  else return primaryVariantSet.id
}

export default getPrimaryVariantSetId