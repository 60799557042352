import { taskRequiresStatusUpdate } from '../utils'

function getTasksRequiringStatusUpdates () {
  const tasksRequiringStatusUpdates = []
  if (!this.tasks || !this.fileOverviews) return tasksRequiringStatusUpdates
  for (let taskId in this.tasks) {
    // We no longer limit number of requests as this caused issues. We should ask for all statuses
    // of jobs that could feasibly have started
    // if (tasksRequiringStatusUpdates.length === maxJobsPerRequest) break
    const task = this.tasks[taskId]
    if (taskRequiresStatusUpdate(task)) {
      const { prerequisiteFileIds } = task
      if (!prerequisiteFileIds) continue
      const prerequisiteTaskIds = prerequisiteFileIds
        .map(fileId => this.fileOverviews[fileId])
        .map(fileOverview => fileOverview && fileOverview.parentTaskId)
      let ready = true
      for (let taskId of prerequisiteTaskIds) {
        if (!taskId) {
          ready = false
          break
        }
        const task = this.tasks[taskId]
        if (!task) {
          ready = false
          break
        }
        const { complete } = task
        if (!complete) {
          ready = false
          break
        }
      }
      if (ready) tasksRequiringStatusUpdates.push(task)
    }
  }
  return tasksRequiringStatusUpdates
}

export default getTasksRequiringStatusUpdates