import { experimentalState } from 'signal/state'

const features = (feature, experimental = experimentalState.get()) => ({
  study6: true,
  study7: experimental,
  study8: experimental,
  analyseCircos: experimental,
  inVitro: true,
  allSignatures: experimental,
  newSignatureFit: true,
  frontEndConvert: experimental
}[feature])

const useFeatures = feature => {
  const experimental = experimentalState.use()
  return features(feature, experimental)
}

export { useFeatures }
export default features