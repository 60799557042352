function getProjectIdsToDelete () {
  const projectIdsToDelete = []
  if (this.projects) {
    for (let projectId in this.projects) {
      const project = this.projects[projectId]
      if (project.hasOwnProperty('delete') && project.delete) projectIdsToDelete.push(projectId)
    }
  }
  return projectIdsToDelete
}

export default getProjectIdsToDelete