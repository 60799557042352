import React, { forwardRef } from 'react'
import { Link as RLink } from 'react-router-dom'
import useWindowSize from '@rooks/use-window-size'
import styled from 'styled-components'
import { usePanel } from 'signal-components/Panel'
import { mainHistory as mainHistoryState } from 'signal-state'
import { useOpenInSidebar } from 'signal/sidebar'

const Link = forwardRef(({ to, children, openInSidebar, hideOpenIn, replace, ...props }, ref) => {
  const { innerWidth } = useWindowSize()
 
  if (innerWidth > 1000) {
    if (openInSidebar) {
      return (
        <Container ref={ref} {...props}>
          <OpenInSidebar to={to}>{children}</OpenInSidebar>
        </Container>
      )
    } else {
      return (
        <Container ref={ref} {...props}>
          <LinkWrapper><RLink to={to} replace={replace}>{children}</RLink></LinkWrapper>
          {!hideOpenIn ? <OpenIn to={to} /> : <></>}
        </Container>
      )
    }
  } else {
    return (
      <Container ref={ref} {...props}>
        <LinkWrapper><RLink to={to} replace={replace}>{children}</RLink></LinkWrapper>
      </Container>
    )
  }
})

export default Link

const OpenIn = props => {
  const panel = usePanel()
  const mainHistory = mainHistoryState.use()
  const openInSidebar = useOpenInSidebar(props.to)

  if (panel === 'main') {
    return (
      <OpenInContainer onClick={openInSidebar}>
        Open in sidebar
      </OpenInContainer>
    )
  } else if (panel === 'sidebar') {
    return (
      <OpenInContainer onClick={() => mainHistory.push(props.to)}>
        Open in main
      </OpenInContainer>
    )
  } else {
    return null
  }
}

const OpenInSidebar = props => {
  const openInSidebar = useOpenInSidebar(props.to)
  return (
    <a
      href={props.to}
      onClick={e => {
        e.preventDefault()
        openInSidebar()
      }}
    >{props.children}</a>
  )
}

const OpenInContainer = styled.span`
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  font-size: 14px;
  cursor: pointer;
  background-color: #f2f6f7;
  color: #04869b;
  font-weight: 200;
  border: 1px solid #04869b;
  padding: 2px;
  border-radius: 3px;
  transition: background-color 0.2s ease, color 0.2s ease;
  white-space: nowrap;
  z-index: 200;

  &:hover {
    background-color: #04869b;
    color: white;
  }
`

const Container = styled.span`
  position: relative;
  display: ${props => props.block ? 'block' : 'inline-block'};

  &:hover {
    & > ${OpenInContainer} {
      display: inherit;
    }
  }
`

const LinkWrapper = styled.span`
  display: block;
`
