import { allTrue } from 'signal/utility-functions'
import taskExistsOnServer from './taskExistsOnServer'
import taskIsComplete from './taskIsComplete'
import taskHasErrored from './taskHasErrored'
import taskIsLocal from './taskIsLocal'

const taskRequiresStatusUpdate = task => allTrue(
  taskExistsOnServer(task),
  !taskIsComplete(task),
  !taskHasErrored(task),
  !taskIsLocal(task)
)

export default taskRequiresStatusUpdate