import React from 'react'
import styled from 'styled-components'

import { ExternalLink } from 'signal/components'

import obiwan from './notfound.mp4'

const NotFound = () => (
  <Container>
    <Text>This isn't the page you're looking for</Text>
    <figure>
      <video
        style={{
          width: '100%',
          borderRadius: '10px',
          boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.7)'
        }}
        autoplay='true'
        muted
        loop
      >
        <source src={obiwan} />
      </video>
      <Credit>
        Credit: <ExternalLink to='http://www.reddit.com/r/Cinemagraphs/comments/9f3x4i/not_the_droids_youre_looking_for/'>u/bryguy54</ExternalLink> and George Lucas
      </Credit>
    </figure>
  </Container>
)

export default NotFound

const Container = styled.div`
  width: 100%;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
`

const Text = styled.h1`
  font-size: 22px;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
`

const Credit = styled.figcaption`
  text-align: right;
  font-size: 12px;
`
