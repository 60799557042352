import React, { forwardRef, useRef, useEffect } from 'react'
import styled from 'styled-components'
import colors from 'signal/colors'

const Text =
  styled(
    forwardRef(

      ({
        onKeyDown,
        onClickOutside,
        onEnter,
        valid,
        className,
        focusedLineColor,
        unfocusedLineColor,
        spellCheck = 'false',
        ...props
      }, _ref) => {
        const privateRef = useRef()
        const ref = _ref || privateRef

        useEffect(() => {
          if (onClickOutside) {
            const handleClick = e => { if (e.target !== ref.current) onClickOutside(e) }
            document.addEventListener('mousedown', handleClick)
            return () => document.removeEventListener('mousedown', handleClick)
          }
        }, [onClickOutside, ref])

        const onKeyDownComposed = e => {
          if (onKeyDown) onKeyDown(e)
          if (onEnter && e.key === 'Enter') onEnter(e)
        }

        return (
          <div className={className}>
            <Input
              type='text'
              ref={ref}
              spellCheck={spellCheck}
              onKeyDown={onKeyDownComposed}
              {...props}
            />
          </div>
        )
      }

    )
  )`${({ valid = true, unfocusedLineColor = 'lightgrey', focusedLineColor = 'darkgrey' }) => `
    transition: color 0.1s;
    position: relative;
    display: inline-flex;
    ${valid ? '' : `color: ${colors.warning};`}
    &:after {
      content: "";
      transition: background-color 0.1s;
      background-color: ${valid ? unfocusedLineColor : colors.warning};
      bottom: 0px;
      right: 0px;
      left: 0px;
      height: 2px;
      transform: translateY(3px);
      position: absolute;
    }
    &:focus-within:after {
      background-color: ${valid ? focusedLineColor : colors.warning} !important;
    }
  `}`

const Input = styled.input`${({ valid = true }) => `
  color: inherit;
  padding: 0px;
  font-family: inherit;
  border: none;
  font-size: 1em;
  background-color: transparent;
  outline: none;
  flex-grow: 1;
`}`

export default Text
