import { makeReadableCsv } from 'analyse2/csv-downloads'
import { allTrue, hidden } from 'signal/utility-functions'

async function getSampleCSVs ({
  sampleId,
  primaryVariantSetId,
  secondaryVariantSetId,
  signatures,
  substitutionSpec,
  secondaryDoubleSubstitutionVariantSetId,
  primaryDoubleSubstitutionVariantSetId,
  doubleSubstitutionSpec,
  primaryIndelVariantSetId,
  secondaryIndelVariantSetId,
  indelSpec
}) {
  // Get data objects
  const dbClient = this.dbClient
  const variantSets = this.get().variantSets
  const samples = this.get().samples
  const fileOverviews = this.get().fileOverviews
  const projects = this.get().projects
  if (!allTrue(dbClient, fileOverviews, samples, variantSets, projects)) return

  // Get sample
  const sample = samples[sampleId]
  if (!sample) { console.warn('Sample does not exist', sampleId); return }

  // Get info from sample
  const sampleFilters = sample.filters || {}
  const sampleAlias = sample.alias

  // Get project
  const projectId = sample.projectId
  const project = projects[projectId]
  if (!project) { console.warn('Project does not exist', projectId); return }

  // Get info from project
  const projectFilters = project.filters || {}
  const kataegisProject = projectFilters.removeKataegisVariants || {}
  const nonPassProject = projectFilters.removeNonPassVariants || {}

  // Process filters
  const kataegisProjectMode = kataegisProject.sampleMode
  const nonPassProjectMode = nonPassProject.sampleMode
  const kataegisActive = kataegisProjectMode === 'ALL' || (kataegisProjectMode === 'CUSTOM' && sampleFilters.removeKataegisVariants)
  const nonPassActive = nonPassProjectMode === 'ALL' || (nonPassProjectMode === 'CUSTOM' && sampleFilters.removeNonPassVariants)

  const filtersText =
    `remove_kataegis_variants: ${kataegisActive ? 'true' : 'false'}\n` +
    `remove_non-PASS_variants: ${nonPassActive ? 'true' : 'false'}\n`

  // Get variant sets
  const secondaryVariantSet = variantSets[secondaryVariantSetId]
  const primaryVariantSet = variantSets[primaryVariantSetId]
  const secondaryDoubleSubstitutionVariantSet = variantSets[secondaryDoubleSubstitutionVariantSetId]
  const secondaryIndelVariantSet = variantSets[secondaryIndelVariantSetId]

  const results = {}

  if (sample.variantsFileId) results['active_filters.txt'] = filtersText

  // Substitution catalog
  // --------------------
  if (secondaryVariantSet && secondaryVariantSet.catalogFileId) {
    const fileObj = await dbClient.files.get(secondaryVariantSet.catalogFileId)
    const data = fileObj && fileObj.data
    const convertedData = makeReadableCsv(data, { type: 'CATALOG', substitutionSpec, alias: sampleAlias })
    results['sbs_catalog.csv'] = convertedData
  }

  // Double substitution catalogs
  // ----------------------------
  if (secondaryDoubleSubstitutionVariantSet && secondaryDoubleSubstitutionVariantSet.catalogFileId) {
    const fileObj = await dbClient.files.get(secondaryDoubleSubstitutionVariantSet.catalogFileId)
    const data = fileObj && fileObj.data
    const convertedData = makeReadableCsv(data, { type: 'DBS_CATALOG', mutationSpec: doubleSubstitutionSpec, alias: sampleAlias })
    results['dbs_catalog.csv'] = convertedData
  }

  // Indel catalogs
  // --------------
  if (indelSpec && secondaryIndelVariantSet && secondaryIndelVariantSet.catalogFileId) {
    const fileObj = await dbClient.files.get(secondaryIndelVariantSet.catalogFileId)
    const data = fileObj && fileObj.data
    const convertedData = makeReadableCsv(data, { type: 'INDEL_CATALOG', mutationSpec: indelSpec, alias: sampleAlias })
    results['indel_catalog.csv'] = convertedData
  }

  // Substitution timing region catalogs
  // -----------------------------------
  if (secondaryVariantSet && secondaryVariantSet.perTimingRegionCatalogs) {
    const { expectedPerTimingRegionCatalogs, perTimingRegionCatalogs } = secondaryVariantSet

    if (expectedPerTimingRegionCatalogs && perTimingRegionCatalogs) {
      const expectedPerTimingRegionCatalogFiles = await Promise.all(expectedPerTimingRegionCatalogs.map(async ({ id }) => {
        const file = await dbClient.files.get(id)
        const data = file && file.data
        return data
      }))
      const perTimingRegionCatalogFiles = await Promise.all(perTimingRegionCatalogs.map(async ({ id }) => {
        const file = await dbClient.files.get(id)
        const data = file && file.data
        return data
      }))

      if (
        expectedPerTimingRegionCatalogFiles.filter(Boolean).length === expectedPerTimingRegionCatalogs.length &&
        perTimingRegionCatalogFiles.filter(Boolean).length === perTimingRegionCatalogs.length
      ) {

        results.expectedCatalogsPerTimingRegion = expectedPerTimingRegionCatalogFiles.reduce((acc, data, index) => {
          const timingRegion = expectedPerTimingRegionCatalogs[index].timingRegion
          acc[`${timingRegion}.csv`] = makeReadableCsv(data, { type: 'CATALOG', substitutionSpec, alias: timingRegion })
          return acc
        }, {})

        results.catalogsPerTimingRegion = perTimingRegionCatalogFiles.reduce((acc, data, index) => {
          const timingRegion = perTimingRegionCatalogs[index].timingRegion
          acc[`${timingRegion}.csv`] = makeReadableCsv(data, { type: 'CATALOG', substitutionSpec, alias: timingRegion })
          return acc
        }, {})
      }
    }
  }

  // Kataegis regions
  // ----------------
  if (primaryVariantSet && primaryVariantSet.kataegisRegionsFileId && sample.variantsFileId) {
    const [{ data: regionsData } = {}, { data: variantsData } = {}] = await Promise.all([
      dbClient.files.get(primaryVariantSet.kataegisRegionsFileId),
      dbClient.files.get(sample.variantsFileId)
    ])
    const convertedData = makeReadableCsv(regionsData, { type: 'KATAEGIS_REGIONS', variantsCsv: variantsData })
    results['kataegis_regions.csv'] = convertedData
  }

  // Kataegis substitution catalog
  // -----------------------------
  if (primaryVariantSet && primaryVariantSet.kataegisCatalogFileId) {
    const fileObj = await dbClient.files.get(primaryVariantSet.kataegisCatalogFileId)
    const data = fileObj && fileObj.data
    const convertedData = makeReadableCsv(data, { type: 'CATALOG', substitutionSpec, alias: `${sampleAlias}_kataegis` })
    results['kataegis_sbs_catalog.csv'] = convertedData
  }

  // Transcription strand bias substitution catalogs
  // ----------------------------------
  if (secondaryVariantSet && secondaryVariantSet.transcribedStrandSubstitutionCatalogFileId && secondaryVariantSet.nonTranscribedStrandSubstitutionCatalogFileId) {
    const [{ data: transCatalog } = {}, { data: nonTransCatalog } = {}] = await Promise.all([
      // TRANS_BIAS_ERROR_NOTE: These results have been swapped to hack the issue with transcribed strand being backwards
      // When it's fixed on the server, revert this change
      dbClient.files.get(secondaryVariantSet.nonTranscribedStrandSubstitutionCatalogFileId),
      dbClient.files.get(secondaryVariantSet.transcribedStrandSubstitutionCatalogFileId)
    ])
    results['sbs_transcription_strand_bias.csv'] = makeReadableCsv(
      [{ data: transCatalog, label: 'Transcribed' }, { data: nonTransCatalog, label: 'Non-transcribed' }],
      { type: 'CATALOG', substitutionSpec }
    )
  }

  // Replication strand bias substitution catalogs
  // --------------------------------
  if (secondaryVariantSet && secondaryVariantSet.leadingStrandSubstitutionCatalogFileId && secondaryVariantSet.laggingStrandSubstitutionCatalogFileId) {
    const [{ data: leadingCatalog } = {}, { data: laggingCatalog } = {}] = await Promise.all([
      dbClient.files.get(secondaryVariantSet.leadingStrandSubstitutionCatalogFileId),
      dbClient.files.get(secondaryVariantSet.laggingStrandSubstitutionCatalogFileId)
    ])
    results['sbs_replication_strand_bias.csv'] = makeReadableCsv(
      [{ data: leadingCatalog, label: 'Leading' }, { data: laggingCatalog, label: 'Lagging' }],
      { type: 'CATALOG', substitutionSpec }
    )
  }

  // SigFit substitution results
  // ---------------------------
  const folderNames = { 'SBS': 0, 'DBS': 0, 'INDEL': 0 }
  await getSigFitResults(folderNames, dbClient, results, sample, secondaryVariantSet, fileOverviews, signatures, substitutionSpec, 'SBS', 'sbs_signatures')

  // SignatureFit substitution results
  // ---------------------------------
  await getSigFitResults(folderNames, dbClient, results, sample, secondaryVariantSet, fileOverviews, signatures, substitutionSpec, 'SBS', 'sbs_signatures', 'newSignatureFit')

  // SignatureFit double substitution results
  // ---------------------------------
  await getSigFitResults(folderNames, dbClient, results, sample, secondaryDoubleSubstitutionVariantSet, fileOverviews, signatures, doubleSubstitutionSpec, 'DBS', 'dbs_signatures', 'newSignatureFit')

  await getSigFitResults(folderNames, dbClient, results, sample, secondaryIndelVariantSet, fileOverviews, signatures, indelSpec, 'INDEL', 'indel_signatures', 'newSignatureFit')

  return results
}

const getSigFitResults = async (folderNames, dbClient, results, sample, variantSet, fileOverviews, signatures, mutationSpec, mutType, key, newSignatureFit) => {

  const sigFitRequests = (variantSet && (newSignatureFit ? variantSet.signatureFitRequests : variantSet.sigFitRequests)) || []

  await Promise.all(sigFitRequests.map(async fit => {

    const folderName = ++folderNames[mutType]

    const {
      id,
      bootstrapsFileId,
      contributionsFileId,
      residualFileId,
      correlationsFileId,
      reconstructionFileId
    } = (() => {
      if (newSignatureFit) {
        const { id, summary: { fileIds, method, allFits, choiceIndex } = {} } = fit
        const ids = method === 'Fit' ? fileIds : method === 'FitMS' ? allFits[choiceIndex].fileIds : {}

        return {
          id,
          bootstrapsFileId: ids.bootstraps,
          contributionsFileId: ids.contributions,
          residualFileId: ids.residualCatalog,
          correlationsFileId: ids.correlations,
          reconstructionFileId: ids.reconstructedCatalog
        }
      } else return fit
    })()

    const selectedAnalyses = sample.selectedAnalyses || {}
    const selectedSigFit = selectedAnalyses[newSignatureFit ? 'signatureFit' : 'sigFit'] || []
    const sigFitRequest = selectedSigFit.find(request => request.id === id)
    if (!sigFitRequest) return
    const { requested, numOfBootstraps, byOrgan, thresholdPValue, thresholdPercentage } = !newSignatureFit ? sigFitRequest : (() => {
      const { requested, params = [] } = sigFitRequest
      const specParams = params.find(x => x.mutationSpecId === { SBS: 7, DBS: 1, INDEL: 10 }[mutType])
      return {
        requested,
        ...specParams || {}
      }
    })()

    const order = [
      { type: 'CONTRIBUTIONS', name: 'bootstraps.csv', id: bootstrapsFileId, options: {} },
      { type: 'CONTRIBUTIONS', name: 'contributions.csv', id: contributionsFileId, options: { singleSolution: true } },
      { type: 'CONTRIBUTIONS', name: 'reference_contributions.csv', id: contributionsFileId, options: { convertToReference: true, singleSolution: true } },
      { type: { SBS: 'CATALOG', DBS: 'DBS_CATALOG', INDEL: 'INDEL_CATALOG' }[mutType], name: 'residual.csv', id: residualFileId, options: { alias: `${sample.alias}_Residual` } },
      { type: 'CORRELATIONS', name: 'correlations.csv', id: correlationsFileId, options: {} },
      { type: { SBS: 'CATALOG', DBS: 'DBS_CATALOG', INDEL: 'INDEL_CATALOG' }[mutType], name: 'reconstruction.csv', id: reconstructionFileId, options: { alias: `${sample.alias}_Reconstruction` } }
    ].filter(x => x.id)

    const fileObjs = await Promise.all(order.map(({ id }) => dbClient.files.get(id)))

    if (fileObjs.filter(({ data } = {}) => data).length !== order.length) return

    if (!results[key]) results[key] = {
      ['README.txt']: 'Signature fitting can be performed multiple times with different parameters. ' +
        'Each directory contains one such solution, numbered according to the order in which ' +
        'the analyses were submitted.\n'
    }
    results[key][folderName] = {}
    order.forEach(({ id, type, name, options = {} }, index) => {
      const { data } = fileObjs[index] || {}
      const fileOverview = fileOverviews[id]
      if (!fileOverview) { console.error(`fileOverview doesn't exist for ${id}`); return }
      const { fetched } = fileOverview
      if (!fetched) return

      const parsedData = makeReadableCsv(data, {
        type,//type === 'CATALOG' ? { SBS: 'CATALOG', DBS: 'DBS_CATALOG', INDEL: 'INDEL_CATALOG' }[mutType] : type,
        signatures,
        substitutionSpec: mutationSpec,
        mutationSpec,
        alias: sample.alias,
        ...options
      })
      if (!parsedData) return
      results[key][folderName][name] = parsedData
    })
    results[key][folderName]['README.txt'] =
      `${new Date(requested).toLocaleString()}\n` +
      `Threshold p-value: ${thresholdPValue}\n` +
      `Threshold percentage: ${thresholdPercentage}\n` +
      '\n' +
      'Note: contributions.csv contains point estimate (median) solution and may contain organ-specific signatures.' +
      ' reference_contributions.csv will convert any organ-specific contributions to the equivalent reference signature contributions.' +
      ' If no organ-specific cancer signatures were used in the fit then these files should look the same.'
  }))
}

export default getSampleCSVs