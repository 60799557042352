function getValidFunctionsForSample (sampleId, referenceBuilds) {
  if (!this.samples || !this.samples[sampleId]) return
  const { referenceBuildId, variantsFileId } = this.samples[sampleId]
  if (!referenceBuilds) return []
  if (!referenceBuilds.find) return []
  const referenceBuild = referenceBuilds.find(build => build.id === referenceBuildId)
  if (!referenceBuild) {
    console.warn(`Reference build ${referenceBuildId} could not be found`)
    return []
  }
  const validFunctions = ['downloadPlots', 'sigFit', 'similarities']
  if (variantsFileId) {
    validFunctions.push('kataegis')
    const { categoriseOutputFiles } = referenceBuild
    if (categoriseOutputFiles.includes('transcribedStrandVariants')) validFunctions.push('transcriptionStrandBias')
    if (categoriseOutputFiles.includes('leadingReplicationStrandVariants')) validFunctions.push('replicationStrandBias')
    if (categoriseOutputFiles.includes('replicationTimingRegionVariantsFiles')) validFunctions.push('timingRegion')
  }
  return validFunctions
}

export default getValidFunctionsForSample