import React from 'react'
import HistoryItem from './HistoryItem'
import { reverse, splitWhen } from 'ramda'
import { searchHistoryState } from 'signal/state'
import colors from 'signal/colors'
import getPath from 'signal/routes/getPath'

const HistorySection = ({ go }) => {
  const searchHistory = searchHistoryState.use()
  const todayDate = new Date()
  const [older, today] = splitWhen(x => {
    return new Date(x.date).setHours(0,0,0,0) === todayDate.setHours(0,0,0,0)
  }, searchHistory)
  const split = Boolean(older.length > 0 && today.length > 0)
  return (
    <div css='margin-top: 40px; font-size: 0.8em;'>
      <div css='font-size: 0.8em; margin-bottom: 15px; color: grey;'>Search history (<span onClick={() => searchHistoryState.reset()} css={`text-decoration: underline; &:hover {color: ${colors.primary}} transition: background-color 0.2s; cursor: pointer;`}>clear</span>)</div>
      {!split && renderHistory(searchHistory, go)}
      {split && (<>
        <div css='font-size: 0.7em; font-style: italic; color: grey; margin: 20px 0px 10px;'>Today</div>
        {renderHistory(today, go)}
        <div css='font-size: 0.7em; font-style: italic; color: grey; margin: 20px 0px 10px;'>Older</div>
        {renderHistory(older, go)}
      </>)}
    </div>
  )
}

const renderHistory = (searchHistory, go) => (
  reverse(searchHistory).map(({ id, type, name }) => {
    const url = getPath({ id, type })
    const onClick = () => {
      go(url)
      searchHistoryState.remove({ id, type })
      searchHistoryState.add({ name, id, type })
    }
    const onRemove = e => {
      e.stopPropagation()
      searchHistoryState.remove({ id, type })
    }
    return <HistoryItem key={`${type}_${id}`} go={onClick} remove={onRemove} id={id} type={type} name={name} />
  })
)

export default HistorySection