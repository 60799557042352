import React, { forwardRef, useMemo } from 'react'
import { Portal } from 'signal/utility-components'
import { useHovering } from 'signal/utility-hooks'
import styled, { keyframes } from 'styled-components'
import { useRect_v2 as useRect } from 'signal-hooks'

// If vertical: will occupy space above or below target, otherwise occupy space left or right of the target
// If forceAbove: will avoid space below the target (regardless of vertical)

const Tooltip = forwardRef(({ noHover, ...props }, ref) => {
  if (noHover) return <TooltipNoHover ref={ref} {...props} />
  else return <TooltipHover ref={ref} {...props} />
})

const TooltipHover = forwardRef(({ target, ...props }, ref) => {
  const isOpen = useHovering(target)
  if (!isOpen) return null
  else return <TooltipNoHover target={target} ref={ref} {...props} />
})

const TooltipNoHover = forwardRef(({
  content,
  target,
  unstyled = false,
  forceAbove = false,
  vertical = false,
  ...props
}, ref) => {
  const isOpen = true
  const boundingRect = useRect(target)
  const positionalStyle = useMemo(() => {
    if (isOpen && target) {
      return getPositionalStyle({ boundingRect, vertical, forceAbove })
    }
  }, [isOpen, boundingRect, vertical, forceAbove])
  if (!isOpen) return null
  else return (
    <Portal>
      <TooltipContainer unstyled={unstyled} ref={ref} style={positionalStyle} {...props}>
        {content}
      </TooltipContainer>
    </Portal>
  )
})

const TooltipContainer = styled.div`
  position: fixed;
  z-index: 10000000000000;
  max-width: 270px;
  ${p => p.unstyled ? '' : `
    border-radius: 5px;
    line-height: 22px;
    font-size: 16px;
    padding: 8px;
    background-color: rgba(50, 50, 50, 0.9);
    color: white;
  `}
  animation: ${keyframes`
    0% { opacity: 0 }
    20% { opacity: 0 }
    100% { opacity: 1 }
  `} 0.3s;
`

function getPositionalStyle ({ boundingRect, vertical = false, forceAbove = false }) {
  const spacing = 5
  const { top, right, bottom, left } = boundingRect
  const { innerWidth, innerHeight } = window
  const spaceOnLeft = left
  const spaceOnRight = innerWidth - right
  const spaceOnTop = top
  const spaceOnBottom = innerHeight - bottom

  if (vertical) {
    const horizontalStyle = spaceOnRight >= spaceOnLeft ? { left: `${left}px` } : { right: `${innerWidth - right}px` }
    const verticalStyle = forceAbove || (spaceOnTop > spaceOnBottom) ? { bottom: `${innerHeight - top + spacing}px` } : { top: `${bottom + spacing}px` }
    return { ...horizontalStyle, ...verticalStyle }
  } else {
    const horizontalStyle = spaceOnRight >= spaceOnLeft ? { left: `${right + spacing}px` } : { right: `${innerWidth - left + spacing}px` }
    const verticalStyle = forceAbove || (spaceOnTop > spaceOnBottom) ? { bottom: `${innerHeight - bottom}px` } : { top: `${top}px` }
    return { ...horizontalStyle, ...verticalStyle }
  }
}

export default Tooltip