import VState from 'signal/v-state'
import * as methods from './dataStateMethods'
import * as objectMethods from './dataObjectMethods'

/*
GET SAMPLE CSVs NEEDS FIXING SO IT DOESNT RELY ON THIS HAVING DBCLIENT
Move it over to data-state object
*/

function DataObject (attributes) {
  Object.assign(this, attributes)
}
Object.assign(DataObject.prototype, objectMethods)

const initialData = {
  fileOverviews: undefined,
  tasks: undefined,
  variantSets: undefined,
  sampleCollections: undefined,
  projects: undefined,
  samples: undefined,
  sharedState: undefined
}

const createDataState = dbClientState => {
  const dataState = new VState(new DataObject(initialData))
  Object.assign(dataState, methods)

  dbClientState.subscribe((dbClient, unsubscribe) => {
    if (dbClient) {
      unsubscribe()
      dataState.dbClient = dbClient
      let buffer = []
      dbClient.on('changes', (changes, partial) => {
        for (let change of changes) initialData.hasOwnProperty(change.table) && buffer.push(change)
        if (!partial) {
          buffer.length > 0 && dataState.set(previousData => {
            const newData = {}
            for (let { table, type, key, obj } of buffer) {
              if (table === 'sharedState') {
                if (type === 3) delete newData[table]
                else newData[table] = { ...obj }
              } else {
                if (!newData.hasOwnProperty(table)) newData[table] = { ...previousData[table] }
                if (type === 3 && newData[table].hasOwnProperty(key)) delete newData[table][key]
                else if (type === 2 && newData[table].hasOwnProperty(key)) newData[table][key] = { ...obj }
                else if (type === 1 && !newData[table].hasOwnProperty(key)) newData[table][key] = { ...obj }
              }
            }
            return new DataObject({ ...previousData, ...newData })
          })
          buffer = []
        }
      })

      loadInitialData(dbClient).then(initialData => dataState.set(initialData))
    }
  })

  return dataState
}

const loadInitialData = dbClient => (
  dbClient.transaction(
    'r',
    Object.keys(initialData),
    async () => {
      const newData = new DataObject({})
      for (let table in initialData) {
        if (table === 'sharedState') {
          const object = await dbClient[table].get(1)
          newData[table] = { ...object }
        } else {
          const array = await dbClient[table].toArray()
          const collection = {}
          for (let item of array) collection[item.id] = { ...item }
          newData[table] = collection
        }
      }
      return newData
    }
  )
)

export default createDataState