import QueryString from 'query-string'

const fetchJson = async (root, path, params, options) => {
  const start = new Date()
  const response = await fetch(
    `${root}${path}?${QueryString.stringify(params, { skipNull: true, arrayFormat: 'index' })}`,
    options
  )
  if (!response.ok) {
    const err = new Error('Network response was not ok')
    log(path, params, start, false, err)
    throw err
  } else {
    const json = await response.json()
    log(path, params, start, true, json)
    return json
  }
}

export default fetchJson

const log = (path, params, start, ok, output) => {
  const end = new Date()
  console.groupCollapsed(
    `%cQuery %c${path} %c${ok ? 'COMPLETE' : 'ERROR'} %c(in ${end - start}ms)`,
    'color: purple;',
    'color: auto;',
    `color: ${ok ? 'green' : 'red'};`,
    'color: grey;'
  )
  console.log('Params:', params)
  console.log('Output:', output)
  console.groupEnd()
}
