import React, { useState } from 'react'

import Context from './Context'

const Provider = props => {
  const [mutationType, _setMutationType] = useState(1)
  const setMutationType = nextMutationType => {
    if (mutationType !== nextMutationType) {
      _setMutationType(nextMutationType)
    }
  }
  return (
    <Context.Provider value={[mutationType, setMutationType]}>
      {props.children}
    </Context.Provider>
  )
}

export default Provider
