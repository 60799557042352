function getTasksForProject (projectId) {
  if (!this.tasks) return
  const tasks = Object.values(this.tasks)
  const tasksForProject = []
  for (let task of tasks) {
    if (task.projectId === projectId) tasksForProject.push(task)
  }
  return tasksForProject
}

export default getTasksForProject