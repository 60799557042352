import { sort } from 'ramda'

function getSampleCollectionsForProject (projectId, orderByName) {
  if (!this.sampleCollections) return
  const sampleCollections = Object.values(this.sampleCollections)
  const sampleCollectionsForProject = []
  for (let sampleCollection of sampleCollections) {
    if (sampleCollection.projectId === projectId) sampleCollectionsForProject.push(sampleCollection)
  }
  if (orderByName) return sort((a, b) => {
    const aAttr = a.inputFilename ? 'inputFilename' : 'url'
    const bAttr = b.inputFilename ? 'inputFilename' : 'url'
    return a[aAttr].localeCompare(b[bAttr])
  }, sampleCollectionsForProject)
  else return sampleCollectionsForProject
}

export default getSampleCollectionsForProject