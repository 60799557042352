import { useRef, useState, useEffect } from 'react'
import progressState from 'analyse2/progressState'

const useProgress = (id, { smoothing = 0.01 } = {}) => {
  const progressFormState = progressState.use()
  const [renderedProgress, setRenderedProgress] = useState(0)

  const globalState = id && progressFormState[id]

  const animationFrameRef = useRef(null)
  useEffect(() => {
    const latestProgress = globalState ? globalState.progress : 0.1
    if (!animationFrameRef.current && renderedProgress < latestProgress) {
      animationFrameRef.current = requestAnimationFrame(() => {
        animationFrameRef.current = null
        setRenderedProgress(renderedProgress => {
          if (renderedProgress === latestProgress) return renderedProgress
          else {
            const theoreticalRenderedProgress = renderedProgress + (smoothing || 1)
            const finalRenderedProgress = theoreticalRenderedProgress > latestProgress ? latestProgress : theoreticalRenderedProgress
            return finalRenderedProgress
          }
        })
      })
    }
  }, [(globalState && globalState.progress), smoothing, renderedProgress])

  return renderedProgress
}

export default useProgress