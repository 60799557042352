import { createPortal } from 'react-dom'

/*

  Wrap your element(s) in <Portal /> and they will be rendered within the given target.
  If no target is provided, elements will be rendered to the end of body (or not at all if not in browser).

*/

const Portal = ({ target = typeof window !== 'undefined' ? window.document.body : null, children }) => {
  if (!target) return null
  return createPortal(children, target)
}

export default Portal