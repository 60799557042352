import { useState, useMemo, useRef } from 'react'
import simpleSelector from './simple-selector'
import strictEqual from './strict-equal'
import useLayoutEffectNoWarning from './use-layout-effect-no-warning'
import useUpdatingRef from './use-updating-ref'

const usePrivateState = (privateState, selector = simpleSelector, equalityFn = strictEqual, { throttle, log } = {}) => {
  const value = privateState.get()
  const [, forceUpdate] = useState({})
  const selectedValue = useMemo(() => selector(value), [value, selector])

  const renderedSelectorRef = useUpdatingRef(selector)
  const renderedEqualityFnRef = useUpdatingRef(equalityFn)
  const renderedValueRef = useUpdatingRef(value)
  const renderedSelectedValueRef = useUpdatingRef(selectedValue)
  const renderedThrottleRef = useUpdatingRef(throttle)
  const renderedLogRef = useUpdatingRef(log)

  const pendingUpdate = useRef(null)

  useLayoutEffectNoWarning(() => {
    let unmounted = false
    const unsubscribe = privateState.subscribe(newValue => {
      if (unmounted || pendingUpdate.current) return
      const renderedValue = renderedValueRef.current
      const renderedSelector = renderedSelectorRef.current
      const renderedEqualityFn = renderedEqualityFnRef.current
      const renderedSelectedValue = renderedSelectedValueRef.current
      const renderedThrottle = renderedThrottleRef.current
      const renderedLog = renderedLogRef.current
      const valueChanged = newValue !== renderedValue
      if (valueChanged) {
        const newSelectedValue = renderedSelector(newValue)
        const selectedValueChanged = !renderedEqualityFn(newSelectedValue, renderedSelectedValue)
        if (selectedValueChanged) {
          if (renderedThrottle) {
            if (renderedLog) console.log('scheduling update in', renderedThrottle)
            pendingUpdate.current = setTimeout(() => {
              if (!unmounted) {
                if (renderedLog) console.log('updating by schedule')
                pendingUpdate.current = null
                forceUpdate({})
              }
            }, renderedThrottle)
          } else {
            if (renderedLog) console.log('updating')
            forceUpdate({})
          }
        }
      }
    })
    return () => unmounted = true && unsubscribe()
  }, [])

  return selectedValue
}

export default usePrivateState