function updateProjectFilter (projectId, filterType, obj) {
  if (!this.dbClient) return
  return this.dbClient.transaction('rw', ['projects'], async () => {
    const project = await this.dbClient.projects.get(projectId)
    if (!project) {
      console.error(`project ${projectId} not found`)
      return
    }
    const filters = project.filters || {}
    const filter = filters[filterType] || {}
    const newFilter = { ...filter, ...obj }
    const newFilters = { ...filters, [filterType]: newFilter }
    await this.dbClient.projects.update(projectId, { filters: newFilters })
  })
}

export default updateProjectFilter