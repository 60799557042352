import requestLock from 'analyse2/request-lock'
import { lockPrefixes } from 'analyse2/config'

function getLockForFileDownload (fileId) {
  if (!this.dbClient) return []
  return this.dbClient.transaction('rw', ['locks', 'fileOverviews'], async () => {
    const hasLock = await requestLock(
      `${lockPrefixes.fileDownload}${fileId}`,
      async dbClient => {
        const fileOverview = await dbClient.fileOverviews.get(fileId)
        if (!fileOverview) return false
        const { fetched } = fileOverview
        return !fetched
      },
      ['fileOverviews']
    )
    return hasLock
  })
}

export default getLockForFileDownload