import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useElement } from 'signal/utility-hooks'
import colors from 'signal/colors'

const PreviewOverlay = ({ name, onClickOutside, onClickGo, onClickSidebar, ...props }) => {
  const [goButton, setGoButton] = useElement()
  const [sidebarButton, setSidebarButton] = useElement()
  useEffect(() => {
    if (onClickOutside) {
      const onClick = e => {
        if (goButton.contains(e.target) || sidebarButton.contains(e.target)) return
        else onClickOutside(e)
      }
      window.addEventListener('click', onClick, true)
      return () => window.removeEventListener('click', onClick, true)
    }
  }, [onClickOutside, goButton, sidebarButton])
  return (
    <Container>
      <div css='display: flex; flex-direction: column'>
        <div css={`color: ${colors.primary}; font-weight: bold; margin: 10px; align-self: center; font-size: 1.6em;`}>{name}</div>
        <Button ref={setGoButton} onClick={onClickGo}>Go to page&nbsp;&nbsp;↵</Button>
        <Button hollow ref={setSidebarButton} onClick={onClickSidebar}>Open in sidebar&nbsp;&nbsp;<i css='font-size: 1.2em;' className='material-icons'>input</i></Button>
      </div>
    </Container>
  )
}

const Button = styled.button`
  border-radius: 0px;
  border: 3px solid ${colors.primary};
  background-color: ${p => p.hollow ? 'transparent' : colors.primary};
  color: ${p => p.hollow ? colors.primary : 'white'};
  font-size: ${p => p.hollow ? '1em' : '1.2em'};
  padding: 10px 25px;
  font-weight: bold;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  opacity: 0.9;
  &:hover {
    opacity: 1;
  }
  cursor: pointer;
  margin: 10px 10px 0px;
`

const Container = styled.div`
  z-index: 10000000000000000000;
  overflow: hidden;
  position: absolute;
  top: 0px;
  bottom: 0px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  right: 0px;
  left: 0px;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.95);
`

export default PreviewOverlay