import { path } from 'ramda'
import findMatchingVariantSet from './findMatchingVariantSet'

const getSecondaryVariantSetId = (sample, project, variantSets, primaryVariantSet) => {
  const { variantsFileId } = sample
  const removeKataegisVariants = path(['globalFilters', 'removeKataegisVariants'], project)
  const downsampleFactor = path(['globalFilters', 'downsample'], project)
  const kataegisSitesFileId = path(['kataegisSitesFileId'], primaryVariantSet)
  const downsampleIncludeFileId = path(['downsampleIncludeFileIds', downsampleFactor], primaryVariantSet)
  let requiredSecondaryIncludeFileIds = [...(primaryVariantSet.includeFileIds || [])]
  let requiredSecondaryExcludeFileIds = [...(primaryVariantSet.excludeFileIds || [])]
  if (variantsFileId && downsampleFactor) requiredSecondaryIncludeFileIds.push(downsampleIncludeFileId)
  if (variantsFileId && removeKataegisVariants) requiredSecondaryExcludeFileIds.push(kataegisSitesFileId)
  const secondaryVariantSet = findMatchingVariantSet(requiredSecondaryIncludeFileIds, requiredSecondaryExcludeFileIds, variantSets)
  if (!secondaryVariantSet) return
  else return secondaryVariantSet.id
}

export default getSecondaryVariantSetId