function getSampleCollectionsWithFailedTask () {
  const failedSampleCollectionsByFailedTask = []
  if (!this.sampleCollections || !this.tasks) return failedSampleCollectionsByFailedTask
  for (let sampleCollectionId in this.sampleCollections) {
    const sampleCollection = this.sampleCollections[sampleCollectionId]
    const { convertTaskId, convertProcessedLocally } = sampleCollection
    if (convertProcessedLocally) continue
    const task = this.tasks[convertTaskId]
    const { serverId } = task
    !serverId && failedSampleCollectionsByFailedTask.push(sampleCollection)
  }
  return failedSampleCollectionsByFailedTask
}

export default getSampleCollectionsWithFailedTask