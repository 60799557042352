function toggleSampleFilter (sampleId, filterType) {
  if (!this.dbClient) return
  return this.dbClient.transaction('rw', ['samples'], async () => {
    const sample = await this.dbClient.samples.get(sampleId)
    if (!sample) {
      console.error(`sample ${sampleId} not found`)
      return
    }
    const filters = sample.filters || {}
    filters[filterType] = !filters[filterType]
    await this.dbClient.samples.update(sampleId, { filters })
  })
}

export default toggleSampleFilter