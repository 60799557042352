import loadable from '@loadable/component'
import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'
import Loading from 'signal-components/Loading'
import { useFeatures } from 'signal/features'

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
  height: 500px;
`

const opt = {
  fallback: (
    <LoadingWrapper>
      <Loading />
    </LoadingWrapper>
  )
}

const Study1 = loadable(() => import('./components/Study1'), opt)
const Study2 = loadable(() => import('./components/Study2'), opt)
const Study3 = loadable(() => import('./components/Study3'), opt)
const Study4 = loadable(() => import('./components/Study4'), opt)
const Study5 = loadable(() => import('./components/Study5'), opt)
const Study6 = loadable(() => import('./components/Study6'), opt)
const Study7 = loadable(() => import('./components/Study7'), opt)

const Study = () => {
  const match = useRouteMatch()
  const study7 = useFeatures('study7')
  if (match.params.id === '1') return <Study1 />
  else if (match.params.id === '2') return <Study2 />
  else if (match.params.id === '3') return <Study3 />
  else if (match.params.id === '4') return <Study4 />
  else if (match.params.id === '5') return <Study5 />
  else if (match.params.id === '6') return <Study6 />
  else if (match.params.id === '7') {
    if (study7) return <Study7 />
    else return 'Page under construction'
  }
}

export default Study
