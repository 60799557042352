async function getFiles (fileIds) {
  const data = this.get()
  const files = fileIds.reduce((acc, id) => (acc[id] = { id, available: false, data: null }, acc), {})
  const fileOverviews = data.fileOverviews
  const promises = {}
  if (fileOverviews) {
    for (let fileId of fileIds) {
      const { fetched } = fileOverviews[fileId] || {}
      files[fileId].available = !!fetched
      if (fetched) {
        promises[fileId] = this.dbClient.files.get(fileId)
      }
    }
  }
  for (let fileId in promises) {
    const file = promises[fileId]
    const thing = await file
    if (file) files[fileId].data = await thing.data
  }
  return files
}

export default getFiles