async function addProject ({
  id,
  alias,
  globalFilters = { removeUnmatchedVariants: true },
  created = Date.now(),
  hidden: projectHidden = false
}) {
  if (!this.dbClient) return
  await this.dbClient.projects.put({
    id,
    alias,
    globalFilters,
    created,
    hidden: projectHidden
  })
}

export default addProject