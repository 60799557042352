async function patchDb ({ fn, transaction, data }) {
  function selectAll (table) {
    if (transaction) return transaction[table].toArray()
    else return data[table]
  }
  function selectById (table, id) {
    if (transaction) return transaction[table].where('id').equals(id)
    else return data[table].find(x => x.id === id)
  }
  function modifyById (table, id, fn) {
    if (transaction) return transaction[table].where('id').equals(id).modify(fn)
    else fn(data[table].find(x => x.id === id))
  }
  await fn({
    selectAll,
    selectById,
    modifyById
  })
}

export default patchDb