import { map, values } from 'ramda'
import React, { useEffect } from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'

import { ErrorBoundary, Footer, NotFound } from 'signal-components'
import { useViewport } from 'signal-components/Viewport'

import pages from './pages'

const Page = props => {
  const viewportElement = useViewport()
  useEffect(() => {
    if (viewportElement) viewportElement.scrollTop = 0
  }, [viewportElement])

  return (
    <ErrorBoundary>
      {props.children}
    </ErrorBoundary>
  )
}

const pathsWithoutFooter = ['/analyse2', '/explore/study/1/network']

const Routes = ({ displayFooter = false }) => {
  const location = useLocation()
  return (
    <Switch>
      {map(({path, exact, component: Component }) => (
        <Route
          key={path}
          path={path}
          exact={exact}
        >
          <Page>
            <Component />
            {displayFooter && !pathsWithoutFooter.includes(location.pathname) && <Footer />}
          </Page>
        </Route>
      ), values(pages))}
      <NotFound />
    </Switch>
  )
}

export default Routes
