async function setSampleAlias (sampleId, alias) {
  if (!this.dbClient) return false
  return this.dbClient.transaction('rw', ['samples'], async () => {
    const samples = await this.dbClient.samples.toArray()
    const sample = samples.find(({ id }) => id === sampleId)
    if (!sample) return false
    const conflictingSample = samples.find(
      otherSample =>
        otherSample.projectId === sample.projectId &&
        otherSample.alias === alias &&
        otherSample.id !== sample.id
    )
    if (conflictingSample) return false
    else {
      await this.dbClient.samples.update(sampleId, { alias })
      return true
    }
  })
}

export default setSampleAlias