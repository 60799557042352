import {
  assocPath,
  find,
  isNil,
  map,
  path,
  pipe,
  values
} from 'ramda'
import React, { useEffect, useRef, useState } from 'react'
import useWindowSize from '@rooks/use-window-size'
import styled from 'styled-components'
import valuesAnchor from './valuesAnchor'
import Tooltip from 'signal-components/Tooltip'
import { Portal } from 'signal/utility-components'
import {
  useRect
} from 'signal-hooks'

import state from './state'

const setDropdowns = state.set.bind(state)

const Values = props => {
  const element = valuesAnchor.use()
  const dropdowns = state.use()
  const [activeDropdown, setActiveDropdown] = useState(null)
  useEffect(() => {
    setActiveDropdown(pipe(
      values,
      find(dropdown => dropdown.active)
    )(dropdowns))
  }, [dropdowns])

  const ref = useRef()
  const valuesRef = useRef()

  const valuesHeight = valuesRef.current ? valuesRef.current.clientHeight : 0
  const fieldRect = useRect(path(['ref'], activeDropdown))

  const { innerWidth, innerHeight } = useWindowSize()

  const position = calculatePosition(fieldRect, valuesHeight, innerHeight)

  const mobile = innerWidth < 700

  const onClickOutside = e => {
    if (activeDropdown) {
      const r = ref.current.getBoundingClientRect()
      if (
        e.pageX < r.top ||
        e.pageX > r.bottom ||
        e.pageY < r.left ||
        e.pageY > r.right
      ) {
        setDropdowns(
          current => assocPath([activeDropdown.id, 'active'], false, current)
        )
      }
    }
  }

  useEffect(() => {
    window.addEventListener('click', onClickOutside)
    return () => window.removeEventListener('click', onClickOutside)
  }, [ref, activeDropdown])

  if (activeDropdown) {
    return (
      <Portal target={element}>
        <Container
          ref={ref}
          mobile={mobile}
          {...position}
        >
          <div ref={valuesRef}>
            <ValuesChild
              dropdown={activeDropdown}
              mobile={mobile}
              closeDropdown={() => setDropdowns(
                current => assocPath([activeDropdown.id, 'active'], false, current)
              )}
              setDropdownValue={v => setDropdowns(
                current => assocPath([activeDropdown.id, 'value'], v, current)
              )}
            />
          </div>
        </Container>
        <Overlay show={mobile} />
      </Portal>
    )
  } else {
    return null
  }
}

export default Values

const calculatePosition = (fieldRect, valuesHeight, windowHeight) => {
  if (
    valuesHeight > windowHeight - fieldRect.bottom &&
    fieldRect.bottom > windowHeight * (2 / 3)
  ) {
    return {
      bottom: windowHeight - fieldRect.top,
      left: fieldRect.left,
      width: fieldRect.width,
      height: valuesHeight > fieldRect.top ? `${fieldRect.top}px` : 'auto'
    }
  } else {
    return {
      top: fieldRect.bottom,
      left: fieldRect.left,
      width: fieldRect.width,
      height: valuesHeight > windowHeight - fieldRect.bottom
        ? `${windowHeight - fieldRect.bottom}px` : 'auto'
    }
  }
}

const ValuesChild = props => {
  if (props.dropdown.loading) {
    return <Loading>Loading...</Loading>
  } else if (props.dropdown.error) {
    return <Error>Error</Error>
  } else {
    return map(value => (
      <Value
        key={value.id}
        mobile={props.mobile}
        value={value}
        onClose={() => props.closeDropdown()}
        setValue={v => props.setDropdownValue(v)}
      />
    ), props.dropdown.values)
  }
}

const Value = props => {
  const [element, setElement] = useState(null)

  return (
    <ValueWrapper
      ref={setElement}
      mobile={props.mobile}
      onClick={() => {
        props.setValue(props.value.id)
        props.onClose()
      }}
    >
      {props.value.label}
      {props.value.hoverText && <Tooltip content={props.value.hoverText} target={element} />}
    </ValueWrapper>
  )
}

const Container = styled.div`
  position: fixed;
  top: ${props => {
    if (props.mobile) {
      return '50%'
    } else if (!isNil(props.top)) {
      return `${props.top - 1}px`
    } else {
      return 'auto'
    }
  }};
  bottom: ${props => props.bottom ? `${props.bottom}px` : 'auto'};
  left: ${props => props.mobile ? '50%' : `${props.left}px`};
  width: ${props => props.mobile ? '50%' : `${props.width}px`};
  height: ${props => props.height};
  overflow: auto;
  transform: ${props => props.mobile ? 'translate(-50%, -50%)' : 'none'};
  z-index: 20000000000000000;
  border: 1px solid #ccc;
`

const ValueWrapper = styled.div`
  background-color: white;
  color: grey;
  transition: background-color 0.1s ease;
  cursor: pointer;
  padding: ${props => props.mobile ? '16px 20px' : '8px 10px'};
  font-size: ${props => props.mobile ? '110%' : '100%'};

  &:hover {
    background-color: #f2f9fc;
  }
`

const Loading = styled.div`
  background-color: white;
  font-size: ${props => props.mobile ? '110%' : '100%'};
`

const Error = styled.div`
  background-color: white;
  font-size: ${props => props.mobile ? '110%' : '100%'};
`

const Overlay = styled.div`
  display: ${props => props.show ? 'block' : 'none'};
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: grey;
  opacity: 0.5;
  z-index: 1999;
`
