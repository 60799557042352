import { sort } from 'ramda'
import { getSortFn } from '../utils'

function getSortedProjects () {
  if (!this.projects) return
  const unsortedProjects = Object.values(this.projects)
  // const unsortedProjectsNotDeleted = unsortedProjects.filter(project => !(project.hasOwnProperty('delete') && project.delete))
  const sortFn = getSortFn('created', 'desc')
  const sortedProjects = sort(sortFn, unsortedProjects)
  return sortedProjects
}

export default getSortedProjects