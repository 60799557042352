function getCompletedTaskIdsWithMissingOutputs () {
  if (!this.tasks || !this.fileOverviews) return []
  const taskIdsWithMissingOutputs = []
  for (let fileId in this.fileOverviews) {
    const { serverId, fetched, parentTaskId } = this.fileOverviews[fileId]
    if (taskIdsWithMissingOutputs.includes(parentTaskId)) continue
    const task = this.tasks[parentTaskId]
    if (!task) console.error(`No parent task found for file ${fileId}`)
    else {
      const { complete } = task
      if (complete && !fetched && !serverId) {
        console.log('failing task ' + parentTaskId + ' due to complete, !fetched and !serverId')
        taskIdsWithMissingOutputs.push(parentTaskId)
      }
    }
  }
  return taskIdsWithMissingOutputs
}

export default getCompletedTaskIdsWithMissingOutputs