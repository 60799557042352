function getFiltersForProject (projectId) {
  if (!this.projects) return
  const project = this.projects[projectId]
  if (!project) {
    console.error('project', projectId, 'does not exist')
    return
  }
  return project.filters || {}
}

export default getFiltersForProject