import { uniq } from 'ramda'
import { browserSessionId } from 'analyse2/worker/session-management'
import { dbClientState } from 'analyse2/worker/db-client'

const requestLock = (key, checkIfLockStillNeeded, additionalTables = [], otherProperties = {}) => {
  const tables = uniq(['locks', ...additionalTables])
  const dbClient = dbClientState.get()
  if (!dbClient) return false
  return dbClient.transaction(
    'rw',
    tables,
    async () => {
      const currentLock = await dbClient.locks.get(key)
      if (currentLock) return false
      else {
        const lockStillNeeded = checkIfLockStillNeeded ? await checkIfLockStillNeeded(dbClient) : true
        if (!lockStillNeeded) return false
        else {
          await dbClient.locks.put({ key, browserSessionId, ...otherProperties })
          return true
        }
      }
    }
  )
}

export default requestLock
