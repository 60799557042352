function getProjectProgress (projectId) {
  if (!this.projects || !this.fileOverviews || !this.tasks) return

  const unfetchedFiles = []

  for (let fileOverviewId in this.fileOverviews) {
    const fileOverview = this.fileOverviews[fileOverviewId]
    if (fileOverview.projectId === projectId) {
      if (!fileOverview.fetched) unfetchedFiles.push(fileOverviewId)
    }
  }

  const unfinishedTasks = []

  for (let taskId in this.tasks) {
    const task = this.tasks[taskId]
    if (task.projectId === projectId) {
      if (!task.complete) unfinishedTasks.push(taskId)
    }
  }
  /*
  if (unfetchedFiles.length > 0) console.log('unfetchedFiles', unfetchedFiles)
  if (unfinishedTasks.length > 0) console.log('unfinishedTasks', unfinishedTasks)
  */
  return unfetchedFiles.length + unfinishedTasks.length
}

export default getProjectProgress