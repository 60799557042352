import React from 'react'

import Context from './Context'

const Provider = props => (
  <Context.Provider value={props.panel}>
    {props.children}
  </Context.Provider>
)

export default Provider
