import {
  assoc,
  assocPath,
  dissoc,
  path
} from 'ramda'
import {
  useEffect,
  useState
} from 'react'
import uuid from 'uuid/v4'

import state from './state'

const setDropdowns = state.set.bind(state)

const useDropdownValues = ({
  ref, value, values, onChange, loading, error
}) => {
  const [id] = useState(uuid())

  const dropdowns = state.use()

  useEffect(() => {
    setDropdowns(current => assoc(
      id, { id, active: false, ref, value, values, loading, error }, current
    ))
    return () => setDropdowns(current => dissoc(id, current))
  }, [ref])

  const dropdown = dropdowns[id]

  useEffect(() => {
    if (dropdown && dropdown.value !== value) {
      setDropdowns(current => assocPath([id, 'value'], value, current))
    }
  }, [value])

  useEffect(() => {
    if (dropdown && dropdown.value !== value) {
      onChange(path(['value'], dropdown))
    }
  }, [path(['value'], dropdown)])

  useEffect(() => {
    if (dropdown && dropdown.values !== values) {
      setDropdowns(current => assocPath([id, 'values'], values, current))
    }
  }, [values])

  useEffect(() => {
    if (dropdown && dropdown.loading !== loading) {
      setDropdowns(current => assocPath([id, 'loading'], loading, current))
    }
  }, [loading])

  useEffect(() => {
    if (dropdown && dropdown.error !== error) {
      setDropdowns(current => assocPath([id, 'error'], error, current))
    }
  }, [error])

  if (dropdown) {
    return [
      dropdown.active,
      () => setDropdowns(current => assocPath([id, 'active'], true, current)),
      () => setDropdowns(current => assocPath([id, 'active'], false, current))
    ]
  } else {
    return [
      false,
      () => {},
      () => {}
    ]
  }
}

export default useDropdownValues
