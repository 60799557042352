import { useState } from 'react'
import useLayoutEffectNoWarning from './use-layout-effect-no-warning'

const useHovering = (element, { advanced } = {}) => {
  const [hovering, setHovering] = useState(false)

  useLayoutEffectNoWarning(() => {
    if (element) {
      const onMouseMove = () => setHovering(true)
      const onMouseLeave = () => setHovering(false)
      element.addEventListener('mousemove', onMouseMove)
      element.addEventListener('mouseleave', onMouseLeave)
      return () => {
        element.removeEventListener('mousemove', onMouseMove)
        element.removeEventListener('mouseleave', onMouseLeave)
        setHovering(false)
      }
    }
  }, [element, setHovering])

  return advanced ? [hovering, { setHovering }] : hovering
}

export default useHovering