import React, { createContext, useContext, useEffect, useState } from 'react'
import Bowser from 'bowser'

const Context = createContext()

const useBrowser = () => useContext(Context)

const Provider = props => {
  const [browser, setBrowser] = useState(null)

  useEffect(() => {
    setBrowser(Bowser.getParser(window.navigator.userAgent))
  }, [window.navigator.userAgent])

  return <Context.Provider value={browser} {...props} />
}

export default {
  Context,
  useBrowser,
  Provider
}
