const upgradeToFixEmptyTimingRegionFiles = async ({
  selectAll,
  selectById,
  modifyById
}) => {
  const samples = await selectAll('samples')
  for (let sample of samples) {
    if (sample.replicationTimingRegionVariantsLists && sample.replicationTimingRegionVariantsLists.length === 0) {
      await modifyById('samples', sample.id, sample => {
        delete sample.replicationTimingRegionVariantsLists
      })
    }
    if (sample.doubleSubstitutionReplicationTimingRegionVariantsLists && sample.doubleSubstitutionReplicationTimingRegionVariantsLists.length === 0) {
      await modifyById('samples', sample.id, sample => {
        delete sample.doubleSubstitutionReplicationTimingRegionVariantsLists
      })
    }
  }
  const tasks = await selectAll('tasks')
  for (let task of tasks) {
    if (task.outputs) {
      const replFilesIndex = task.outputs.findIndex(output => output.key === 'replicationTimingRegionVariantsFiles' && output.array.length === 0)
      if (replFilesIndex > -1) {
        await modifyById('tasks', task.id, task => {
          task.outputs = [...task.outputs.slice(0, replFilesIndex), ...task.outputs.slice(replFilesIndex + 1)]
          task.complete = false
          task.serverId = null
          task.submitted = null
          task.errorCount = 0
        })
      }
    }
  }
}

export default upgradeToFixEmptyTimingRegionFiles