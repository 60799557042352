import styled, { css, keyframes } from 'styled-components'

/*
  <Loading
    thickness='8px'
    width='80px'
    height='80px'
    duration='1s'
    color={primary}
    double={false}
  />
*/

const colors = {
  primary: '#04869b'
}
const animation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`
const fadeInAnimation = keyframes`
  0% { opacity: 0 }
  100% { opacity: 1 }
`
const Loading = styled.div`
  border: ${p => p.thickness || '8px'} solid ${p => p.backgroundColor || '#f3f3f3'};
  border-top: ${p => p.thickness || '8px'} solid ${p => p.color || colors.primary};
  ${p => p.double ? `border-bottom: ${p.thickness || '8px'} solid ${p.color || colors.primary};` : ''}
  border-radius: 50%;
  width: ${p => p.width || p.height || '80px'};
  height: ${p => p.height || p.width || '80px'};
  animation: ${animation} ${p => p.duration || '1s'} linear infinite${p => p.fadeIn && css`, ${fadeInAnimation} 0.5s linear`};
  ${p => p.addedStyle}
`

export default Loading
