import VState from 'signal/v-state'

const state = new VState(window.localStorage.getItem('wasSignedInWithGoogle') === 'true')
state.subscribe(wasSignedInWithGoogle => {
  if (wasSignedInWithGoogle === true) {
    window.localStorage.setItem('wasSignedInWithGoogle', 'true')
  } else {
    window.localStorage.removeItem('wasSignedInWithGoogle')
  }
})

export default state
