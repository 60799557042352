function getFileIdToServerId () {
  if (!this.fileOverviews) return
  const fileIdToServerId = {}
  for (let fileId in this.fileOverviews) {
    const { serverId } = this.fileOverviews[fileId]
    fileIdToServerId[fileId] = serverId
  }
  return fileIdToServerId
}

export default getFileIdToServerId