function getVariantSetsForProject (projectId) {
  if (!this.variantSets) return
  const variantSets = Object.values(this.variantSets)
  const variantSetsForProject = []
  for (let variantSet of variantSets) {
    if (variantSet.projectId === projectId) variantSetsForProject.push(variantSet)
  }
  return variantSetsForProject
}

export default getVariantSetsForProject