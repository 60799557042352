import chooseColorById from './choose-color-by-id'
import chooseKellyColor from './choose-kelly-color'

const colors = {
  // From variables.css
  primary: '#04869b',
  secondary: '#eb4a65',
  tertiary: '#edf7f4',
  background: '#ECEFF1',
  cancerPrimary: '#7FAF89',
  cancerSecondary: '#F0F8F1',
  mutagenPrimary: '#AC91AF',
  mutagenSecondary: '#F9F3FA',
  genePrimary: '#DEAB75',
  geneSecondary: '#FFF0DF',
  substitutionPrimary: '#EF476F',
  substitutionSecondary: '#FCDDE4',
  doubleSubstitutionPrimary: '#E17B41',
  doubleSubstitutionSecondary: '#fce6d9',
  indelPrimary: '#118AB2',
  indelSecondary: '#D3E9F1',
  rearrangementPrimary: '#FFD166',
  rearrangementSecondary: '#FFF6E3',
  inactivePrimary: '#b5b5b5',
  inactiveSecondary: '#e5e5e5',
  success: '#5cb85c',

  // From analyse2
  primaryHover: '#05798c',
  fadedPrimary: '#f0f4f5',
  warning: '#eb4a65',
  fadedWarning: '#f2d5da',
  greyHoverFill: 'rgba(0,0,0,0.06)',
  greyEdge: 'rgb(229, 229, 229)',
  bolderBackground: '#f4f4f4',
  backgroundEdge: '#d8d8d8',
  darkerGrey: '#606060',
  inProgress: '#deab75',
  inProgressHover: '#d1a06b',
  mutagenHover: '#9f81a3',
  getGrey: (rgb = 150, a = 1) => `rgba(${rgb}, ${rgb}, ${rgb}, ${a})`
}
colors.filterFlag = colors.inProgress

export { chooseColorById }
export { chooseKellyColor }
export default colors