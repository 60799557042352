import {
  last
} from 'ramda'
import {
  useEffect,
  useState
} from 'react'

const useRect = element => {

  const [rect, setRect] = useState(element
    ? element.getBoundingClientRect()
    : {
      bottom: 0,
      height: 0,
      left: 0,
      right: 0,
      top: 0,
      width: 0
    }
  )

  const _set = () => {
    if (element) {
      setRect(element.getBoundingClientRect())
    }
  }

  const set = _set.bind(this)

  useEffect(() => {
    set()
    window.addEventListener('resize', set)
    window.addEventListener('scroll', set)
    const ancestors = getAncestors(element)
    ancestors.forEach(elem => {
      elem.addEventListener('scroll', set)
    })
    const mutationObserver = listenToMutations(set)
    return () => {
      window.removeEventListener('resize', set)
      window.removeEventListener('scroll', set)
      ancestors.forEach(elem => {
        elem.removeEventListener('scroll', set)
      })
      if (mutationObserver) {
        mutationObserver.disconnect()
      }
    }
  }, [element])

  return rect
}

export default useRect

const listenToMutations = callback => {
  if (typeof MutationObserver !== 'undefined') {
    const mutationObserver = new MutationObserver(callback)
    const config = { attributes: true, childList: true, subtree: true }
    mutationObserver.observe(document, config)
    return mutationObserver
  }
}

const getAncestors = element => {
  if (element) {
    return addAncestors([element])
  } else {
    return []
  }
}

const addAncestors = elems => {
  const parentNode = last(elems).parentNode
  if (parentNode) {
    return addAncestors([...elems, parentNode])
  } else {
    return elems
  }
}
