import VState from 'signal/v-state'

import master from './master'
import wasSignedInWithGoogleState from './wasSignedInWithGoogle'

const state = new VState()

const subscribeFn = o => {
  console.log('IDENTITY', o)
  if (o && o.type === 'reviewer') {
    window.localStorage.setItem('reviewerToken', o.token)
    wasSignedInWithGoogleState.set()
  } else if (o && o.type === 'google') {
    window.localStorage.removeItem('reviewerToken')
    wasSignedInWithGoogleState.set(true)
  } else {
    window.localStorage.removeItem('reviewerToken')
    wasSignedInWithGoogleState.set()
  }
}

const wasSignedInWithGoogle = wasSignedInWithGoogleState.get()
const reviewerToken = window.localStorage.getItem('reviewerToken')

if (wasSignedInWithGoogle) {
  window.google.accounts.id.initialize({
    client_id: master.googleClientId,
    callback: async response => {
      const res = await window.fetch(
        `${master.dynamicApi}/login`,
        {
          method: 'POST',
          headers: {
            Authorization: `Google ${response.credential}`
          }
        }
      )
      if (!res.ok) {
        console.error('Error authenticating identity.')
        state.set()
      }

      const identity = await res.json()
      state.set({
        type: 'google',
        token: response.credential,
        identity
      })
      state.subscribe(subscribeFn)
    },
    auto_select: true
  })
  window.google.accounts.id.prompt()
} else if (reviewerToken) {
  console.log('Verifying reviewer token...')
  window.fetch(
    `${master.dynamicApi}/verifyPrereleaseToken?token=${reviewerToken}`,
    {
      method: 'POST'
    }
  )
    .then(res => res.json())
    .then(({ verified }) => {
      if (verified) {
        state.set({
          type: 'reviewer',
          token: reviewerToken
        })
        console.log('Reviewer token verified.')
      } else {
        state.set(null)
        console.log('Reviewer token failed verification and was deleted from storage.')
      }

      state.subscribe(subscribeFn)
    })
} else {
  state.subscribe(subscribeFn)
}

export default state
