import VState from 'signal/v-state'

const state = new VState(window.localStorage.getItem('mode') || 'prerelease')
state.subscribe(mode => {
  if (mode) {
    window.localStorage.setItem('mode', mode)
  } else {
    window.localStorage.removeItem('mode')
  }
})

export default state
