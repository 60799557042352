function countFiltersForSample (sampleId) {
  if (!this.projects || !this.samples) return
  const sample = this.samples[sampleId]
  if (!sample) return
  const project = this.projects[sample.projectId]
  if (!project) return

  const filters = project.filters || {}
  const sampleFilters = sample.filters || {}
  let count = 0

  for (let key in filters) {
    const { sampleMode } = filters[key]
    if (sampleMode === 'ALL') count++
    else if (sampleMode === 'CUSTOM' && sampleFilters[key]) count++
  }

  return count
}

export default countFiltersForSample