function getSampleProgress (sampleId) {
  if (!this.projects || !this.fileOverviews || !this.tasks) return
  let unfetchedFilesCount = 0
  let unfinishedTasksCount = 0
  for (let fileOverviewId in this.fileOverviews) {
    const fileOverview = this.fileOverviews[fileOverviewId]
    if (fileOverview.sampleId === sampleId) {
      if (!fileOverview.fetched) unfetchedFilesCount++
      const parentTaskId = fileOverview.parentTaskId
      if (parentTaskId) {
        const task = this.tasks[parentTaskId]
        if (!task.complete) unfinishedTasksCount++
      }
    }
  }
  return unfetchedFilesCount + unfinishedTasksCount
}

export default getSampleProgress