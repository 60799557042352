import { allTrue } from 'signal/utility-functions'
import { fileTouchTimeout } from 'analyse2/config'
import { taskRequiresSubmission } from '../utils'

function getTaskIdsReadyForSubmission () {
  if (!this.tasks || !this.fileOverviews) return [[], {}, null]
  const taskExistsOnServer = task => task.serverId
  const taskIsComplete = task => task.complete
  const taskHasErrored = task => task.error
  const taskExistenceOnServerHasTimedOut = task => {
    const taskExistsOnServerTimeout = 1000 * 60 * 10
    const { touched } = task
    if (!touched) return true
    else if (Date.now() - touched > taskExistsOnServerTimeout) return true
    else return false
  }
  const prerequisiteFileIsPendingOnServer = parentTask => allTrue(
    !taskIsComplete(parentTask),
    !taskHasErrored(parentTask),
    taskExistsOnServer(parentTask),
    !taskExistenceOnServerHasTimedOut(parentTask)
  )
  const fileExistsOnServer = fileOverview => fileOverview.serverId
  const prerequisiteFileExistsOnServer = fileOverview => allTrue(
    fileExistsOnServer(fileOverview),
    !fileExistenceOnServerHasTimedOut(fileOverview)
  )
  const fileExistenceOnServerHasTimedOut = fileOverview => {
    const { touched } = fileOverview
    if (!touched) return true
    else if (Date.now() - touched > fileTouchTimeout) return true
    else return false
  }

  const tasksArray = Object.values(this.tasks)
  const requireSubmissionChecks = tasksArray.map(taskRequiresSubmission)
  const unsubmittedTasks = requireSubmissionChecks.filter(required => required[0]).map(x => x[2])
  const timeToWaits = requireSubmissionChecks.map(required => required[1]).filter(Boolean)
  const timeToWait = timeToWaits.length > 0 ? Math.min(...timeToWaits) : null

  if (unsubmittedTasks.length === 0) return [[], {}, timeToWait]



  const taskIds = []
  const taskIdToDependencies = {}

  for (let task of unsubmittedTasks) {
    const { id: taskId, prerequisiteFileIds, type } = task
    if (type === 'CONVERT_VARIANTS' || type === 'CONVERT_CATALOG') continue

    const prerequisiteTaskIds = []
    let goodToGo = true

    // Loop through prerequisite files to see if they exist on server or are pending on server
    for (let fileId of prerequisiteFileIds) {
      if (!this.fileOverviews[fileId]) {
        console.warn(`Prerequisite file ${fileId} of task ${taskId} does not exist`)
        goodToGo = false
        break
      }
      const { parentTaskId: parentTaskId, serverId } = this.fileOverviews[fileId]
      const fileOverview = this.fileOverviews[fileId]
      const parentTask = this.tasks[parentTaskId]

      // Dependencies are worked out based on the status of prerequisite tasks
      if (prerequisiteFileIsPendingOnServer(parentTask)) {
        if (!serverId) {
          console.error(`No serverId for fileId despite parent task pending`)
          goodToGo = false
        }
        if (!prerequisiteTaskIds.includes(parentTaskId)) prerequisiteTaskIds.push(parentTaskId)
      } else if (prerequisiteFileExistsOnServer(fileOverview)) {}

      // If any prerequisite files don't need requirements, we can't submit this task
      else goodToGo = false

      if (!goodToGo) break
    }

    if (goodToGo) {
      taskIds.push(taskId)
      // Object mapping a task ID to its dependencies
      taskIdToDependencies[taskId] = prerequisiteTaskIds
    }
  }
  return [taskIds, taskIdToDependencies, timeToWait]
}

export default getTaskIdsReadyForSubmission