import loadable from '@loadable/component'
import React from 'react'
import { Redirect, useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'
import Loading from 'signal-components/Loading'
import { useFeatures } from 'signal/features'
import Study from './components/Study'

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
  height: 500px;
`

const opt = {
  fallback: (
    <LoadingWrapper>
      <Loading />
    </LoadingWrapper>
  )
}

const Analyse2 = loadable(() => import('analyse2/Analyse2'), opt)
const CancerSample = loadable(() => import('./components/CancerSample'), opt)
const CancerSignature = loadable(
  () => import('./components/CancerSignature'), opt)
const Home = loadable(() => import('./components/Home'), opt)
const Explore = loadable(() => import('./components/Explore'), opt)
const Experiment = loadable(() => import('./components/Experiment'), opt)
const ExperimentalSample = loadable(() => import ('./components/ExperimentalSample'), opt)
const Faq = loadable(() => import('./components/Faq'), opt)
const FaqQuestion = loadable(() => import('./components/FaqQuestion'), opt)
const Gene = loadable(() => import('./components/Gene'), opt)
const GeneKnockoutSample = loadable(
  () => import('./components/GeneKnockoutSample'), opt
)
const GeneKnockoutSignature = loadable(
  () => import('./components/GeneKnockoutSignature'), opt
)
const Login = loadable(() => import('./components/Login'), opt)
const Mutagen = loadable(() => import('./components/Mutagen'), opt)
const MutagenGroup = loadable(() => import('./components/MutagenGroup'), opt)
const MutagenSample = loadable(() => import('./components/MutagenSample'), opt)
const MutagenSignature = loadable(
  () => import('./components/MutagenSignature'), opt)
const ReferenceCancerSignature = loadable(
  () => import('./components/ReferenceCancerSignature'), opt)
const ReferenceCancerSignatureInTissueType = loadable(
  () => import('./components/ReferenceCancerSignatureInTissueType'), opt)
const TissueType = loadable(
  () => import('./components/TissueType'), opt
)
const TermsAndConditions = loadable(
  () => import('./components/TermsAndConditions'), opt
)

const pages = {
  analyse: {
    path: '/analyse',
    exact: true,
    component: () => <Redirect to='/analyse2' />,
    getPath: () => '/analyse'
  },
  analyse2: {
    path: '/analyse2',
    exact: true,
    component: Analyse2,
    getPath: () => '/analyse2'
  },
  cancer: {
    path: '/explore/cancer',
    exact: false,
    component: Explore,//Cancer,
    getPath: () => '/explore/cancer'
  },
  cancerSample: {
    path: '/explore/cancerSample/:id',
    component: () => <CancerSample />,
    getPath: id => `/explore/cancerSample/${id}`
  },
  cancerSignature: {
    path: '/explore/cancerSignature/:id',
    component: CancerSignature,
    getPath: id => `/explore/cancerSignature/${id}`
  },
  compound: {
    path: '/explore/compound/:id',
    component: () => <Mutagen type='compound' />,
    getPath: id => `/explore/compound/${id}`
  },
  experiment: {
    path: '/explore/experiment/:id',
    component: Experiment,
    getPath: id => `/explore/experiment/${id}`
  },
  explore: {
    path: '/explore',
    exact: true,
    component: () => <Redirect to='/explore/cancer' />,//Explore,
    getPath: () => '/explore'
  },
  faq: {
    path: '/faq',
    exact: true,
    component: Faq,
    getPath: () => `/faq`
  },
  faqQuestion: {
    path: '/faq/:slug',
    component: FaqQuestion,
    getPath: question => `/faq/${question}`
  },
  gene: {
    path: '/explore/gene/:id',
    component: Gene,
    getPath: id => `/explore/gene/${id}`
  },
  allSignatures: {
    path: '/explore/signatures',
    component: Explore,
    getPath: () => '/explore/signatures'
  },
  experimental: {
    path: '/explore/experimental',
    component: Explore,
    getPath: () => '/explore/experimental'
  },
  experimentalSample: {
    path: '/explore/experimentalSample/:id',
    component: ExperimentalSample,
    getPath: id => `/explore/experimentalSample/${id}`
  },
  genes: {
    path: '/explore/genes',
    component: () => {
      const inVitro = useFeatures('inVitro')
      if (inVitro) return <Redirect to='/explore/experimental?experimentType=gene' />
      else return <Explore />
    },
    getPath: () => '/explore/genes'
  },
  geneKnockoutSample: {
    path: '/explore/geneKnockoutSample/:id',
    component: GeneKnockoutSample,
    getPath: id => `/explore/geneKnockoutSample/${id}`
  },
  geneKnockoutSignature: {
    path: '/explore/geneKnockoutSignature/:id',
    component: GeneKnockoutSignature,
    getPath: id => `/explore/geneKnockoutSignature/${id}`
  },
  home: {
    path: '/',
    exact: true,
    component: Home,
    getPath: () => '/'
  },
  login: {
    path: '/login',
    exact: true,
    component: Login,
    getPath: () => '/login'
  },
  mutagenGroup: {
    path: '/explore/mutagenGroup/:id',
    component: () => {
      const groupToTag = {
        1: 26,
        2: 32,
        3: 33,
        4: 34,
        5: 35,
        6: 36,
        7: 37,
        8: 38,
        9: null,
        10: 27,
        11: 28,
        12: 29,
        13: 30,
        14: 31
      }
      const match = useRouteMatch()
      const inVitro = useFeatures('inVitro')
      if (inVitro) return <Redirect to={`/explore/experimental/mutagens?tag=${groupToTag[match.params.id]}`} />
      return <MutagenGroup />
    },
    getPath: id => `/explore/mutagenGroup/${id}`
  },
  mutagenSample: {
    path: '/explore/mutagenSample/:id',
    component: MutagenSample,
    getPath: id => `/explore/mutagenSample/${id}`
  },
  mutagenSignature: {
    path: '/explore/mutagenSignature/:id',
    component: () => {
      const mutagenSignatureToExperiment = {
        47: 29,
        71: 88,
        72: 74
      }
      const match = useRouteMatch()
      const inVitro = useFeatures('inVitro')
      if (inVitro) return <Redirect to={`/explore/experiment/${mutagenSignatureToExperiment[match.params.id]}?mutationType=1`} />
      return <MutagenSignature />
    },
    getPath: id => `/explore/mutagenSignature/${id}`
  },
  mutagens: {
    path: '/explore/mutagens',
    component: () => {
      const inVitro = useFeatures('inVitro')
      if (inVitro) return <Redirect to='/explore/experimental?experimentType=mutagenesis' />
      else return <Explore />
    },
    getPath: () => '/explore/mutagens'
  },
  radiation: {
    path: '/explore/radiation/:id',
    component: () => <Mutagen type='radiation' />,
    getPath: id => `/explore/radiation/${id}`
  },
  referenceCancerSignature: {
    path: '/explore/referenceCancerSignature/:id',
    component: ReferenceCancerSignature,
    getPath: id => `/explore/referenceCancerSignature/${id}`
  },
  referenceCancerSignatureInTissueType: {
    path: '/explore/referenceCancerSignatureInTissueType/:signatureId/:tissueTypeId',
    component: ReferenceCancerSignatureInTissueType
  },
  solvent: {
    path: '/explore/solvent/:id',
    component: () => <Mutagen type='solvent' />,
    getPath: id => `/explore/solvent/${id}`
  },
  study: {
    path: '/explore/study/:id',
    component: Study,
    getPath: id => `/explore/study/${id}`
  },
  studyTissueType: {
    path: '/explore/studyTissueType/:id',
    component: () => {
      const match = useRouteMatch()
      const [study, tissueType] = match.params.id.split('-')
      return <Redirect to={`/explore/tissueType/${tissueType}?study=${study}`} />
    },
    getPath: id => `/explore/studyTissueType/${id}`
  },
  tissueType: {
    path: '/explore/tissueType/:id',
    component: TissueType,
    getPath: id => `/explore/tissueType/${id}`
  },
  termsAndConditions: {
    path: '/termsAndConditions',
    component: TermsAndConditions,
    getPath: () => '/termsAndConditions'
  }
}

export default pages
