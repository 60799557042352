import { sidebarHistory as sidebarHistoryState } from 'signal-state'
import sidebarOpenState from './sidebar-open-state'

const useOpenInSidebar = link => {
  const sidebarHistory = sidebarHistoryState.use()
  return linkOverride => {
    const newLink = typeof linkOverride == 'string' || linkOverride instanceof String ? linkOverride : link
    if (newLink) {
      sidebarHistory.push(newLink)
      sidebarOpenState.set(true)
    }
  }
}

export default useOpenInSidebar