import React from 'react'
import PrivateState from './private-state'
import { hidden, usePrivateState, isFunction, shallowEqual, strictEqual, simpleSelector } from './utils'
import { path as rPath, assocPath as rAssocPath } from 'ramda'

/*
  This is the public API for v-state. It passes off much of the logic to an instance of the private v-state class.
  This allows the public API for v-state to be designed somewhat independently of the slightly more complicated
  stateful logic, such that we can choose what elements of the state we wish to expose, or add secondary
  features (eg. dispatch/reducer) not core to the stateful logic.
*/

class VState {
  constructor (initialValue, reducer = undefined) {
    hidden(this).privateState = new PrivateState(initialValue)
    hidden(this).reducer = reducer
  }
  canRedo () {
    const { readOnly, privateState } = hidden(this)
    if (readOnly) return false
    return privateState.canRedo()
  }
  canUndo () {
    const { readOnly, privateState } = hidden(this)
    if (readOnly) return false
    return privateState.canUndo()
  }
  dispatch (...action) {
    const { readOnly, privateState, reducer } = hidden(this)
    if (readOnly) throw new Error('Cannot set value on a read-only state.')
    if (!reducer) throw new Error('Cannot call dispatch on state without a reducer')
    const currentValue = privateState.get()
    const newValue = reducer(currentValue, ...action)
    privateState.set(newValue)
  }
  get () {
    const { privateState } = hidden(this)
    return privateState.get()
  }
  derive (selector = simpleSelector, equalityFn = strictEqual) {
    const newPublicInterface = new this.constructor()
    const newPrivateState = hidden(newPublicInterface).privateState
    hidden(newPublicInterface).readOnly = true
    hidden(this).privateState.subscribe((value, unsubscribe) => {
      if (!newPublicInterface) unsubscribe()
      // Should probably return function after unsubscribing
      // Unless it wont ever go out of scope because it's used in this function
      const currentSelectedValue = newPrivateState.get()
      const selectedValue = selector(value)
      if (!equalityFn(selectedValue, currentSelectedValue)) newPrivateState.set(selectedValue)
    })
    return newPublicInterface
  }
  increment (amount = 1) {
    const { readOnly, privateState } = hidden(this)
    if (readOnly) throw new Error('Cannot set value on a read-only state.')
    privateState.set(value => value + amount)
  }
  inject (selector, Component, equalityFn = strictEqual) {
    const { privateState } = hidden(this)
    return props => {
      const selected = usePrivateState(privateState, isFunction(selector) ? selector : undefined, equalityFn)
      const newProps = isFunction(selector) ? selected : { [selector]: selected }
      const combinedProps = { ...props, ...newProps }
      return <Component {...combinedProps} />
    }
  }
  set readTransform (fn) { // NOT YET TESTED
    hidden(this).privateState.readTransform = fn
  }
  set writeTransform (fn) { // NOT YET TESTED
    hidden(this).privateState.writeTransform = fn
  }
  redo () {
    const { readOnly, privateState } = hidden(this)
    if (readOnly) throw new Error('Cannot set value on a read-only state.')
    return privateState.redo()
  }
  get reducer () {
    return hidden(this).reducer
  }
  set reducer (reducer) { // NOT YET TESTED
    hidden(this).reducer = reducer
  }
  reset () {
    const { readOnly, privateState } = hidden(this)
    if (readOnly) throw new Error('Cannot set value on a read-only state.')
    privateState.set(privateState.getInitialValue())
  }
  set (value) {
    const { readOnly, privateState } = hidden(this)
    if (readOnly) throw new Error('Cannot set value on a read-only state.')
    privateState.set(value)
  }
  subscribe (fn, id = undefined) {
    const { privateState } = hidden(this)
    return privateState.subscribe(fn, id)
  }
  toggle () {
    const { readOnly, privateState } = hidden(this)
    if (readOnly) throw new Error('Cannot set value on a read-only state.')
    privateState.set(currentValue => !currentValue)
  }
  undo () {
    const { readOnly, privateState } = hidden(this)
    if (readOnly) throw new Error('Cannot set value on a read-only state.')
    return privateState.undo()
  }
  unsubscribe (...ids) {
    const { privateState } = hidden(this)
    privateState.addToUnsubscribeRequests(...ids)
  }
  use (selector = simpleSelector, equalityFn = strictEqual, options = {}) {
    const { privateState } = hidden(this)
    return usePrivateState(privateState, selector, equalityFn, options)
  }
  static join (...vStates) {
    const subscribes = vStates.map(vState => fn => vState.subscribe(fn))
    const newPublicInterface = new this()
    const newPrivateState = hidden(newPublicInterface).privateState
    hidden(newPublicInterface).readOnly = true
    subscribes.forEach(subscribe => {
      subscribe(() => {
        const currentValues = newPrivateState.get()
        const latestValues = vStates.map(vState => vState.get())
        if (!shallowEqual(currentValues, latestValues)) newPrivateState.set(latestValues)
      })
    })
    return newPublicInterface
  }
}

VState.objectReducer = function (state, pathAndValue) {
  const path = pathAndValue.slice(0, pathAndValue.length - 1)
  const _value = pathAndValue[pathAndValue.length - 1]
  const currentValue = rPath(path, state)
  const value = typeof _value === 'function' ? _value(currentValue) : _value
  return rAssocPath(path, value, state)
}

export default VState