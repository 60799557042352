import React, { useMemo, useState, useLayoutEffect } from 'react'
import colors from 'signal/colors'
import { QueryParamProvider } from 'use-query-params'
import getPath from 'signal/routes/getPath'
import { useLocation, useHistory, MemoryRouter, Route } from 'react-router-dom'
import styled, { keyframes } from 'styled-components'
import Routes from 'signal-routes'
import Tooltip from 'signal-components/Tooltip'
import { useElement, useElementSize } from 'signal/utility-hooks'
import { useOpenInSidebar } from 'signal/sidebar'
import { Provider as ViewportProvider } from 'signal-components/Viewport'
import { Provider as PanelProvider } from 'signal-components/Panel'
import { Provider as BreadcrumbsProvider } from 'signal-components/Breadcrumbs'
import PreviewOverlay from './preview-overlay'

const sectionTitlesSingular = {
  referenceCancerSignature: 'Reference Signature',
  mutagenGroup: 'Mutagen Group',
  compound: 'Compound',
  radiation: 'Radiation',
  gene: 'Gene',
  cancerSample: 'Cancer Sample'
}

const PreviewPane = ({ searchHistoryState, categoryType, type, name, id, closeSearch, previewPanelUrl: urlForButton, setPreviewPanelUrl: setUrlForButton }) => {
  const history = useHistory()

  const [sidebarElement, setSidebarElement] = useElement()

  const [showGoToOverlay, setShowGoToOverlay] = useState(false)

  useLayoutEffect(() => {
    setShowGoToOverlay(false)
  }, [url])

  const url = getPath({ type, id })

  const [panelHistory, setPanelHistory] = useState()

  useLayoutEffect(() => {
    setUrlForButton(getPath({ type, id }))
  }, [setUrlForButton, type, id])

  const openInSidebar = useOpenInSidebar(urlForButton)
  const [viewportElement, setViewportElement] = useElement()

  return (
    <Container>
      {/*
      <Header key={id}>
        <div css='color: grey; font-size: 0.9em;' children={sectionTitlesSingular[categoryType]} />
        <div css={`color: ${colors.primary}; margin-top: 5px; font-size: 1.3em;`} children={name} />
      </Header>
      */}
      {/* component */}
      {/* <PreviewWarning /> */}
      <div ref={setViewportElement} css='overflow: auto; min-height: 0px; position: relative; flex-grow: 1;'>
        {url && <div css='position: relative;'>
          <MemoryRouter key={url} initialEntries={[url]} initialIndex={0}>
            <PassUrlUp setUrl={setUrlForButton} setHistory={setPanelHistory} />
            <QueryParamProvider ReactRouterRoute={Route}>
              <BreadcrumbsProvider>
                <PanelProvider panel='search'>
                  <ViewportProvider viewportElement={viewportElement}>
                    <Routes />
                  </ViewportProvider>
                </PanelProvider>
              </BreadcrumbsProvider>
            </QueryParamProvider>
          </MemoryRouter>
          <div onClick={() => setShowGoToOverlay(true)} css={`z-index: 100000000; position: absolute; top: 0px; left: 0px; right: 0px; bottom: 0px;`}>

          </div>
        </div>}

      </div>
      {showGoToOverlay &&
        <PreviewOverlay
          onClickGo={e => {
            e.stopPropagation()
            searchHistoryState.add({ type, id, name })
            history.push(url)
            closeSearch()
          }}
          name={name}
          onClickSidebar={() => {
            searchHistoryState.add({ type, id, name })
            openInSidebar()
            closeSearch()
          }}
          onClickOutside={() => {
            setShowGoToOverlay(false)
          }}
        />
      }
      <BottomRow>
        <span css={`flex-shrink: 0; background-color: ${colors.inProgress}; margin-right: 8px; color: white; height: 20px; width: 20px; border-radius: 50%; display: inline-flex; align-items: center; justify-content: center;`}>!</span>
        <div css={`color: ${colors.inProgress};font-weight: bold; display: flex; align-items: center;`}>This is a non-interactive page preview</div>
        <div css='flex-grow: 1;' />
        <Footer openInCss='display: none !important;' onClick={() => {
          searchHistoryState.add({ type, id, name })
          history.push(urlForButton)
          closeSearch()
        }} children={<>Go to page&nbsp;↵</>} />
        <Footer openInCss='display: none !important;' ref={setSidebarElement} css={`padding: 10px; border-radius: 0px;`} onClick={() => {
          searchHistoryState.add({ type, id, name })
          openInSidebar()
          closeSearch()
        }} children={<i css='font-size: 1.2em;' className='material-icons'>input</i>} />
      </BottomRow>
      <Tooltip vertical target={sidebarElement} content='Open in sidebar' />
    </Container>
  )
}

const Container = styled.div`
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1);
  flex-grow: 1;
  background-color: white;
  position: relative;
  min-height: 0px;
  display: flex;
  flex-direction: column;
  margin-left: 5px;
  overflow: hidden;
`


const PreviewWarning = styled.div.attrs({ children: 'Page preview' })`
  display: flex;
  justify-content: flex-end;
  color: ${colors.primary};
  padding: 5px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
`

const BottomRow = styled.div`
  padding: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid rgb(235, 235, 235);
  background-color: rgb(250, 250, 250);
`

const PassUrlUp = ({ setUrl, setHistory }) => {
  const location = useLocation()
  const history = useHistory()
  useLayoutEffect(() => (setUrl(location.pathname), setHistory(history)), [location.pathname, setUrl, history])
  return null
}
const animation = keyframes`
  0% { transform: translateX(-60px); opacity: 0; }
  10% { transform: translateX(-60px); opacity: 0; }
  100% { transform: translateX(0px); opacity: 1; }
`
const Header = styled.div`
  /* border-bottom: 1px solid rgba(150, 150, 150, 0.2); */
  /* background-color: rgba(150, 150, 150, 0.1); */
  border-right: 10px solid rgba(150, 150, 150, 0.1);
  background-color: rgba(150, 150, 150, 0.05);
  padding: 10px;
  /* height: 68px;
  border-radius: 34px 0px 0px 34px; */
  text-align: right;
  /*
  & > * {
    animation: ${animation} 0.7s;
  }
  */
`

const Footer = styled.div`
  background-color: ${colors.primary};
  ${p => p.disabled ? 'background-color: lightgrey; cursor: default;' : 'cursor: pointer; &:hover { opacity: 1; }'}
  color: white;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  margin-left: 3px; 
  height: 38px;
  border-radius: 19px 0px 0px 19px;
  font-size: 1em;
  opacity: 0.9;

`

export default PreviewPane