import { sameUniqValues } from 'signal/utility-functions'

const findMatchingVariantSet = (requiredIncludeFileIds, requiredExcludeFileIds, variantSets) => {
  if (requiredIncludeFileIds.filter(Boolean).length !== requiredIncludeFileIds.length) return
  if (requiredExcludeFileIds.filter(Boolean).length !== requiredExcludeFileIds.length) return
  return variantSets.find(
    ({ mutationType, includeFileIds = [], excludeFileIds = [] }) =>
      (mutationType || 'SUBSTITUTION') === 'SUBSTITUTION' && sameUniqValues(includeFileIds, requiredIncludeFileIds) && sameUniqValues(excludeFileIds, requiredExcludeFileIds)
  )
}

export default findMatchingVariantSet