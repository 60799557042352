import QueryString from 'query-string'

import VState from 'signal/v-state'

const getExperimentalChoice = () => {
  if (typeof window !== 'undefined') {
    return QueryString.parse(window.location.search).experimental === 'true'
  } else {
    return false
  }
}

const state = new VState(null)
state.set(getExperimentalChoice)

state.writeTransform = function (newValue = false, prevValue) {
  return prevValue
}

export default state
