const erroredTooRecently = task => {
  const { errorCount: ec, timeOfLastError } = task
  if (!ec) return null
  const minimumMinutes = ec === 1 ? 1 : ec === 2 ? 2 : ec === 3 ? 4 : ec === 4 ? 8 : 16// ec === 5 ? 16 : 32
  const minimumMilliseconds = minimumMinutes * 60 * 1000
  const elapsed = Date.now() - timeOfLastError
  if (elapsed > minimumMilliseconds) return null
  else return minimumMilliseconds - elapsed
}

export default erroredTooRecently