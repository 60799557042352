import VState from 'signal/v-state'
import { acceptStorageState } from 'signal/storage'
import createDb from 'analyse2/createDb'

const dbState = new VState(null)

acceptStorageState.subscribe(async (acceptStorage, unsubscribe) => {
  if (acceptStorage && !dbState.get()) {
    const db = await createDb()
    dbState.set(db)
    unsubscribe()
  }
})

export default dbState
