import VState from 'signal/v-state'

import master from './master'
import experimentalState from './experimental'
import experimentalChoiceState from './experimentalChoice'
import identityState from './identity'
import modeState from './mode'
import prereleaseTokenState from './prereleaseToken'
import reviewerTokenState from './reviewerToken'
import wasSignedInWithGoogleState from './wasSignedInWithGoogle'
import searchHistoryState from './searchHistoryState'

const configState = new VState()

const mainHistory = new VState()
const sidebarHistory = new VState()

const hideLowQCSignaturesState = new VState(true)
const hideRareSignaturesState = new VState(true)

const analyseMutationTypeState = new VState('SUBSTITUTION')

const showDeprecatedDatasetsState = new VState(false)

const pipelineApiState = new VState()

export {
  master,
  configState,
  mainHistory,
  sidebarHistory,
  hideLowQCSignaturesState,
  hideRareSignaturesState,
  experimentalState,
  experimentalChoiceState,
  showDeprecatedDatasetsState,
  analyseMutationTypeState,
  identityState,
  modeState,
  prereleaseTokenState,
  reviewerTokenState,
  wasSignedInWithGoogleState,
  pipelineApiState,
  searchHistoryState
}
