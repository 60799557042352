import React from 'react'
import colors from 'signal/colors'
import styled from 'styled-components'

const HistoryItem = ({ id, type, name, go, remove }) => {
  return (
    <Container onClick={go} key={`${type}_${id}`}>
      <Name children={name} />
      <Remove onClick={remove} children={<>&#215;</>} />
    </Container>
  )
}

const Container = styled.div`
  align-items: center;
  cursor: pointer;
  background-color: rgb(243, 243, 243);
  border-bottom: 1px solid lightgrey;
  display: flex;
  &:hover {
    background-color: ${colors.primary};
    color: white;
  }
`

const Name = styled.div`
  padding: 5px;
  flex-grow: 1;
`

const Remove = styled.div`
  font-weight: bold;
  margin: 5px;
  font-size: 0.8em;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  &:hover {
    color: ${colors.primary};
    background-color: rgb(243, 243, 243);
  }
`

export default HistoryItem