import { v1 as generateId } from 'uuid'
import { isFunction } from './utils'

/*
  This is the private class that handles the inner logic of v-state. Users wont use this
  class directly, however the public API for v-state will reference an instance of this private
  class.
*/

class PrivateState {
  constructor (initialValue = undefined, maxHistory = 1) {
    this.history = [initialValue]
    this.index = 0
    this.initialValue = initialValue
    this.maxHistory = maxHistory
    this.subscriptions = []
    this.unsubscribeRequests = []
    this.readTransform = value => value
    this.writeTransform = value => value
  }
  addToUnsubscribeRequests (...ids) {
    this.unsubscribeRequests.push(...ids)
  }
  broadcast () {
    const currentValue = this.get()
    this.subscriptions.forEach(({ id, fn }) => fn(currentValue, () => this.unsubscribeRequests.push(id))) 
  }
  canRedo () {
    return this.index < this.history.length - 1
  }
  canUndo () {
    return this.index > 0
  }
  flushUnsubscribeRequests () {
    const newSubscriptions = this.subscriptions.filter(({ id }) => !this.unsubscribeRequests.includes(id))
    this.subscriptions = newSubscriptions
    this.unsubscribeRequests = []
  }
  set (_newValue) {
    const currentValue = this.get()
    const intermediateValue = isFunction(_newValue) ? _newValue(currentValue) : _newValue
    const newValue = this.writeTransform(intermediateValue, currentValue)
    if (newValue !== currentValue) {
      if (this.canRedo()) this.history = this.history.slice(0, this.index + 1)
      this.history.push(newValue)
      if (this.history.length > this.maxHistory) this.history = this.history.slice(1)
      else this.index++
      this.flushUnsubscribeRequests()
      this.broadcast()
    }
  }
  get () {
    return this.readTransform(this.history[this.index])
  }
  getInitialValue () {
    return this.readTransform(this.initialValue)
  }
  redo () {
    if (this.canRedo()) {
      this.index++
      return true
    } else return false
  }
  subscribe (fn, id = generateId()) {
    this.subscriptions.push({ id, fn })
    fn(this.get(), () => this.unsubscribeRequests.push(id))
    return () => this.unsubscribeRequests.push(id)
  }
  undo () {
    if (this.canUndo()) {
      this.index--
      return true
    } else return false
  }
}

export default PrivateState