import dataState from 'analyse2/data'
import React, { useState } from 'react'
import { Portal } from 'signal/utility-components'
import { animation } from 'analyse2/css'
import { useProgress } from 'analyse2/ProgressIndicator'
import colors from 'signal/colors'

const selector = data => {
  const projects = data.projects
  if (!projects) return []
  const projectsInProgress = []
  for (let projectId in projects) {
    const { id, alias } = projects[projectId]
    const unfinishedCount = data.getProjectProgress(id)
    if (unfinishedCount) projectsInProgress.push({ id, alias, unfinishedCount })
  }
  return projectsInProgress
}

const GlobalProjectProgress = () => {

  const projectsInProgress = dataState.use(selector)
  const [show, setShow] = useState(true)

  if (projectsInProgress.length === 0) return null
  return (
    <Portal>
      <div css={`
        ${animation('fade', 'slide')};
        z-index: 100000000000000000;
        position: fixed;
        right: 0px;
        bottom: 20px;
        opacity: 0.7;
        display: flex;
        flex-direction: column;
        pointer-events: none;
        align-items: flex-end;
        font-size: 1em;
        /* background-color: black;
        padding: 7px;
        border-radius: 3px; */
        color: white;
      `}
      >
        {projectsInProgress.map(({ id, alias, unfinishedCount }) => <ProjectInProgress show={show} key={id} id={id} name={alias} unfinishedCount={unfinishedCount} />)}
        <ShowHide show={show} onClick={() => setShow(x => !x)} />
      </div>
    </Portal>
  )
}

const ProjectInProgress = ({ id, show, name, unfinishedCount }) => {
  const progress = useProgress(id, { smoothing: false })
  const _progressPercent = Math.round(progress * 100)
  const progressPercent = _progressPercent < 1 ? 1 : _progressPercent > 99 ? 99 : _progressPercent

  return (
    <div css={`
      background-color: black;
      border-radius: 3px;
      margin-right: 10px;
      margin-top: 5px;
      pointer-events: auto;
      padding: 5px;
      transform: scaleX(${show ? 1 : 0});
      transform-origin: right;
      transition: transform 0.1s;
      box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
    `}>
      Processing project {name}... {progressPercent}%
    </div>
  )
}

const ShowHide = ({ show, ...props }) => {
  return (
    <div css={`
      background-color: black;
      border-radius: 50% 0% 0% 50%;
      margin-top: 10px;
      cursor: pointer;
      pointer-events: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      width: 20px;
      height: 40px;
      box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
    `} {...props}>
      <span className='material-icons'>{show ? 'chevron_right' : 'chevron_left'}</span>
    </div>
  )
}

export default GlobalProjectProgress