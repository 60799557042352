import VState from 'signal/v-state'
import { acceptStorageState } from 'signal/storage'
import { uniqBy, pipe, reverse, slice } from 'ramda'

const searchHistoryState = new VState([])

if (typeof window !== 'undefined' && typeof localStorage !== 'undefined') {
  window.addEventListener('storage', e => e.key === 'searchHistory' && searchHistoryState.set(JSON.parse(e.searchHistory)))
  const searchHistory = localStorage.getItem('searchHistory')
  searchHistory && searchHistoryState.set(JSON.parse(searchHistory))
}
searchHistoryState.add = function (value) {
  searchHistoryState.set(x => [...x, { ...value, date: Date.now() }])
}
searchHistoryState.remove = function (value) {
  searchHistoryState.set(x => x.filter(item => !(item.id === value.id && item.type === value.type)))
}
searchHistoryState.reset = function () {
  searchHistoryState.set([])
}
searchHistoryState.writeTransform = function (_newValue) {
  const newValue = pipe(
    reverse,
    uniqBy(x => `${x.type}_${x.id}`),
    reverse,
    slice(-10, Infinity)
  )(_newValue)
  if (acceptStorageState.get()) localStorage.setItem('searchHistory', JSON.stringify(newValue))
  return newValue
}

export default searchHistoryState