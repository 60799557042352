import {
  append,
  intersperse,
  last,
  map,
  pipe,
  range,
  slice,
  zip
} from 'ramda'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import Provider from './Provider'
import useBreadcrumbs from './useBreadcrumbs'

const Breadcrumbs = props => {
  const [breadcrumbs] = useBreadcrumbs()
  if (breadcrumbs && breadcrumbs.length > 0) {
    return (
      <Container>
        <div css='flex-grow: 1; max-width: 1400px;'>
          <Contents breadcrumbs={breadcrumbs} />
        </div>
      </Container>
    )
  } else {
    return null
  }
}

export default Breadcrumbs
export { Provider, useBreadcrumbs }

const Contents = props => {
  const components = pipe(
    slice(0, -1),
    map(breadcrumb => <Breadcrumb breadcrumb={breadcrumb} />),
    append(<FinalBreadcrumb>{last(props.breadcrumbs).label}</FinalBreadcrumb>),
    intersperse(<Divider />)
  )(props.breadcrumbs)

  return pipe(
    zip(range(0, components.length)),
    map(([id, component]) => <span key={id}>{component}</span>)
  )(components)
}

const Breadcrumb = props => (
  <BreadcrumbContainer>
    <Link to={props.breadcrumb.to}>{props.breadcrumb.label}</Link>
  </BreadcrumbContainer>
)

const Divider = props => (
  <DividerContainer>&gt;</DividerContainer>
)

const Container = styled.nav`
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  justify-content: center;
  padding-top: 9px;
  padding-bottom: 8px;
  white-space: nowrap;
  overflow-x: auto;
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
`

const BreadcrumbContainer = styled.span`
  font-size: 1em;
  margin-right: 8px;

  & > a {
    text-decoration: none;
    color: #04869b;
    text-decoration: underline;
    transition: color 0.2s ease;
  }

  & > a:hover {
    color: black;
  }
`

const DividerContainer = styled.span`
  font-size: 0.9em;
  margin-right: 8px;
  color: rgb(70, 70, 70);
`

const FinalBreadcrumb = styled.span`
  position: relative;
  color: rgb(70, 70, 70);
`
