import React, { useState, forwardRef } from 'react'
import { Link, useLocation } from 'react-router-dom'
import useWindowSize from '@rooks/use-window-size'
import styled, { keyframes } from 'styled-components'
import { sidebarHasBeenOpenedState, sidebarOpenState } from 'signal/sidebar'
import logoOnWhite from './logo_on_white.svg'
import textOnWhite from './text_on_white.svg'
import StorageWarning from './StorageWarning'
import { sidebarHistory as sidebarHistoryState } from 'signal-state'
import colors from 'signal/colors'
import { useFeatures } from 'signal/features'
import {
  Experimental,
  Link as SLink,
  Mode,
  Search
} from 'signal-components'
import { useElement, useHovering, useLayoutEffectNoWarning } from 'signal/utility-hooks'

const narrowWidth = 10000000000 //700
const narrowWidthHome = 700

const useOpenInSidebar = link => {
  const sidebarHistory = sidebarHistoryState.use()
  return () => {
    if (link) {
      sidebarHistory.push(link)
      sidebarOpenState.set(true)
    }
  }
}

const NavBar = props => {
  const [exploreElement, setExploreElement] = useElement()
  const [hovering, { setHovering }] = useHovering(exploreElement, { advanced: true })

  const onClickExploreElement = () => setHovering(false)
  const inVitro = useFeatures('inVitro')
  return (
    <Container>
      <StorageWarning />
      <Nav>
        <Title>
          <Link to='/'>
            <Logos>
              <Logo src={logoOnWhite} />
              <LogoText src={textOnWhite} />
            </Logos>
          </Link>
        </Title>
        <SectionLinks>
          <Mode />
          <Search openLargeView />
          <SectionLink to='/' exact hideWhenNarrow>Home</SectionLink>
          <SectionLink to='/explore' onClick={onClickExploreElement} ref={setExploreElement} downArrow>Explore
            {hovering &&
              <Menu>
                {inVitro
                  ? (<>
                  <MenuItem to='/explore/cancer'>Cancer</MenuItem>
                  <MenuItem to='/explore/experimental'>Experimental</MenuItem>
                  </>)
                  : (<>
                  <MenuItem to='/explore/cancer'>Cancer</MenuItem>
                  <MenuItem to='/explore/mutagens'>Environmental Mutagenesis</MenuItem>
                  <MenuItem to='/explore/genes'>Gene Edits</MenuItem>
                  </>)}
              </Menu>
            }
          </SectionLink>
          <SectionLink to='/analyse2'>Analyse</SectionLink>
          <Section>
            <SLink to='/faq' openInSidebar>
              <FaqLink>?</FaqLink>
            </SLink>
          </Section>
          <SidebarButton />
        </SectionLinks>
      </Nav>
    </Container>
  )
}
const MenuItem = styled(Link)`
padding: 10px;
display: block;
cursor: pointer;
&:hover {
  background-color: rgb(240, 240, 240);
}
&:not(:last-child) {
  border-bottom: 1px solid rgb(230, 230, 230);
}
`
export default NavBar

const SectionLink = forwardRef(({ to, exact, downArrow, hideWhenNarrow, ...props }, ref) => {
  const location = useLocation()
  let active = to ? isActive(location.pathname, to, exact) : false
  if (to === '/analyse' && location.pathname === '/analyse2') active = false
  return (
    <SectionLinkContainer
      ref={ref}
      to={to}
      $downArrow={downArrow}
      $active={active}
      $hideWhenNarrow={hideWhenNarrow}
      {...props}
    />
  )
})

const SidebarButton = props => {
  const { innerWidth } = useWindowSize()
  const sidebarHasBeenOpened = sidebarHasBeenOpenedState.use()
  const sidebarOpen = sidebarOpenState.use()
  if (innerWidth > 1000) {
    return (
      <SectionLink
        style={{
          transition: 'padding 0.2s, width 0.2s',
          display: 'flex',
          alignItems: 'center',
          ...(sidebarHasBeenOpened && !sidebarOpen
            ? { width: '44px' }
            : { padding: '0px', minWidth: '0px', width: '0px', overflow: 'hidden' })
        }}
        as='div'
        onClick={() => sidebarOpenState.set(true)}
      ><span className='material-icons' css='font-size: 22px;'>vertical_split</span></SectionLink>
    )
  } else {
    return null
  }
}

const isActive = (pathname, to, exact) => {
  if (exact && pathname === to) {
    return true
  } else if (!exact && pathname.substring(0, to.length) === to) {
    return true
  } else {
    return false
  }
}

const Container = styled.div`
  color: #ffffff;
  background-color: white;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
`

const Nav = styled.nav`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-left: 15px;
  padding-right: 15px;
`

const Title = styled.div`
  flex: 0 0 auto;
  font-size: 25pt;
  display: flex;
  align-items: center;
  padding: 2px 0px;
`

const Logos = styled.div`
  display: flex;
  align-items: center;
`

const Logo = styled.img`
  height: 40px;

  @media (max-width: ${narrowWidth}px) {
    height: 35px;
  }
`

const LogoText = styled.img`
  margin-left: 10px;
  height: 53px;
  position: relative;
  top: -1px;
  transition: height 0.1s;

  @media (max-width: ${narrowWidth}px) {
    height: 45px;
  }
`

const SectionLinks = styled.div`
  flex: 1 1 auto;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  align-items: stretch;
  height: 57px;

  @media (max-width: ${narrowWidth}px) {
    height: 50px;
  }
`
const animation = keyframes`
  0% { transform: scaleY(0) }
  100% { transform: scaleY(1) }
`
const Menu = styled.div`
background-color: white;
box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.2);
position: absolute;
top: 100%;
z-index: 1000;
animation: ${animation} 0.17s;
transform-origin: top;
`

const SectionLinkContainer = styled(Link)`
  position: relative;
  color: #04869b;
  font-weight: bold;
  transition: transform 0.1s;
  text-decoration: none;
  margin-left: 15px;
  cursor: pointer;
  ${p => p.$downArrow ?
    `&::after {
      content: "▼";
      position: absolute;
      bottom: 0px;
      left: calc(50% - 3px);
      font-size: 0.6em;

      @media (max-width: ${narrowWidth}px) {
        display: none;
      }
    }
  ` : ''}
  display: flex;
  align-items: center;
  border-bottom-width: 3px;
  border-bottom-style: solid;
  border-bottom-color: ${props => props.$active ? '#04869b' : 'white'};
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 3px;
  transition: border-color 0.2s ease;

  @media (max-width: ${narrowWidthHome}px) {
    display: ${props => props.$hideWhenNarrow ? 'none' : 'flex'};
  }
`

const Section = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 15px;
`

const FaqLink = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  height: 25px;
  width: 25px;
  max-width: 25px;
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: 25px;
  font-weight: bold;
  border-radius: 50%;
  background-color: ${colors.inProgress};
  color: white;
`
