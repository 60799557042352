function getFileOverviewsForProject (projectId) {
  if (!this.fileOverviews) return
  const fileOverviews = Object.values(this.fileOverviews)
  const fileOverviewsForProject = []
  for (let fileOverview of fileOverviews) {
    if (fileOverview.projectId === projectId) fileOverviewsForProject.push(fileOverview)
  }
  return fileOverviewsForProject
}

export default getFileOverviewsForProject