import dataState from 'analyse2/data'
import shallowEqual from 'signal/utility-functions'
import VState from 'signal/v-state'

const progressState = new VState({})

const derivedDataState = dataState.derive(data => {
  if (data.samples && data.projects) {
    const projectsProgress = Object.values(data.projects).reduce((acc, project) => {
      const pendingCount = data.getProjectProgress(project.id)
      if (pendingCount) acc[project.id] = pendingCount
      return acc
    }, {})
    const samplesProgress = Object.values(data.samples).reduce((acc, sample) => {
      const pendingCount = data.getSampleProgress(sample.id)
      if (pendingCount) acc[sample.id] = pendingCount
      return acc
    }, {})
    return { ...projectsProgress, ...samplesProgress }
  } else return {}
}, shallowEqual)

derivedDataState.subscribe(currentRemainingObj => {
  progressState.set(lastProgressObj => {
    const newProgressObj = {}
    for (let id in currentRemainingObj) {
      const remaining = currentRemainingObj[id]
      let progress = 0.1
      if (Object.hasOwnProperty.call(lastProgressObj, id)) {
        const lastProgress = lastProgressObj[id]
        const oldRemaining = lastProgress.remaining
        const oldProgress = lastProgress.progress
        const progressMade = oldRemaining - remaining
        if (progressMade > 0) {
          const proportionalProgress = progressMade / oldRemaining
          const availableProgress = 1 - oldProgress
          const additionalProgress = proportionalProgress * availableProgress
          progress = oldProgress + additionalProgress
        } else progress = oldProgress
      }
      newProgressObj[id] = { remaining, progress }
    }
    return newProgressObj
  })
})

export default progressState