import { allTrue } from 'signal/utility-functions'
import { getPrimaryVariantSetId as getPrimaryVariantSetId1 } from '../utils'

function getPrimaryVariantSetId (sampleId) {
  if (!allTrue(this.samples, this.projects, this.variantSets)) return
  const sample = this.samples[sampleId]
  if (!sample) {
    console.warn('Sample does not exist', sampleId)
    return
  }
  const projectId = sample.projectId
  const project = this.projects[projectId]
  if (!project) {
    console.warn('Project does not exist', projectId)
    return
  }
  const variantSetsForSample = this.variantSets.filter(variantSet => variantSet.sampleId === sampleId)
  const primaryVariantSetId = getPrimaryVariantSetId1(sample, project, variantSetsForSample)
  return primaryVariantSetId
}

export default getPrimaryVariantSetId